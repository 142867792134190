import React from 'react'
import { Progress ,  Jumbotron, Button} from 'reactstrap';
import { Card, CardTitle, Form, FormGroup, Label, Input, Modal, ModalBody, Spinner } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { requestServerData } from '../Utils/Config'
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import { getFromSession } from '../Utils/SessionStorage'

class UserDashboardMini extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            user: getFromSession('user'),
            Dashboarddata:[],
            // ContestentCount:"",
            ApprovedShlokCount:0,
            // ApprovedShlokAmount:'',
            PaidAmount:0,
            // TodayApprovedShlokCount:'',
            UnPaidAmount:0,
            PendingAmount:0,
            TodayApprovedShlokAmount:'',
            MonthlyApprovedShlokCount:'',
            MonthlyApprovedShlokAmount:'',
            YearlyApprovedShlokCount:'',
            YearlyApprovedShlokAmount:'',
            TodayShlokCounttobeApproved:'',
            ShlokCounttobeApprovedTillDate:'',
            statewisedata:[],
            statelabels:[],
            statedata:[],
            shloklabels:[],
            shlokdata:[],
            shlokPendinglabels:[],
            shlokPendingdata:[],
            backgroundColor:[],
            borderColor:[],
            backgroundColor1:[],
            borderColor1:[],
         
         }
        
    }

    

    componentDidMount(preProps, preState) {
        this.GetDashboard();
        // this.GetStateData();
        // this.GetApporveshlokData();
        // this.GetPendingshlokData();
    }


   









    

    GetDashboard = async () => {
        this.setState({ loading: true })
        let data =
        {
            "RegCode": this.state.user.data.uid,
            // "ClaimStatus": "unclaimed",
        }
        let val_obj = {
            url: `Dashboards/User/${this.state.user.data.uid}`,
            data: '',
            request_type: 'get'
        }
            let response = await requestServerData(val_obj, 'user')
            if (response.response) {
                var getdata = response.data.data.result; 
                this.setState({ showchaptertitle:false, loading: false ,
                     Dashboarddata: response.data.data.result,
                    //  ApprovedShlokAmount: getdata.ApprovedShlokAmount,
                    PaidAmount: getdata.PaidAmount ? getdata.PaidAmount : 0,
                     ApprovedShlokCount: getdata.ApprovedShlokCount ? getdata.ApprovedShlokCount :0,
                    //  ContestentCount: getdata.ContestentCount,
                     MonthlyApprovedShlokAmount: getdata.MonthlyApprovedShlokAmount ,
                     MonthlyApprovedShlokCount: getdata.MonthlyApprovedShlokCount,
                     ShlokCounttobeApprovedTillDate: getdata.ShlokCounttobeApprovedTillDate,
                    //  TodayApprovedShlokCount: getdata.TodayApprovedShlokCount,
                    UnPaidAmount : getdata.UnPaidAmount ?  getdata.UnPaidAmount: 0,
                    PendingAmount : getdata.PendingAmount ?  getdata.PendingAmount : 0,
                       })
              // console.log("ap",this.state.Dashboarddata)
                // console.log("selectedChapters", this.state.Dashboarddata )
            
            }else{
                alert('Try Again after some time . . .')
            }

    }

    render() { 

     

        return (  
            <React.Fragment>
            <div  style={{ padding: '20px 20px' , borderRadius: '5px 20px 5px', backgroundColor:'#fff', color:'white'}}   class="container-fluid">
           <div class="row content">
           <div className="well">
                         </div>
                         
         
                <div className="col-sm-12">
                       <div  style={{ padding: '10px' , borderRadius: '5px', backgroundColor:'rgb(220, 220, 220)', color:'white',}}     className="row">
                         <div   className="col-sm-3" >
                             <div  color="primary"  style={{  textAlign: 'center',padding: '2px',margin: "5px 0", borderRadius: '5px', border:'1px solid', backgroundColor:'rgb(119, 136, 153)', color:'white',width:'100%'}}className="well">
                             <h5>PaidAmount</h5>
                              <p>{this.state.PaidAmount}</p> 
                             </div>
                         </div>
                         <div  className="col-sm-3" >
                             <div style={{ textAlign: 'center',padding: '2px', borderRadius: '5px',margin: "5px 0", border:'1px solid', backgroundColor:'rgb(119, 136, 153)', color:'white', width:'100%'}} className="well">
                             <h5>Approved Count</h5>
                            <p>{this.state.ApprovedShlokCount }</p> 
                             </div>
                         </div>
                      
                         
                         <div  className="col-sm-3" >
                             <div  style={{ textAlign: 'center',padding: '2px', borderRadius: '5px',margin: "5px 0", border:'1px solid', backgroundColor:'rgb(119, 136, 153)', color:'white',width:'100%'}} className="well">
                             <h5>UnPaid Amount</h5>
                             <p>{this.state.UnPaidAmount }</p> 
                             </div>
                         </div>
                         
                         <div  className="col-sm-3" >
                             <div  style={{ textAlign: 'center',padding: '2px', borderRadius: '5px',margin: "5px 0", border:'1px solid', backgroundColor:'rgb(119, 136, 153)', color:'white', width:'100%'}} className="well">
                             <h5>Pending Amount</h5>
                             <p>{this.state.PendingAmount }</p> 
                             </div>
                         </div>
                         
                         
                       
                       
         
         
         
                 </div>
         
         
              
               </div>  
              
         
              
               </div>
               </div>
                     </React.Fragment>
        );
    }
}
 
export default UserDashboardMini;