import React from 'react'
import { Card, CardTitle, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


class Request extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
       
    }



    render() {
        return (
            <React.Fragment>
                <ToastContainer
                    position="top-center"
                />
                <div>
                <h1> Page Under Construction</h1>
                </div>
            </React.Fragment>
        );
    }

   
}

export default withRouter(Request);
