import React from "react";

const UserRewards = props => ( <
    svg height = { 512 }
    width = { 512 } {...props } >
    <
    path d = "M16.5 24a.495.495 0 01-.277-.084L12 21.101l-4.223 2.815A.5.5 0 017 23.5V10.785a.5.5 0 011 0v11.781l3.723-2.482a.5.5 0 01.555 0L16 22.566V10.785a.5.5 0 011 0V23.5a.5.5 0 01-.5.5z" / >
    <
    path d = "M14.173 14c-.248 0-.496-.052-.725-.156l-1.138-.517a.756.756 0 00-.623 0l-1.137.517c-.44.199-.95.208-1.396.023a1.764 1.764 0 01-.971-1.003l-.438-1.169a.754.754 0 00-.44-.441l-1.169-.438a1.76 1.76 0 01-1.004-.971 1.768 1.768 0 01.026-1.396l.517-1.137a.753.753 0 000-.623l-.517-1.137a1.755 1.755 0 01.979-2.366l1.169-.438a.75.75 0 00.44-.44l.438-1.17A1.755 1.755 0 0110.55.159l1.138.517c.197.09.424.09.623 0l1.137-.517a1.751 1.751 0 012.366.979l.438 1.169a.75.75 0 00.44.44l1.17.438c.453.17.819.524 1.004.971.185.446.176.955-.024 1.395l-.517 1.137a.753.753 0 000 .623l.517 1.137c.2.44.209.948.024 1.395s-.551.801-1.004.971l-1.169.438a.75.75 0 00-.44.44l-.438 1.169A1.76 1.76 0 0114.173 14zM12 12.259c.247 0 .495.052.725.157l1.138.517a.752.752 0 001.016-.421l.438-1.169a1.754 1.754 0 011.025-1.025l1.169-.439a.744.744 0 00.431-.417.744.744 0 00-.01-.599l-.517-1.137a1.755 1.755 0 010-1.45l.517-1.138c.087-.191.091-.404.01-.599s-.234-.343-.431-.417l-1.169-.438a1.75 1.75 0 01-1.025-1.026l-.438-1.169a.747.747 0 00-.417-.431.742.742 0 00-.6.01l-1.137.517c-.46.21-.989.21-1.449 0l-1.138-.517a.743.743 0 00-.599-.01.747.747 0 00-.417.431l-.438 1.169a1.755 1.755 0 01-1.026 1.026l-1.169.439a.744.744 0 00-.431.417.744.744 0 00.01.599l.517 1.137c.209.461.209.989 0 1.45l-.517 1.137c-.087.192-.091.404-.01.599s.233.343.431.417l1.169.438c.474.178.848.552 1.025 1.025l.438 1.169a.744.744 0 00.417.431c.194.08.406.077.599-.01l1.137-.517c.231-.103.479-.156.726-.156zm.518-11.129h.01z" / >
    <
    path d = "M12 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-7c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z" / >
    <
    /svg>
);

UserRewards.defaultProps = {
    width: 48,
    height: 48,
    viewBox: "0 0 24 24"
};

export default UserRewards;