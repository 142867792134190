import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import SideBar from './SideBar';
import {
  Card,
  Button,
  Input,
  Spinner,
  Label,
  Modal,
  DropdownButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody
} from "reactstrap";
import { requestServerData } from "../Utils/Config";
import { ToastContainer, toast } from "react-toastify";
import { getFromSession } from "../Utils/SessionStorage";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: getFromSession("user"), // for admin switch the session on DidMount

      OldPassword: null,
      Password: null,
      ConfirmPassword: null
    };
  }

  handleOnChange = e => {
    let { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async () => {
    this.setState({
      loading: true
    });
    const { OldPassword, Password, ConfirmPassword } = this.state;
    if (!OldPassword || !Password) {
      toast.error(
        `${(!OldPassword && "Old Password") ||
          (!Password && "Password")} is mandatory`
      );
    } else if (Password !== ConfirmPassword) {
      toast.error(`Confirm Password must be match with New Password`);
      this.setState({
        ConfirmPassword: ""
      })
    } else {
      let val_obj = {
        data: {
           
          Regcode :this.state.user.data.uid,
          Role:this.state.user.data.role,
          Type:this.state.user.data.type,
          Token:this.state.user.data.token,
          OldPassword,
          Password,
          ConfirmPassword
        },
        request_type: "POST",
        url: "Account/ChangePassword"
        
      };
      console.log("data results",val_obj)
    

      let response = await requestServerData(val_obj, "user");
      if (response.response) {
        toast.success("Changed Successfully");
        this.setState({
          
          OldPassword: "",
          Password: "",
          ConfirmPassword: ""
        })
      } else if((!response.response) && response.data && response.data.data && response.data.data.StatusCode === 406){
        toast.error(response.data.data.ResponceMessage);
      } else  {
        toast.error("Please try again");
      }
    }
    this.setState({
      loading: false
    });
  };

  componentDidMount(){
    let { forAdmin = false } = this.props;
    if(forAdmin){
      this.setState({user:getFromSession("admin")})
      console.log("session",{user:getFromSession("admin")})
    }
  }

  render() {
    let { forAdmin = false } = this.props;
    console.log(forAdmin)
    let { OldPassword, Password, ConfirmPassword, loading } = this.state;
    return (
     
      <div className={"top-main"} style={{ height: window.innerHeight }}>
        
        <div style={{ paddingTop: "50px", margin: "auto", maxWidth: "500px" }}>
          <Card style={{ padding: 20 }}>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
            <h2>Change Password</h2>
            <div class="form-group">
              {/* <label class>User Type Name*</label> */}
              <input
                name="OldPassword"
                id="OldPassword"
                placeholder="Old Password"
                class="form-control"
                type="text"
                value={OldPassword}
                onChange={this.handleOnChange}
              />
            </div>
            <div class="form-group">
              {/* <label class>User Type Name*</label> */}
              <input
                name="Password"
                id="Password"
                placeholder="Password"
                class="form-control"
                type="Password"
                value={Password}
                onChange={this.handleOnChange}
              />
            </div>
            <div class="form-group">
              {/* <label class>User Type Name*</label> */}
              <input
                name="ConfirmPassword"
                id="ConfirmPassword"
                placeholder="Confirm Password"
                class="form-control"
                type="password"
                value={ConfirmPassword}
                onChange={this.handleOnChange}
              />
            </div>

            {loading ? (
              <button
                type="submit"
                class="btn btn-secondary"
                style={{
                  marginTop: "12px",
                  width: "100%",
                  backgroundColor: "#9932CC"
                }}
              >
                <Spinner />
              </button>
            ) : (
              <button
                type="submit"
                class="btn btn-secondary"
                style={{
                  marginTop: "12px",
                  width: "100%",
                  backgroundColor: "#9932CC"
                }}
                onClick={() => this.handleSubmit()}
              >
              Submit
              </button>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(ChangePassword);
