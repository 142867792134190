import React from "react";

const UserProfileIcon = props => (
    <svg width={30.5} height={30.5}  {...props}>
    <path d="M29.782 27.866l-.844-5.109c-.224-1.352-1.444-2.645-2.782-2.944l-6.258-1.4c.887-1.086 1.591-2.407 2.091-3.731.811-.295 1.626-1.019 1.861-1.959.312-1.248-.018-2.364-.792-2.851v-.884c.708-2.131 1.075-5.614-2.137-7.492-1.438-.84-2.632-.893-3.532-.6-.142-.365.277-.895.277-.895-.74-.025-1.128.369-1.291.592-.988-.455-2.281-.65-3.938-.303-5.313 1.115-5.99 8.074-5.019 9.598-.758.49-1.081 1.594-.77 2.83.236.945 1.057 1.672 1.871 1.963.484 1.305 1.167 2.602 2.03 3.678l-2.104.416-3.892.771c-1.345.267-2.599 1.529-2.854 2.875l-.97 5.086c-.133.699.025 1.367.445 1.882.419.512 1.042.799 1.751.809l24.596.302h.034c.7 0 1.32-.274 1.745-.774.428-.503.597-1.164.482-1.86zm-15.026-5.594l-.75 2.976-4.81-5.603 2.142-.424c.875.84 1.897 1.44 3.057 1.643l-.586.523.947.885zm1.707 3.02l-.679-3.008.968-.863-.604-.563c1.116-.207 2.104-.787 2.961-1.597l2.208.494-4.854 5.537zM9.369 13.687c-.431.092-1.427-.451-1.625-1.238-.202-.805-.01-1.557.428-1.68a.544.544 0 01.398.062V9.63l.007-.01c-.003-.016-.005-.039-.007-.057v-.412c.108-1.019 1.07-2.953 6.376-1.215 1.827.6 2.955-.351 3.521-1.607 1.804.201 3.006 2.246 3.467 3.508v.996a.656.656 0 01.199-.072c-.002.008-.001.025-.004.031.016.016.041.006.076-.027.042-.002.085-.008.126.004.438.123.629.875.427 1.68-.194.781-1.185 1.32-1.612 1.238-.471 1.428-1.318 3.178-2.502 4.443a7.268 7.268 0 01-.425.418 5.67 5.67 0 01-.494.402c-.731.535-1.558.871-2.474.871-.936 0-1.772-.338-2.509-.877a6.66 6.66 0 01-.925-.836c-1.156-1.252-1.983-2.974-2.448-4.421zm18.164 15.812l-4.802-.059.032-2.632a.505.505 0 00-.494-.507h-.006a.502.502 0 00-.5.494l-.032 2.631-12.986-.16.032-2.632a.501.501 0 00-.494-.506h-.006a.5.5 0 00-.5.493l-.032 2.632-4.806-.06c-.409-.006-.76-.162-.989-.44-.229-.28-.313-.658-.237-1.062l.97-5.086c.179-.94 1.125-1.896 2.066-2.082l3.32-.658 6.789 7.904a.505.505 0 00.377.174h.002a.5.5 0 00.376-.17l6.817-7.772 3.507.784c.937.209 1.856 1.188 2.014 2.132l.844 5.11c.066.399-.023.772-.258 1.047-.229.271-.581.422-.983.422v.498l-.021-.495z" />
  </svg>
);

UserProfileIcon.defaultProps = {
    width: 48,
    height: 48,
    viewBox:"0 0 30.5 30.5"
};

export default UserProfileIcon;
