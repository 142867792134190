import React from "react";

const SvgTwitter = props => (
  <svg width={48} height={48} {...props}>
    <path
      d="M47 7.988a17.308 17.308 0 01-5.409 1.7 10.474 10.474 0 004.134-5.863 17.831 17.831 0 01-5.981 2.587A8.857 8.857 0 0032.8 3.061c-5.167 0-9.389 4.73-9.389 10.568a12.251 12.251 0 00.242 2.414A25.983 25.983 0 014.21 5.007a11.432 11.432 0 00-1.275 5.3 10.769 10.769 0 004.2 8.77 8.553 8.553 0 01-4.288-1.232v.074c0 5.124 3.254 9.41 7.564 10.371a8.286 8.286 0 01-4.244.172c1.187 4.163 4.64 7.267 8.8 7.341a17.51 17.51 0 01-11.72 4.533A17.145 17.145 0 011 40.184a24.67 24.67 0 0014.468 4.754c17.327 0 26.848-16.111 26.848-30.078 0-.468 0-.911-.022-1.379A20.277 20.277 0 0047 7.988z"
      fill={props.fill}
    />
    <path fill="none" d="M0 0h48v48H0z" />
  </svg>
);

SvgTwitter.defaultProps = {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48'
};

export default SvgTwitter;
