import React, { Component, cloneElement } from "react";
import { Alert, Button, CardImg, Modal, ModalBody, ModalFooter } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Logo from '../Assets/Images/logo.png'


class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {
    }

    render() {
        return (

            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ padding: 10, margin: 'auto', width: '96%' }}>
                    <Alert className={'shadow'} color="warning" style={{ backgroundColor: '#fff', textAlign: 'center', height: '100%' }}>
                        <div style={{ width: '100%', textAlign: 'left' }}>

                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: 250, height: 300 }}>
                                    <CardImg src={Logo} alt='' width={'100%'} height={'100%'} />
                                </div>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>Terms and Conditions:</p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}> 1. By joining this campaign through any possible way, like by registration , by sending video , joining group or any other possible way, you agree to all terms and conditions of this campaign.</p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}> 2. This campaign is purely volunteer movent and can be fully or partially stoped or discontinued at any time without prior information or notice. </p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>3. Campaign or volunteer working for the campaign can accept or reject your registration, videos or any other request. They can also add or remove you from the active group.  </p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>4. The content you share in the form of videos, pictures, text or any other form will always remain property of our campaign.  We will have complete copyright on this content. We can use this content for promotion, publicity, education or any other possible way on media, social media or any other possible platform.</p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>5. This campaign is not an agreement of any kind between us and you.  In the spirit of giving respect to the efforts of your scripture remembrance, we are giving you the amount of respect as a gift.</p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>6. The amount given is solely on discretion of campaign managers and volunteers. We can change or discontinue the amount at anytime without any prior information or notice. 
 </p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>7. 7. We are free to stop this campaign (initiative) for a fixed time or forever as per our discretion.</p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>8. We are not legally obligated to pay for any video received.  It is a purely emotional connection.</p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>9. Please check the process and rules of uploading videos on Telegram group. Process is evolving and changing for the convenience of all so all are requested to check the same frequently . Latest and last changes are always applicable. In case any dispute campaign volunteer have the last right to accept or reject the matter.  </p>
                            </div>

                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>10. For this campaign we do not collect any fees for registration or enrollment. It is absolutely free of cost. We do not take any money from the participants by any means or for any reason.  If anyone asks you for money please do not pay and immediately report us on our group and Facebook page.</p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>11. For payments we only ask for your UPI ID and mobile number connected to your bank account. We do not ask for any other bank related details like OTP, PIN or any type of account information. If someone asks for bank account or any other details or information, please do not share and report the same to us. For any changes in the payment process please follow our group and latest updates. We are not responsible if you miss these instructions. </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>12. For details, change in the rules, cutoff date important notices please check our facebook page.  Our official Facebook page is -
                                <a style={{color: '#4285f4'}} onClick={()=>{window.open('https://www.facebook.com/vedicwisdombharat/')}}>https://www.facebook.com/vedicwisdombharat</a></p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>13. This campaign is volunteer campaign has no binding to anyone in the system. We are not liable to any money after cutoff date.</p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>14. We can add or remove any participant at any time without any notice. </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>15. All content posted on campaign group , Messenger, Facebook page or website is sole property of campaign. Any recreation of content, copy of content, download of content or any type of change is strictly prohibited and not allowed.</p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 12, fontWeight: 700, color: '#000' }}>16. We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific notice of each such change.<br></br>
                                <br></br>
                                It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site , group or any other campaign platform after the date such revised Terms and Conditions are posted.
</p>
                            </div>
                            <div style={{ margin: '20px 0px', display:'flex' }}>
                                <p style={{ padding:'1px 4px',fontSize: 12, fontWeight: 700, color: '#000',margin: 0,background: '#ffc107',fontStyle: 'italic' }}>(Last updated on 18 July 2020)</p>
                            </div>
                        </div>
                    </Alert>
                </div>
            </div>

        );
    }

    handleCheckBox = (e) => {

        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    handleClick = () => {
        alert('please select Terms & Conditions....')
    }
}



export default withRouter(Terms);