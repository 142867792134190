import React, { Component } from "react";
import { Button, CardImg, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from "react-router-dom";
// import Logo from '../Assets/Images/logo.png'
import { getFromSession, removeSessionStorage } from '../Utils/SessionStorage'
import FaceBook from '../Assets/Images/FacebookBox'
import Google from '../Assets/Images/GoogleLogoColor'
import UserProfileIcon from '../Assets/Images/UserProfileIcon'
import Twitter from '../Assets/Images/Twitter'
import Youtube from '../Assets/Images/Youtube'
import Instagram from '../Assets/Images/Instagram'
import Home from '../Assets/Images/Home'



import Logo from '../Assets/Images/logo.png'

class Header extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
            user: getFromSession('user'),
           
        };
    }

    render() {

        let mobile = window.innerWidth < 769
        let isLoginUser = getFromSession('user')

        if (mobile) {

            return (
                <div className={'top-header-mobile'}>
                    <div onClick={() => { this.props.history.push('/') }}>
                        {
                            this.state.user && this.state.user.response ?
                                <React.Fragment>
                                    <p style={mobile ? { color: '#111', fontSize: 20, fontWeight: 'lighter', fontWeight: 'bold', margin: 0, padding: 0 } : { color: '#111', fontSize: 40, fontWeight: 'lighter', margin: 0, padding: 0 }}> {this.state.user.data.userName}</p>
                                    <p style={mobile ? { color: '#111', fontSize: 15, fontWeight: 'lighter', margin: 0, padding: 0 } : { color: '#111', fontSize: 40, fontWeight: 'lighter', margin: 0, padding: 0 }}>{this.state.user.data.uid}</p>
                                </React.Fragment>
                                :
                                // <p style={mobile ? { color: '#111', fontSize: 20, fontWeight: 'lighter', fontWeight: 'bold', margin: 0, padding: 0 } : { color: '#111', fontSize: 40, fontWeight: 'lighter', margin: 0, padding: 0 }}> Vedic Wisdom </p>
                                <Home width={35} height={35} fill="black" style={{ marginRight: 5 }}  />
                       
                       }

                    </div>
                    <div className={mobile ? 'header-icon-btm-mobile' : 'header-icon-btm'}>
                            <Youtube width={35} height={35} fill="#4285f4" style={{ marginRight: 5 }}   onClick={() => window.location.href="http://www.youtube.com/c/VedicWisdomBharat"}/>
                            {/* <Twitter width={26} height={26} fill="#4285f4" style={{ marginRight: 5 }} /> */}
                            <Instagram width={30} height={30} fill="#4285f4" style={{ marginRight: 5 }} onClick={() => window.location.href="https://www.instagram.com/vedic_wisdom08/"}/>
                            
                            <FaceBook width={30} height={30} fill="#4285f4" onClick={() => window.location.href="https://www.facebook.com/वैदिक-शास्त्र-स्मरण-अभियान-107539540986637/"}/>
                           
                        </div>
                    <div style={{ width: 65, height: 65, textAlign: 'center' }}>
                        {/* <CardImg src={Logo} alt='' width={'100%'} height={'100%'} /> */}
                        {
                            
                            <React.Fragment>
                                <UserProfileIcon height={50} width={50} onClick={() => this.handleClick('dropDown')} ></UserProfileIcon>
                                 
                                <p style={mobile ? { color: '#111', fontSize: 10, fontWeight: 'lighter',fontWeight: 'bold', margin: 0, padding: 0 } : { color: '#111', fontSize: 40, fontWeight: 'lighter', margin: 0, padding: 0 }}>{this.state.user && this.state.user.response ? 'Logout' : 'Login'}</p>
                               
                             
                                <Dropdown isOpen={this.state.isDropDown} style={{ position: 'absolute', left: 100 }}>
                
                                    <DropdownMenu style={{ overflow: 'scroll' }}>
                                        {
                                            this.state.user && this.state.user.response ?
                                                <DropdownItem onClick={() => this.handleClick('logout')}>Logout</DropdownItem>
                                                :
                                                <DropdownItem onClick={() => this.handleClick('login')}>Login</DropdownItem>
                                        }
                                    </DropdownMenu>
                                  
                                
                                       
                            </Dropdown>
                            </React.Fragment>
                            // :
                            // <Button color="primary" size={mobile ? '' : "lg"} style={{ color: '#111', padding: '3px 15px', margin: 0 }} onClick={() => this.handleClick('login')}>Login</Button>

                        }

                        {/* <UserProfileIcon height={60} width={60} onClick={() => this.handleClick('logout')}>logout</UserProfileIcon> */}
                        {/* <Button color="primary" size={mobile ? '' : "lg"} style={{ color: '#111', padding: '3px 10px' }} onClick={() => this.handleClick('logout')}>Logout</Button> */}
                    </div>
                </div>
            )
        } else {

            return (
                <div className={'top-header'}>

         
 
 {
    this.state.user && this.state.user.response ?                
                   
                    <div onClick={() => { this.props.history.push('/') }}>
                        <p style={mobile ? { color: '#111', fontSize: 30, fontWeight: 'lighter', margin: 0, padding: 0 } : { color: '#111', fontSize: 40, fontWeight: 'lighter', margin: 0, padding: 0 }}> Vedic Wisdom <h5>Welcome: {this.state.user.data.userName}</h5> </p>
                    </div>
                 :
                    <div onClick={() => { this.props.history.push('/') }}>
                        <p style={mobile ? { color: '#111', fontSize: 30, fontWeight: 'lighter', margin: 0, padding: 0 } : { color: '#111', fontSize: 40, fontWeight: 'lighter', margin: 0, padding: 0 }}> Vedic Wisdom</p>
                    </div>
        }


                    <div className={mobile ? 'header-icon-top-mobile' : 'header-icon-top'}>
                        <div className={mobile ? 'header-icon-btm-mobile' : 'header-icon-btm'}>
                            <Youtube width={35} height={35} fill="#4285f4" style={{ marginRight: 5 }}   onClick={() => window.location.href="http://www.youtube.com/c/VedicWisdomBharat"}/>
                            {/* <Twitter width={26} height={26} fill="#4285f4" style={{ marginRight: 5 }} /> */}
                            <Instagram width={30} height={30} fill="#4285f4" style={{ marginRight: 5 }} onClick={() => window.location.href="https://www.instagram.com/vedic_wisdom08/"}/>
                            
                            <FaceBook width={30} height={30} fill="#4285f4" onClick={() => window.location.href="https://www.facebook.com/वैदिक-शास्त्र-स्मरण-अभियान-107539540986637/"}/>
                           
                        </div>
                      
                        {
                            isLoginUser && isLoginUser.response ?
                                <Button color="primary" size={mobile ? '' : "lg"} style={{ color: '#ffffff', padding: '3px 24px' }} onClick={() => this.handleClick('logout')}>Logout</Button>
                                :
                                <Button color="primary" size={mobile ? '' : "lg"} style={{ color: '#ffffff', padding: '3px 24px' }} onClick={() => this.handleClick('login')}>Login</Button>
              
                               
                        }

                       
                    </div>
                </div>
            );
        }
    }

    handleClick(key) {

        switch (key) {

            case 'login':

                this.props.history.push('/user-login')
                break;

            case 'logout':

                removeSessionStorage('user')
                this.props.history.push('/')
                break;
            case 'dropDown':

                this.setState({
                    isDropDown: !this.state.isDropDown
                })
                break;

            default:
                break;
        }
    }

}

export default withRouter(Header);
