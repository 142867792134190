import React, { Component, cloneElement } from "react";
import { Alert, Button, CardImg, Modal, ModalBody, ModalFooter } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Logo from '../Assets/Images/logo.png'
class Aboutus extends Component {
    render(){
        return(
            <div style={{ width: '100%', height: '100%' }}>
            <div style={{ padding: 10, margin: 'auto', width: '96%' }}>
                <Alert className={'shadow'} color="warning" style={{ backgroundColor: '#fff', textAlign: 'center', height: '100%' }}>
                    <div style={{ width: '100%', textAlign: 'left' }}>



                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: 250, height: 300 }}>
                                    <CardImg src={Logo} alt='' width={'100%'} height={'100%'} />
                                </div>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>जो व्यक्ति भारत में जन्म लेकर भी भारत की मूल आत्मा अध्यात्म की शरण में नहीं है वह अपने जीवन के होने का अर्थ खो रहा है । 
                                                                                            आज भारतीय समाज भी अपनी मूल संस्कृति, सभ्यता और गौरव से दूर होता जा रहा है | शास्त्रों के प्रति पूज्य भाव तो है लेकिन कहीं न कहीं वर्तमान शिक्षा पद्धति की वजह से हमारे नौनिहाल,
                                                                                            हमारे देश के वास्तविक धन उर्जावान युवा शास्त्रों के सानिध्य से वंचित रह गये हैं | हमारा यह अभियान इसी खाई को पाटने का एक माध्यम है |
                                                                                           एक समय था जब पुरे देश में ७ लाख से अभी गुरुकुल शिक्षा के केंद्र बनकर भारत को भारतीयता के आधार पर धारण किये हुए थे, लकिन विदेशी शासन और मैकाले की शिक्षा पद्धति के कारण गुरुकुल भारत में समाप्त प्राय: ही हो गए,
                                                                                            इसी खाई को पाटने के लिए हम इस अभियान के माध्यम से घर घर गुरुकुल के प्रारंभिक स्वरुप को प्रारंभ करना चाहते हैं | हमारे इस अभियान से जुड़े बच्चे जब युवावस्था को प्राप्त होंगे तब वे अपने शास्त्रों के प्रति गौरव का भाव रखेंगे और उसमें निहित शिक्षा को अपने जीवन में उतारने से गुरेज भी नहीं करेंगे |
                                                                                             शास्त्र उनकी सब प्रकार की जिज्ञासाओं के समाधान में समर्थ होंगे | 
                                                                                            पुनः हमारा भारत वर्ष अपनी संस्कृति के स्वर्णिम युग को प्राप्त करे इसके लिए ही यह अभियान प्रारंभ किया गया है | 
                                                                                            एक ओर शास्त्र स्मरण से संस्कृति की ओर जीवन का प्रवाह और दूसरी ओर प्रतिभागियों को यथोचित वैदिक सम्मान राशि देकर उनके जीवन में शास्त्र बल का संचार, यही है हमारे अभियान का सार |</p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                    वैदिक संस्कृति के अर्थ एवं उसके गूढ़ रहस्यों से परिचित कराने वाले सद्ग्रन्थों का स्वाध्याय ही हमें हमारे मूल स्वभाव से मिला सकते हैं जिससे हमारा अभ्युदय और निःश्रेयस दोनों सिद्ध हो सके ।
                                    हमारा एक ही संकल्प है कि भारत के प्रत्येक बच्चें में हमारे ग्रंथों के ज्ञान से अछूते न रहें और बालपन और युवा अवस्था में ही उन्हें प्रमुख ग्रंथो के विषय में मूलभूत जानकारी हो जाये जिससे उनके भीतर कभी यह भाव न आये कि उन्हें अपने ही ऋषि मुनियों एवं ईश्वर कृत ग्रंथो के विषय में कोई जानकारी नहीं है |
                                    बच्चों को यदि बालपन एवं युवा अवस्था आते आते कुछ कुछ शास्त्र स्मरण हो जाये तो जीवन में उन्हें कभी भी द्वंद्व की स्थिति नहीं आयेगी | </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                पढ़ने, लिखने में समर्थ हो चुके बच्चों के मन मस्तिष्क भी सच्चे अध्यात्म की छांव में आकर अपना भावी जीवन सुखमय बना सके इसके लिए हम लाये हैं शास्त्र स्मरण प्रतियोगिता । 
                                 </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                इस अभियान के प्रथम चरण में हमने श्रीमद्भगवत गीता के श्लोक स्मरण को रखा हुआ है । बाद में इस अभियान के अंतर्गत हम योग दर्शन, उपनिषद एवं वेदों के स्मरण को भी रखेंगे |  
                                 </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                जो भी  बालक, बालिका श्रीमद्भगवत गीता के श्लोक स्मरण कर, आंखे बंद करके वीडियो हमारे टेलीग्राम चैनल (चैनल का लिंक एवं नम्बर नीचे दे रहे हैं) 
                                 पर भेजेंगे उन्हें श्लोक के उच्चारण एवं स्मरण के आधार पर प्रति श्लोक ₹10 रुपया भेज दिया जाएगा। 
                                 </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                जितने श्लोक एक साथ सुनाएंगे उसके अनुसार रुपये एक साथ दिए जाएंगे। 
                                आप स्मरण करके टेलीग्राम नीचे दिए गए पब्लिक ग्रुप पर अपनी वीडियो हमें भेज सकते हैं। 
                               
                                Telegram <a style={{color: '#4285f4'}} onClick={()=>{window.open('https://t.me/joinchat/JBW2Shk0T3He5cgr_CUVcw')}}>    (https://t.me/joinchat/JBW2Shk0T3He5cgr_CUVcw)</a>
                               <br></br> आगे चलकर इस अभियान में योग दर्शन, षड्दर्शन, उपनिषद एवं वेदों के स्वाध्याय एवं स्मरण को रखा जायेगा |</p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                        
                                VW गीता-श्लोक स्मरण 
                                <br></br>
                                ★गीता के श्लोक याद करें और पाएं हर श्लोक पर ₹10 सम्मान राशि
                                <br></br>
                                ★आंखे बंद एवं गर्दन सीधी रखकर हमें वीडियो भेजें ।
                                <br></br>
                                ★ न्यूनतम 5 श्लोक और अधिकतम 15 श्लोक ही हमें भेजें।
                                 </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                आप सब को अपने राज्यों के अनुसार ही, इसके अतिरिक्त, एक नया ग्रुप ज्वाइन करना है । आप इस ग्रुप में नहीं बल्कि नए ग्रुप में वीडियो शेयर करने हैं । 
                                 </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                जिस भी प्रतिभागी के रजिस्ट्रेशन के पहले दो अक्षर नीचे दिए गए कोड से मैच करते हैं, वे ही इस ग्रुप को जॉइन करें और वीडियो पोस्ट करें। 
                                 </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                ग्रुप 1<br></br>
                                    राज्य : (अपने रजिस्ट्रेशन के पहले 2 अक्षर देखें)<br></br>
                                    AR, AS, BR, OR, JR, MN, MZ, ML, NL, SK, TR, WB
                                <a style={{color: '#4285f4'}} onClick={()=>{window.open('https://t.me/joinchat/JBW2ShqyD1q32bAfcc0QeQ')}}>    (https://t.me/joinchat/JBW2ShqyD1q32bAfcc0QeQ)</a>

                                 </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                ग्रुप 2<br></br>
                                राज्य : (अपने रजिस्ट्रेशन के पहले 2 अक्षर देखें)<br></br>

                                 GJ, MH, RJ, MP, CG, GA, DD 
                                <a style={{color: '#4285f4'}} onClick={()=>{window.open('https://t.me/joinchat/JBW2Sk4bXy24YQJPWpnSEw')}}>    (https://t.me/joinchat/JBW2Sk4bXy24YQJPWpnSEw)</a>
                                </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                ग्रुप 3<br></br>
                                राज्य : (अपने रजिस्ट्रेशन के पहले 2 अक्षर देखें)<br></br>

                                DL, UP, UK, HR, PB, HP, CH, JM, KS, LD
                                <a style={{color: '#4285f4'}} onClick={()=>{window.open('https://t.me/joinchat/JBW2ShnOHclOFCwHzOgjoQ')}}>    (https://t.me/joinchat/JBW2ShnOHclOFCwHzOgjoQ)</a>
                                </p>
                            </div>
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                ग्रुप 4<br></br>
                                राज्य : (अपने रजिस्ट्रेशन के पहले 2 अक्षर देखें)<br></br>

                                TL, KR, Kl, TN, AP, AU, AN 
                                <a style={{color: '#4285f4'}} onClick={()=>{window.open('https://t.me/joinchat/JBW2SlL1077nie_2PuaTEA')}}>    (https://t.me/joinchat/JBW2SlL1077nie_2PuaTEA)</a>
                                </p>
                            </div>
                        
                            <div style={{ margin: '20px 0px' }}>
                                <p style={{ fontSize: 15, fontWeight: 700, color: '#000' ,padding:'13px'}}>
                                अपने अपने राज्य के अनुसार ग्रुप जॉइन करें और आज से अपने विडियो यहां भेजें और अन्य कहीं भेजे गए विडियो मान्य नहीं होंगे। यह आप सब की और वॉलंटियर्स जो आपके विडियोज का निरीक्षण करते हैं उनकी सुविधा के लिए किया गया है।
                            <br></br>
                            यदि कोई इन नियमों का पालन नहीं करता है तो उनकी वीडियोज स्वीकार नहीं की जाएंगी।
                                 </p>
                            </div>
                            
                    </div>
                    </Alert>
                </div>
            </div>
        )
    }
}
     export default withRouter(Aboutus);