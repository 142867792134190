import React, { Component, cloneElement } from "react";
import { Alert, Button, CardImg } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { Row, P} from 'reactstrap';
import { withRouter } from "react-router-dom";
import Logo from '../Assets/Images/logo.png';
// import Donate from '../Component/Donate';
// import '../Common/donate.html';
import Books from '../Assets/Images/Books'
import Audio from '../Assets/Images/Audio'
import Knowledge from '../Assets/Images/Knowledge'
import RegisterNow from '../Assets/Images/RegisterNow'






class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(preProps, preState) {
        this.GetAdminDashboardData();
          
    }


    GetAdminDashboardData = async () => {
        this.setState({ loading: true })
        let val_obj = {
            url: `Dashboards/PublicDashboard`,
            data: '',
            request_type: 'get'
        }
        
        let response = await requestServerData(val_obj, 'user');
        if (response.response) {
           
            var getdata = response.data.data.result;
       
            this.setState({              
              
                ContestentCount: getdata.ContestentCount, 
                ApprovedShlokCount: getdata.ApprovedShlokCount,               
                 
                
            })
             

        } else {
            alert('Try Again after some time . . .')
        }

    }
          
    

    render() {

        let mobile = window.innerWidth < 769
        // alert(mobile)

        return (

            <React.Fragment>

                
                <div>
                    <Alert color="warning" className="shadow" style={{ backgroundColor: '#fff', textAlign: 'center', width: '100%', padding: 20 }}>
                        
                    {/* <Row className="justify-content-between">

                        <div className="col-md-3 col-xs-12">
                            
                            <button type="button" class="btn btn-info">
                            Total Contestant<span class="badge badge-light" style={{ fontSize:'17px',height:'30px' }}> <p>{this.state.ContestentCount}</p></span>
                            <span class="sr-only">unread messages</span>  
                            </button>
                            
                        </div>



                            <div className="col-md-3 col-xs-12">
                            <button type="button" class="btn btn-info">
                                Approved Shlok <span class="badge badge-light"  style={{ fontSize:'17px',height:'30px' }}>  <p>{this.state.ApprovedShlokCount}</p></span>
                                <span class="sr-only">unread messages</span>

                            </button>
                            </div>

                        </Row> */}

                       <Row>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ width: 250, height: 350 }}>
                                <CardImg top width="100%" height={'100%'} width={200} src={Logo} alt="Card image cap" />
                            </div >
                            </div>
                        </Row>



{/*  
                        <Row className="justify-content-between">

                                <div className="col-md-3 col-xs-12">
                                    
                                
                                <p style={{fontSize:'20px' ,color:'black'}}>Registered User:  <span class="badge badge-light" style={{ fontSize:'17px',height:'30px' }}> <p>{this.state.ContestentCount}</p></span></p>
                                    <span class="sr-only">unread messages</span>  
                                
                                    
                        </div>



    <div className="col-md-3 col-xs-12">
 
    <p style={{fontSize:'20px' ,color:'black'}}>Received Shloka:  <span class="badge badge-light"  style={{ fontSize:'17px',height:'30px' }}>  <p>{this.state.ApprovedShlokCount}</p></span></p>
        <span class="sr-only">unread messages</span>

    </div>

</Row> */}
                      



                        
                        {/* <Row className="justify-content-center">
                        <div className="col-md-5 col-xs-12" style={{ margin: 10 }}>
                        <input type="search"  name="gsearch" placeholder="Search" style={{width:'100%'}}/>
                        </div>    
                       </Row> */}

                          {/* <Row className="justify-content-center">
                          <div className="col-md-3 col-xs-12">
                            <div>
                            {/* <div className={mobile ? 'home-page-main-mobile' : 'home-page-main'}> */}
                                {/* <p className={mobile ? 'home-mobile-p' : 'home-p'}>Knowledge</p> */}
                            {/* <button type="button"
                            class="btn btn-warning waves-effect btn-block"
                             onClick={() => window.location.href="https://pages.razorpay.com/Vedic-Wisdom"}> Donate US </button>


                            </div>
                            </div>
                          </Row> */} 

                        <div style={{ margin: 20 }} >Vedic Wisdom welcomes you on the blissful journey to Vedic Heritage.
                        <p style={{ color: '#111', fontSize: 22, fontWeight: 'bolder', margin: 0, padding: 0 }}></p>
                         </div>
                     <Row>
                     <div className="col-md-3 col-xs-12">

                            <div
                             onClick={() => this.props.history.push('register-user')} style={{ margin: 10 }}>

                                {/* <button type="button" class="btn btn-outline-secondary btn-rounded waves-effect btn-block" > Register Now</button> */}
                                <RegisterNow width={50} height={50} fill="black" style={{ marginRight: 5 }} />
                             <p style={{fontSize:'20px' ,color:'black'}}>Register Now</p>
                            </div>
                            
                       </div>

                                <div className="col-md-3 col-xs-12">
                                <div style={{ margin: 10 }}>
                            {/* <div className={mobile ? 'home-page-main-mobile' : 'home-page-main'}> */}
                                {/* <p className={mobile ? 'home-mobile-p' : 'home-p'}>Books</p> */}

                             {/* <button type="button" class="btn btn-outline-secondary btn-rounded waves-effect btn-block" >  < width={26} height={26} fill="#4285f4" style={{ marginRight: 5 }} /></button> */}
                             <Books width={50} height={50} fill="black" style={{ marginRight: 5 }} />
                             <p style={{fontSize:'20px' ,color:'black'}}>Books</p>
                            </div>

                                </div>





                         <div className="col-md-3 col-xs-12">
                        <div style={{ margin: 10 }}>
                            {/* <div className={mobile ? 'home-page-main-mobile' : 'home-page-main'}>
                                <p className={mobile ? 'home-mobile-p' : 'home-p'}>Audio</p> */}
                            {/* <button type="button" class="btn btn-outline-secondary btn-rounded waves-effect btn-block" > Audio</button> */}
                            <Audio width={50} height={50} fill="black" style={{ marginRight: 5 }} />
                             <p style={{fontSize:'20px' ,color:'black'}}>Audio</p>
                            </div>
                            </div>

                            <div className="col-md-3 col-xs-12">
                            <div style={{ margin: 10 }}>
                            {/* <div className={mobile ? 'home-page-main-mobile' : 'home-page-main'}> */}
                                {/* <p className={mobile ? 'home-mobile-p' : 'home-p'}>Knowledge</p> */}
                            {/* <button type="button" class="btn btn-outline-secondary btn-rounded waves-effect btn-block" > Knowledge</button> */}
                            <Knowledge width={50} height={50} fill="black" style={{ marginRight: 5 }} />
                             <p style={{fontSize:'20px' ,color:'black'}}>Knowledge</p>
                            </div>
                            </div>



                        {/*
                            <div className={mobile ? 'home-page-main-mobile' : 'home-page-main'} onClick={() => this.props.history.push('donation-form')}>
                                <p className={mobile ? 'home-mobile-p' : 'home-p'}>Donation</p>

                            </div> */}





                        </Row>
                        
                        

   



 
                        <Row className="justify-content-between">

                                <div className="col-md-3 col-xs-12">
                                    
                                
                                <p style={{fontSize:'20px' ,color:'black'}}>RegisteredUser:  <span style={{ fontSize:'17px',height:'30px' }}> {this.state.ContestentCount}</span></p>
                                    <span class="sr-only">unread messages</span>  
                                
                                    
                        </div>
                        
    <div className="col-md-3 col-xs-12">
 
 <p style={{fontSize:'20px' ,color:'black'}}>ReceivedShloka: <span style={{ fontSize:'17px',height:'30px' }}>  {this.state.ApprovedShlokCount}</span></p>
     <span class="sr-only">unread messages</span>

 </div>
                        </Row>

                        {/*
                <div style={{ width: '100%', marginTop: 40, display: 'flex', justifyContent: 'space-around' }}>
                <div className={mobile ? 'home-page-main-btm-mobile' :'home-page-main-btm'} onClick={()=>this.props.handleChangeView('add_contestent')}>
                <p className={mobile ? 'home-mobile-p' :'home-p'}>Register Now</p>
                </div>

                <div className={mobile ? 'home-page-main-btm-mobile' :'home-page-main-btm'}>
                <p className={mobile ? 'home-mobile-p' :'home-p'}>Books</p>
                </div>

                <div className={mobile ? 'home-page-main-btm-mobile' :'home-page-main-btm'}>
                <p className={mobile ? 'home-mobile-p' :'home-p'}>Audio</p>
                </div>

                <div className={mobile ? 'home-page-main-btm-mobile' :'home-page-main-btm'}>
                <p className={mobile ? 'home-mobile-p' :'home-p'}>Knowladge</p>
                </div>
            </div> */}


                        {/*<div style={{ marginTop: 20 }}>
                    <Button color="primary" size="lg" block onClick={() => { this.props.handleChangeView('add_contestent') }}>Register</Button>
                </div> */}
                    </Alert>
                </div>
                <div>
                    <Alert color="warning" className="shadow" style={{display:'flow-root', backgroundColor: '#fff', padding: 20 }}>
                        {/* <div style={{display:'flex', justifyContent:'space-around'}}> */}
                       
                        <Row className="justify-content-center">
                        <div className="col-md-2 col-xs-12">
                        <div className={mobile ? "custom-icom-home-footer-mobile": "custom-icom-home-footer"}onClick={()=>{this.props.history.push('/about-us')}}>
                            <a className="home-custom-icon">A</a>
                            <a style={{ color: '#4285f4', fontWeight: 400 }} onClick={()=>{this.props.history.push('/about-us')}}>About Us</a>
                        </div>
                        </div>
                        <div className="col-md-2 col-xs-12">
                        <div className={mobile ? "custom-icom-home-footer-mobile": "custom-icom-home-footer"}>
                            <a className="home-custom-icon">D</a>
                            <a style={{ color: '#4285f4', fontWeight: 400 }}onClick={()=> window.location.href="https://pages.razorpay.com/Vedic-Wisdom"}>Donation</a>
                        </div>
                        </div>
                        <div className="col-md-3 col-xs-12">
                        <div className={mobile ? "custom-icom-home-footer-mobile": "custom-icom-home-footer"} onClick={()=>{this.props.history.push('/terms')}}>
                            <a className="home-custom-icon">T</a>
                            <a style={{ color: '#4285f4', fontWeight: 400 }} onClick={()=>{this.props.history.push('/terms')}}>Terms & Condition</a>
                        </div>

                        </div>
                        <div className="col-md-2 col-xs-12">
                        <div className={mobile ? "custom-icom-home-footer-mobile": "custom-icom-home-footer"} onClick={()=>{this.props.history.push('/faq')}}>
                            <a className="home-custom-icon">F</a>
                            <a style={{ color: '#4285f4', fontWeight: 400 }} onClick={()=>{this.props.history.push('/faq')}}>Faq</a>
                        </div>
                        </div>
                     
                        <div className="col-md-2 col-xs-12">
                        <div className={mobile ? "custom-icom-home-footer-mobile": "custom-icom-home-footer"}>
                            <a className="home-custom-icon">C</a>
                            <a style={{ color: '#4285f4', fontWeight: 400 }} onClick={()=>{this.props.history.push('/contactus')}}>Contact Us</a>
                        </div>
                        </div>
                       </Row>
                        

                      

                        {/* </div> */}
                    </Alert>
                    <Row>
                       <div className="col-md-4 col-xs-12">
                       
                        </div>
                         
                        <div className="col-md-4 col-xs-12">
                        <p class="text-center" style={{ fontSize: '12px' }}>
                        Copyright @ Vedic Wisdom <br/>Designed by <a href="http://www.satisfactionwebsolution.com" target="_blank">Satisfaction Web Solution Pvt. Ltd.</a>
                    </p>
                            
                       
                        </div>
                       

                    

                        </Row>
                 
              
              
                </div>

                


            </React.Fragment>
        );
    }
}



export default withRouter(HomePage);