import * as React from "react"

function FeedbackSvgComponent(props) {
  return (
    <svg height="512pt" viewBox="0 0 512 512" width="512pt" {...props}>
      <path d="M211 286c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm0 0M256 361c-49.629 0-90 40.371-90 90v61h180v-61c0-49.629-40.371-90-90-90zm0 0M136 316c0-24.813-20.188-45-45-45s-45 20.188-45 45 20.188 45 45 45 45-20.188 45-45zm0 0M466 316c0-24.813-20.188-45-45-45s-45 20.188-45 45 20.188 45 45 45 45-20.188 45-45zm0 0M76 0L53.18 53.8 0 61.3l38.355 36.434L29.535 151 76 126.715 122.465 151l-8.82-53.266L151 61.301l-51.621-7.5zm0 0M279.379 53.8L256 0l-22.82 53.8L181 61.3l37.355 36.434-8.82 53.266L256 126.715 302.465 151l-8.82-53.266L331 61.301zm0 0M436 0l-22.82 53.8L361 61.3l37.355 36.434-8.82 53.266L436 126.715 482.465 151l-8.82-53.266L512 61.301l-52.621-7.5zm0 0M421 391c-19.938 0-38.184 6.691-53.098 17.66C372.922 421.875 376 436.047 376 451v61h136v-31c0-49.707-41.293-90-91-90zm0 0M0 481v31h136v-61c0-14.953 3.078-29.125 8.098-42.34C129.184 397.691 110.938 391 91 391c-49.707 0-91 40.293-91 90zm0 0" />
    </svg>
  )
}

export default FeedbackSvgComponent