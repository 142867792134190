import React from "react";

const SvgGoogleLogoColor = props => (
    <svg width={48} height={48} {...props}>
        <path
            d="M11.752 28.59l-1.532 5.718-5.6.118a22.039 22.039 0 01-.16-20.544l4.984.914 2.183 4.954a13.13 13.13 0 00.123 8.84z"
            fill="#fbbb00"
        />
        <path
            d="M45.617 19.89a21.992 21.992 0 01-7.843 21.267l-6.277-.32-.888-5.546a13.112 13.112 0 005.642-6.7H24.484v-8.7h21.133z"
            fill="#518ef8"
        />
        <path
            d="M37.773 41.156a21.908 21.908 0 01-13.772 4.845A22 22 0 014.622 34.426l7.13-5.831a13.085 13.085 0 0018.855 6.7z"
            fill="#28b446"
        />
        <path
            d="M38.044 7.065L30.916 12.9a13.083 13.083 0 00-19.286 6.85l-7.169-5.867a22.005 22.005 0 0133.583-6.818z"
            fill="#f14336"
        />
        <path fill="none" d="M0 0h48v48H0z"/>
    </svg>
);

SvgGoogleLogoColor.defaultProps = {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48'
};

export default SvgGoogleLogoColor;
