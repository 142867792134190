import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import { getFromSession } from '../Utils/SessionStorage'
import 'react-toastify/dist/ReactToastify.css';
const date = new Date();    
class ViewWallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalContestnentPayout: "",
            TotalShlokCount: "",
            TotalAmountPaid: "",
           
             admin: getFromSession('admin'),
            initial:false
        };
    }
    
    // handleEnterSubmit = async (event) => {
    //     document.getElementById("myDIV").style.visibility = "hidden";
    //     if (event.keyCode === 13) {
    //         this.handleOnSubmit();
    //     }
    // }

    requestPayments = async (key) => {
       
       

           
                // if(this.state.totalContestnentPayout>500)
                // {
                   
                    if (this.state.claimWalletData) {

                             this.setState({
                             loading: true,
                            // isDisable:true
                                                    })

                         let claimWalletData = this.state.claimWalletData

                        claimWalletData && claimWalletData.map((item, index) => {

                             Object.assign(item, { RegCode: this.state.regcode})
                                         })

                             let data = claimWalletData
                             let val_obj = {
                             url: `ApprovedShloks/ClaimWallet`,
                       
                         data: data,
                         request_type: 'POST'
                                              }

                    //   console.log('requst payment', val_obj)
                     let response = await requestServerData(val_obj, 'admin')
                        // console.log('requst payment', response.data.data.result)

                             if (response.response) {
                              this.getWalletData();

                                 this.setState({
                             claimWalletData: '',totalContestnentPayout:0,
                                isDisable:false
                                     })
                   
                    // alert("Payment Done Successfully")

                //    toast.success(response.data.data.ResponceMessage)
                } else {
                    this.setState({
                        isDisable:false
                    })
                    toast.error(response.data.data.ResponceMessage)
                        }
                    }
                    
                 
            // }
            // else
            // {
               
            //  toast.error("Payment request is acceptable only above 500 Rs")  
                   
            // }
           
          

        

        this.setState({
            loading: true
        })
    }






    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleOnSubmit = async () => {

        this.getWalletData()
        }

    getWalletData = async () => {
        this.setState({
            loading: true
        })

        let data =
        {
            "RegCode": this.state.regcode,
            "ClaimStatus": "unclaimed",
        }
             
               let val_obj = {
                // url: `ApprovedShloks/WalletHistoryByRegNo?RegNum=${this.state.regcode}`,
                // url: `ApprovedShloks/WalletHistory` + this.state.regcode,
                url: `ApprovedShloks/WalletHistory`,
                data:data,
                // data: '',
                request_type: 'POST'
            }
            console.log("abc",val_obj)
          
    
              
            //console.log('wallet data val obj', val_obj)
            let response = await requestServerData(val_obj, 'admin')
           
            console.log('wallet list', response.data.data.result)

            // debugger
  
            
            if (response.response) {
                
                if (response.data.data.result && response.data.data.result.length > 0)
                {
                        const data = {
                            columns: [

                                { label: 'Date', field: 'DateOnSubmitiononTelegram', },
                                { label: 'Total Shlok ', field: 'ShlokCount', },
                                { label: 'Amount', field: 'TotalAmount', },
                                { label: ' Status', field: 'ClaimStatus', },
                            ],
                            rows: this.assemblePosts(response.data.data),
                        }
            
                            let totalAmount = 0
                            response.data.data.result && response.data.data.result.map((item, index) =>
                             {
            
                                if (item.TotalAmount > 0) {
                                    totalAmount = totalAmount + item.TotalAmount
                                }
                                })
                                    this.setState({ claimWalletData: response.data.data.result, walletData: data, totalContestnentPayout: totalAmount })
                                }
                                else {
                                    this.setState({ walletData: [] })
                                }
            }
     else {
                
         alert('try again . . .')
      }
            this.setState({ loading: false });
    }
    
        assemblePosts = (ContestnentData) => {
    
            let posts = ContestnentData && ContestnentData.result && ContestnentData.result.map((post) => {
                return (
                    {
                       
                        DateOnSubmitiononTelegram: post.DateOnSubmitiononTelegram.split('T')[0],
                        ShlokCount: post.ShlokCount,
                        TotalAmount: post.TotalAmount,
                        ClaimStatus: post.ClaimStatus,
                    }
                )
            });
            return posts || '';
        }
    

    render()
    {
        return(
            
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <Card >
                    <div align="center"><h3>View Wallet Amount </h3></div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start',alignItems:"center",flexDirection:"row",borderColor: 'black', backgroundColor: 'black', color: 'white', borderRadius: '5px 20px 5px', fontweight: 'bold', padding: '15px 20px', width: '100%',flexWrap:"wrap" }}>
                    <h5 >Registration Code : </h5>
                    <from style={{ display: 'flex', justifyContent: 'flex-start',alignItems:"center",flexDirection:"row",flexWrap:"wrap" }}>
                            <input type="text" name="regcode" onChange={(e) => this.handleChange(e)} id="regcode" />
                            <button onClick={(e) => this.handleOnSubmit()} type="submit" >Submit</button>
                        </from>

                    </div>
                    </Card>
           
                    
              <div className={'top-main'} style={{ height: window.innerHeight }}>
              <div className="content-wrapper">
                  <ToastContainer position="top-center" />
                  <Row>
                      <Col md="12">
                          <div class="card">
                              <div class="card-body">
                                  <h3 className="page-center">Vedic Earning</h3>
                              </div>
                          </div>
                      </Col>
                  </Row>
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 20 }}>

                      <p color="primary" >Total Wallet Amount : <span class="badge badge-primary"> {this.state.totalContestnentPayout} </span> </p>

                      <div>
                        <Button color={'primary'} onClick={(e) => this.requestPayments()}  >Request Payment</Button>

                     
                      </div> 

                  </div>
                  <Row className="mb-4">
                      <Col md="12">
                          <Card>
                              <CardBody>
                                  {/* <p color = "primary" >Total Shlok : {this.state.totalContestnentPayout} </p>
                              <p color = "primary"  >Total Amount : {this.state.totalContestnentPayout} </p> */}
                                  <div className="table-responsive">
                                      <MDBDataTable
                                          id={'pendingPayouts'}
                                          paging={false}
                                          striped
                                          bordered
                                          hover
                                          data={this.state.walletData}
                                      />
                                  </div>
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>
              </div>
          </div>
          </div>
        )

        
    }



}

export default withRouter(ViewWallet);