// -----------Validate input------------


export const emailVerifier = value => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'The entered email address does not have a valid format.'
    } else {
        return ""
    }
}

export const upiIdCheck = value => {

    if (!/([@]+)/g.test(value)) {
        return 'Upi Id is not valid'
    } else {
        return ""
    }

}

export const AllowOnlyNumber = value => {
    if (value) {
        if (/[0-9]$/.test(value)) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

export const SpaceNotAllowed = value => {
    if (/^\S*$/.test(value)) {
        return true
    } else {
        return false
    }
}

export const AllowOnlyNumberAlfaNumerik = value => {
    if (/^[a-zA-Z0-9]+$/.test(value)) {
        return true
    } else {
        return false
    }
}