import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { getFromSession } from '../Utils/SessionStorage'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const date = new Date();
class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalContestnentPayout: "",
            TotalShlokCount: "",
            TotalAmountPaid: "",
            start: this.formatDate(date),
            end: this.formatDate(date),
            user: getFromSession('user'),

        };
    }
    componentDidMount() {

        this.getPayments()

    }
    formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    render() {
        //console.log(this.state.start)

        if (this.state.loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <div >
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            )
        }

        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer position="top-center" />
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">Payment</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />

                    <Row>
                    <div className="col-md-3 col-sm-12" >
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            {/* <div style={{ marginRight: 20, }}>
                                <Label style={{ color: '#000' }}>Select Date</Label>
                            </div> */}
                            </div>
                            </div>
                            <div className="col-md-3 col-sm-12" >
                            <Input value={this.state.start} type="date" name={'date1'} onChange={e => this.handleChangeDate(e)} style={{margin:'10px 0'}} />
                             </div>
                             <div className="col-md-3 col-sm-12" >
                            <Input value={this.state.end} type="date" name={'date2'} onChange={e => this.handleChangeEndDate(e)} style={{margin:'10px 0'}} />
                            </div>

                            <div className="col-md-3 col-sm-12" >
                            <Button color={'primary'} onClick={() => this.getPendingPayouts()}>View</Button>
                        </div>
                        {
                            this.state.PendingPayout && this.state.PendingPayout.length > 0 &&
                            <div>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="PendingPayouts"
                                    filename={`${this.state.dateForGetTableData}pendingPayoutsData`}
                                    sheet={`${this.state.dateForGetTableData}pendingPayoutsData`}
                                    buttonText="Download as XLS" />
                            </div>
                        }
                   
                    </Row>
                    <Row className="mb-4">
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <div style={{ justifyContent:'space-around'}}>
                                       <Row>
                                           <div className="col-md-4 col-sm-12">
                                           <p color="primary"  >Total    : <span class="badge badge-primary"> {this.state.totalContestnentPayout}</span> </p>
                                               </div>

                                               <div className="col-md-4 col-sm-12">
                                               <p color="primary"  >Paid Amount : <span class="badge badge-success"> {this.state.paidCount} </span> </p>
                                               </div>

                                               <div className="col-md-4 col-sm-12">
                                               <p color="primary"  >Unpaid Amount : <span class="badge badge-danger"> {this.state.unPaidCount} </span> </p>
                                               </div>
                                        </Row>   
                                    </div>

                                   <div className="table-responsive">
                                    <MDBDataTable
                                        id={'pendingPayouts'}
                                        paging={true}
                                        striped
                                        bordered
                                        hover
                                        data={this.state.paymentTableData}
                                    />
                                    </div>

                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    handleChangeDate=async(e)=>{ this.setState({ start: e.target.value  }); }
    handleChangeEndDate=async(e)=>{ this.setState({ end: e.target.value});  }

    getPayments = async () => {

        this.setState({
            loading: true
        })

        let data =
        {
            "RegCode": this.state.user.data.uid,
            "PayStatus": "",
            "FirstDate": "",
            "SecondDate": ""
        }
        let val_obj = {
            url: `ApprovedShloks/PaymentHistory`,
            data: data,
            request_type: 'POST'
        }

        let response = await requestServerData(val_obj, 'user')

        if (response.response) {

            const data = {
                columns: [
                    { label: 'Req Date', field: 'PaymentRequestedDate', },
                    { label: ' Paid Date', field: 'PaidDate', },
                    { label: 'Amount', field: 'TotalAmount', },
                    { label: ' Total ', field: 'ShlokCount', },
                    { label: ' Status', field: 'PayStatus', },
                    { label: ' TransactionNo', field: 'TransactionId', }
                ],
                rows: this.assemblePosts(response.data.data),
            }

            let totalAmount = 0
            let paidCount = 0
            let unPaidCount = 0
            response.data.data.result && response.data.data.result.map((item, index) => {

                if (item.TotalAmount > 0) {
                    totalAmount = totalAmount + item.TotalAmount
                }
                if (item.PayStatus === "Paid") {
                    paidCount = paidCount + item.TotalAmount
                }
                if (item.PayStatus === 'UnPaid') {
                    unPaidCount = unPaidCount + item.TotalAmount
                }
            })
            this.setState({ paymentData: response.data.data.result, paymentTableData: data, totalContestnentPayout: totalAmount, paidCount:paidCount, unPaidCount:unPaidCount })
        } else {

            alert('try again . . .')
        }
        this.setState({ loading: false })
    }

    assemblePosts = (paymentData) => {

        let posts = paymentData.result && paymentData.result.map((post) => {
            return (
                {
                    PaymentRequestedDate: post.PaymentRequestedDate.split('T')[0],
                    PaidDate: post.PaymentRequestedDate && post.PaymentRequestedDate.split('T')[0],
                    TotalAmount: post.TotalAmount,
                    ShlokCount: post.ShlokCount,
                    PayStatus: post.PayStatus,
                    TransactionId:post.TransactionId
                }
            )
        });
        return posts;
    }

    getPendingPayouts = async () => {
        
        this.setState({
            loading: true
        })

        let data =
        {
            "RegCode": this.state.user.data.uid,
            "PayStatus": "",
            "FirstDate": this.state.start,
            "SecondDate": this.state.end
        }
        let val_obj = {
            url: `ApprovedShloks/PaymentHistory`,
            data: data,
            request_type: 'POST'
        }

       //console.log('requst payment', val_obj)
        let response = await requestServerData(val_obj, 'user')

        if (response.response) {
            let totalAmount = 0
            let paidCount = 0
            let unPaidCount = 0
            if(response.data.data.result.length > 0){
               
            
            const data = {
                columns: [
                    { label: 'Req Date', field: 'PaymentRequestedDate', },
                    { label: 'Paid Date', field: 'PaidDate', },
                    { label: 'Amount', field: 'TotalAmount', },
                    { label: ' Total Shlok', field: 'ShlokCount', },
                    { label: ' Status', field: 'PayStatus', },
                    { label: ' TransactionNo', field: 'TransactionId', },
                ],
                rows: this.assemblePosts(response.data.data),
            }


            response.data.data.result && response.data.data.result.map((item, index) => {

                if (item.TotalAmount > 0) {
                    totalAmount = totalAmount + item.TotalAmount
                }
                if (item.PayStatus === "Paid") {
                    paidCount = paidCount + item.TotalAmount
                }
                if (item.PayStatus === 'UnPaid') {
                    unPaidCount = unPaidCount + item.TotalAmount
                }
            })
            this.setState({ paymentData: response.data.data.result, paymentTableData: data, totalContestnentPayout: totalAmount, paidCount:paidCount, unPaidCount:unPaidCount })
        }else{
            toast.warn('No data Found');
            this.setState({ paymentData: [],
                 paymentTableData: [],
                  totalContestnentPayout: totalAmount,
                   paidCount:paidCount, 
                   unPaidCount:unPaidCount })
        }
        } else {

            toast.error(response.data.data.ResponceMessage)
        }

        this.setState({
            loading: false
        })
    }


}

export default withRouter(Payment);