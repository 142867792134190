import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { getFromSession } from '../Utils/SessionStorage'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../UsersComponents/Profilepicture.css'


// componentDidMount() 
// {

//     this.handleSubmit()
// }
const ImgUpload =({
    onChange,
    src
  })=>
        <label htmlFor="photo-upload" className="custom-file-upload fas">
        <div className="img-wrap img-upload" >
            <img for="photo-upload" src={src}/>
        </div>
        <input id="photo-upload" type="file" onChange={onChange}/> 
        </label>
 
  const Profile =({
    onSubmit,
    src,
                })=>
            <div className="card" style={{width: "600px",
                height: "450px",
                padding: "15px",
                marginTop: "40px",
                borderRadius: "25px",
                alignItems: "center",
                marginLeft:"100px",
                display: "flex",
                justifyContent: "center"}}>
                <form onSubmit={onSubmit}>
                    <h1 style={{fontStyle:"bold"}}>Profile Picture</h1>
                    <label className="custom-file-upload fas">
                    <div className="img-wrap" >
                    <img for="photo-upload" src={src}/>
                    </div>
                    </label>
                    <button type="submit" className="edit">Edit Profile </button>
                </form>
            </div>
       
        
  const Edit =({
    onSubmit,
    children,
  })=>
    <div className="card" style={{width: "600px",
        height: "450px",
        padding: "15px",
        marginTop: "40px",
        borderRadius: "25px",
        alignItems: "center",
        marginLeft:"100px",
        display: "flex",
        justifyContent: "center"}}>
        <form onSubmit={onSubmit}>
            <h1>Choose New Profile Picture</h1>
            {children}
            <button type="submit" className="save">Save </button>
        </form>
    </div>
  
  class ProfilePicture extends React.Component {
    state = {
      file: '',
      imagePreviewUrl: 'https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true',
      active: 'edit'
    }
  
    photoUpload = e =>{
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
   
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      }
      reader.readAsDataURL(file);
    }


    handleSubmit= e =>{
      e.preventDefault();
      let activeP = this.state.active === 'edit' ? 'profile' : 'edit';
      this.setState({
        active: activeP,
      })
    }
    
    render() {
      const {imagePreviewUrl,  
             active} = this.state;
      return (
        <div>
          {(active === 'edit')?(
            <Edit onSubmit={this.handleSubmit}>
              <ImgUpload onChange={this.photoUpload} src={imagePreviewUrl}/>
            </Edit>
          ):(
            <Profile 
              onSubmit={this.handleSubmit} 
              src={imagePreviewUrl} 
              />)}
          
        </div>
     
     );
    }
 }
  






//  handleSubmit = async () => {

//     this.setState({
//         loading: true
//     })
//     const { registerCode, name, image} = this.state;

//     let data = {
       
//     }

//     let val_obj = {

//         data: data,
//         request_type: 'POST',
//         url: 'uploads/profile'
//     }
//    // console.log('val_obj', val_obj)
//     let response = await requestServerData(val_obj, 'user')
//    // console.log('update user profile', response)

//     if (response.response) {
//         toast.success('Updated Successfully.')
//         this.getUserData()
//     } else {

//         toast.error('Error')
//     }
//     this.setState({
//         loading: false
//     })
// }

export default withRouter(ProfilePicture);
