import React from 'react'
import { Card, CardTitle, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { requestServerData } from '../Utils/Config'

class UserShlokList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps, preState) {

        if (preProps.shlokList !== this.props.shlokList) {

            if (this.props.showSlock) {

                this.setState({
                    loading: false
                })
            }
        }
        if (preState.selectedChapter !== this.state.selectedChapter) {

            this.setState({
                [preState.selectedChapter]: !preState.selectedChapter,
                [this.state.selectedChapter]: true
            })
        }
    }

    render() {

        let mobile = window.innerWidth < 769

        return (
            <React.Fragment>
                {/* <div style={{ overflow: 'scroll', padding: 20, width: '100%', minHeight: '100%', maxHeight: 300 }}> */}
                <div>
                    {
                        this.props.chapterList ?
                            <div style={{ padding: 20, width: '100%' }}>
                                <CardTitle className={'shadow'} style={{ padding: 5, margin: 0, fontWeight: 'bold', }}>{this.props.title}</CardTitle>
                                <div style={{ margin: '20px 5px 0px 0px', width: '100%', padding: 5 }}>
                                    {
                                        this.renderChapterList(this.props.chapterList)
                                    }
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: 'center', width:'100%' }}>
                                <CardTitle style={{ padding: 0, margin: 0, fontWeight: 'bold' }}>{"You don't have any attampted shlok"}</CardTitle>
                            </div>
                    }
                </div>
                <div style={{ padding: '20', width: '100%' }}>
                    {
                        this.state.showShlock ?
                            this.state.loading ?
                                <div style={{ margin: '15px 0px', textAlign: 'center' }}>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                                </div>
                                :
                                <div className={''} style={{ overflow: 'scroll', padding: 10, width: '100%', minHeight: '100%', maxHeight: 400 }}>
                                    <div className={''} style={{ padding: 10, width: '100%', margin: '20px 0px 0px 0px' }}>
                                        {
                                            !mobile &&
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                {/* <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10, width: '10%' }}>
                                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>Shlok No.</CardTitle>
                                                </div> */}
                                                {/* <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10, width: '15%' }}>
                                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>Telegram Post Date</CardTitle>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10, width: '10%' }}>
                                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>Rating</CardTitle>
                                                </div> */}
                                                {/* <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10, width: '60%' }}>
                                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>Shlok </CardTitle>
                                                </div> */}
                                            </div>
                                        }

                                        {
                                            this.renderShlokList()
                                        }
                                    </div>
                                </div>
                            :
                            null
                    }
                </div>
            </React.Fragment>
        );
    }

    renderChapterList = (data) => {

        let mobile = window.innerWidth < 769

        return data && data.map((item, index) => {

            if (mobile) {

                return (
                    <div key={index} className={'shadow'} style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', padding: 10, width: '48%', float: "left" }} onClick={() => { this.getShlockListForUser(item.ChapterNO) }}>
                        <div>
                            {
                                item.ChapterNO &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 10, fontWeight: 'bold' }}>Chapter No : {item.ChapterNO}</CardTitle>
                            }
                            {
                                item.ShalokCount &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 10, fontWeight: 'bold' }}>{item.ShalokCount}</CardTitle>
                            }
                            {
                                item.ChapterName &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 10, fontWeight: 'bold' }}>{item.ChapterName}</CardTitle>
                            }
                        </div>
                        <div>
                            <Input type="radio" name={'redio'}/>
                        </div>
                    </div>
                )
            } else {

                return (
                    <div key={index} className={'shadow'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: 5, width: '25%', float: "left" }} onClick={() => { this.getShlockListForUser(item.ChapterNO) }}>
                        <div>
                            {
                                item.ChapterNO &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>Chapter No : {item.ChapterNO}</CardTitle>
                            }
                            {
                                item.ShalokCount &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>{item.ShalokCount}</CardTitle>
                            }
                            {
                                item.ChapterName &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>{item.ChapterName}</CardTitle>
                            }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Input type="radio" name={'redio'} checked={this.state.selectedChapter === item.ChapterNO && this.state[this.state.selectedChapter]} />
                        </div>
                    </div>
                )
            }
        })
    }

    renderShlokList = () => {

        let mobile = window.innerWidth < 769

        return this.props.shlokList && this.props.shlokList.map((item, index) => {

            let array_date = item.DateOfSubmissionOnTelegram && item.DateOfSubmissionOnTelegram.split('T') || undefined
            let date = array_date && array_date[0] || undefined
            if (mobile) {

                return (
                    <React.Fragment>
                        <div className={'shadow'} style={{ padding: 10, width: '100%', margin: '10px 0px 0px 0px' }}>
                            {
                                item.ShlokNo &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 10, fontWeight: 'bold' }}>Shlok No : {item.ShlokNo}</CardTitle>
                            }
                            {
                                date &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 10, fontWeight: 'bold' }}>Telegram Post Date : {date}</CardTitle>
                            }
                            {
                                item.Rating &&

                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 10, fontWeight: 'bold' }}>Rating : {item.Rating}</CardTitle>
                            }
                            {
                                item.Shalok && item.Shalok.ShlokHindi &&
                                <CardTitle style={{ padding: 0, margin: 0, fontSize: 10, fontWeight: 'bold' }}>{item.Shalok.ShlokHindi}</CardTitle>
                            }
                        </div>
                    </React.Fragment>
                )
            } else {

                return (
                    <React.Fragment>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            {/* {
                                item.ShlokNo &&
                                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10, width: '10%' }}>
                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>{item.ShlokNo}</CardTitle>
                                </div>
                            } */}
                            {
                                item.Shalok && item.Shalok.ShlokHindi &&
                                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10, width: '60%' }}>
                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>{item.Shalok.ShlokHindi}</CardTitle>
                                </div>
                            }
                                {
                                date &&
                                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10, width: '15%' }}>
                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>{date}</CardTitle>
                                </div>
                            }
                               {
                                item.Rating &&
                                <div style={{ display: 'flex', justifyContent: 'flex-start', padding: 10, width: '10%' }}>
                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 'bold' }}>{item.Rating}</CardTitle>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )
            }
        })
    }

    getShlockListForUser = (ChapterNo) => {

        this.setState({ loading: true, showShlock: true, selectedChapter: ChapterNo })
        this.props.getShlockListForUser(ChapterNo)
    }

}



export default withRouter(UserShlokList);