import React, { Component, cloneElement } from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, Spinner, Input, Label, FormGroup } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Logo from '../Assets/Images/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import { password } from "../Validation/Validation";
import { storeInSession,getFromSession } from '../Utils/SessionStorage'

class UserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successMessage: props.location.state || undefined,
            RegistrationCode: props.registrationSuccessId,
            modalShow: true

        };
    }

    componentDidMount() {

        if (this.state.successMessage) {

            toast.success(this.state.successMessage.data)

            if(this.state.successMessage.data){

                let idArray = this.state.successMessage.data.split(':')
                let id =idArray[2]?.trim()
                this.setState({
                    registrationCode: id
                })
            }
        }
        let isLoginUser = getFromSession('user')
		
        if(isLoginUser.response){

            this.props.history.push('/user-dashboard')
        }
    }

    render() {

		let mobile = window.innerWidth < 769

        return (
            <React.Fragment>
                <ToastContainer
                    position="top-center"
                />
                <div className={mobile ? 'user-login-top-mobile' : 'user-login-top'}>
                    {
                        this.state.successMessage &&
                        <Alert>
                            <p style={{ color: '#111', fontSize: 15, fontWeight: 'lighter', margin: 0, padding: 0 }}>{this.state.successMessage.data}</p>
                        </Alert>
                    }
                    <div style={{ marginTop: 20 }}>
                        <FormGroup>
                            <Label >Registration Code</Label>
                            <Input type="text" name="registrationCode" id="registrationCode" placeholder="Your registration code *" value={this.state.registrationCode} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <Label >Password</Label>
                            <Input type="password" name="password" id="password" placeholder="Your password*" value={this.state.password} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                        {
                            this.state.loading ?

                                <Button color="primary" size="lg" block>Login</Button>
                                :
                                <Button color="primary" size="lg" block onClick={() => this.handleClick('login')}>Login</Button>
                        }
                        <div style={{marginTop:20, fontWeight:'bold', fontSize:22}}>
                            <a onClick={()=>{this.handleClickForgetPassword('forget_password')}} style={{color:'#4285f4'}}>Forget Password</a>
                        </div>
                        {
                            this.state.loading &&
                            <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center' }}>
                                <Spinner style={{ width: '3rem', height: '3rem' }} />
                            </div>
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    }

    handleChange = (event) => {

        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleClickForgetPassword =()=>{

        this.props.history.push('/forget-password')
    }

    handleClick = async (id) => {

        const { registrationCode, password } = this.state

        this.setState({
            loading : true
        })
        if (registrationCode && password) {

            let val_obj = {
                data:{"username": registrationCode,
                "password": password,
                },
                request_type:'LOGIN',
                url:'Account/Login'
            }

            console.log('val_obj', val_obj)

            let response
            response = await requestServerData(val_obj)

            console.log('response', response)

            // chnge for apr
            // let data
            // response = data = {
            //     response: true,
            //     data: {
            //         data: {
            //             result: 'success'
            //         }
            //     }
            // }
            
            if(response.response){
                
                let sotre_value ={
                    token:response.data.data.Key.Token,
                    type:'user',
                    uid: registrationCode,
                    userName: response.data.data.result.ContestantName,
                    role:response.data.data.result.Role || "account"
                }
                
                let isStore = await storeInSession('user', sotre_value)
                
                if (isStore && isStore.response) {
                    
                    this.props.history.push('/user-dashboard')
                } else {
                    
                    toast.error('Please clear cache from your browser setting.')
                }
            }else{

                toast.error('Invalid Registration Code / Password')
            }
        } else {

            toast.error('Please fill all the value !')
        }
        this.setState({
            loading : false
        })
    }


}



export default withRouter(UserLogin);