
  export const formatNumber = (number,upto = 1) => {
    let prefix = "";
    if (number > 10000000) {
      prefix = "Cr";
      number = number / 10000000;
    } else if (number > 100000) {
      prefix = "L";
      number = number / 100000;
    } else if (number > 1000) {
      prefix = "k";
      number = number / 1000;
    }
    return (number !== 0 ? number.toFixed(upto) : 0) + " " + prefix;
  };

  