import React, { Component } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { getFromSession } from "../Utils/SessionStorage";

export const defaultLanding = {
  SuperAdmin: "/contestent-list",
  Admin: "/contestent-list",
  Evaluator: "/new-approved-shlok",
  Account: "/pending-payout"
};

export const userRoleVisibility = ({ user, admin, path }) => {
  let { role } = admin || {};

  if (role === "SuperAdmin") return true;
  else if (role === "Admin") return true;

  /// add route and role type of visibility here
  let routeWiseRole = {
  
    
    "contestent-list":"Account",
    "contestent-payout": "Account",
    "pending-payout": "Account",
    "contestent-update-payout": "Account",
    "donation": "Account",
    "wallet": "Account",
    "admin-change-password": "Account",

    "contestent-list":"Evaluator",
    "view-approved-slok": "Evaluator",
    "new-approved-shlok": "Evaluator",
    "admin-change-password": "Evaluator",
    
  };

  if (routeWiseRole[path] === role) return true;

  return false;
};

const PrivateRoutes = props => {
  let { component: Component, visible, ...rest } = props || {};
  let user = getFromSession("user") || undefined;
  let admin = getFromSession("admin") || undefined;

  let notRestricted = false;

  let  path =
    (props.path &&
      props.path.split("/") && 
      props.path.split("/").length &&
      props.path.split("/")[1]) ||
    "";

  if ((user && user.response) || (admin && admin.response)) {
    if (typeof visible === "function") {
      notRestricted = visible({
        user: user && user.data,
        admin: admin && admin.data,
        path: path || ""
      });
    } else if (props.hasOwnProperty("visible")) {
      notRestricted = visible;
    } else {
      notRestricted = true;
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        return notRestricted ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default withRouter(PrivateRoutes);
