import React from 'react'
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Alert, Button, Modal, ModalBody, ModalFooter, Spinner, Input, Label, FormGroup } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import reactHtmlTableToExcel from 'react-html-table-to-excel';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            getOtp: true,
        }
    }


    componentDidUpdate(preProps, preState) {

        if (preState.get_otp !== this.state.get_otp) {

            if (this.state.get_otp && this.state.get_otp.response) {

                toast.success('Verificatoin Code Sent To Your Mobile Successfully. . .')
                this.setState({ getOtp: false })
            } else {

                toast.error('Server Error')
            }
        }
        if (preState.submit_otp !== this.state.submit_otp) {

            if (this.state.submit_otp && this.state.submit_otp.response) {

                toast.success('Enter Password and Confirm Password')
                this.setState({ enterPassword: true })
            } else {

                toast.error('Server Error')
            }
        }
        if (preState.submit_password !== this.state.submit_password) {

            if (this.state.submit_password && this.state.submit_password.response) {

                this.props.history.push('/user-login', { data: 'Password reset successfully' })
            } else {

                toast.error('Server Error')
            }
        }
    }

    render() {
      
        let mobile = window.innerWidth < 769

        return (
            <React.Fragment>
                <ToastContainer
                    position="top-center"
                />
                <div className={mobile ? 'user-login-top-mobile' : 'user-login-top'}>

                    <div style={{ marginTop: 20 }}>
                        <FormGroup>
                            <Label >Registration Code</Label>
                            <Input type="text" name="registrationCode" id="registrationCode" placeholder="Your registration code *" value={this.state.registrationCode} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                            <Label >Mobile Number</Label>
                            <Input type="mobile" name="mobile" id="mobile" placeholder="Your mobile Number *" value={this.state.mobile} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>

                        {
                            this.state.getOtp ?
                                <Button color="primary" size="lg" block onClick={() => this.handleClick('get_otp')}>Get Verification Code</Button>
                                :
                                <React.Fragment>
                                    <FormGroup>
                                        <Label >Verification Code</Label>
                                        <Input type="otp" name="otp" id="otp" placeholder="Enter Verification Code *" value={this.state.otp} onChange={(e) => this.handleChange(e)} />
                                    </FormGroup>
                                    {
                                        this.state.submit_otp && this.state.submit_otp.response ?
                                            null
                                            :
                                            <Button color="primary" size="lg" block onClick={() => this.handleClick('submit_otp')}>Submit</Button>
                                    }
                                </React.Fragment>
                        }

                        {
                            this.state.enterPassword ?
                                <React.Fragment>
                                    <FormGroup>
                                        <Label >Password</Label>
                                        <Input type="password" name="password" id="password" placeholder="Your password*" value={this.state.password} onChange={(e) => this.handleChange(e)} />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label >Confirm Password</Label>
                                        <Input type="password" name="cpassword" id="cpassword" placeholder="Cofirm your password*" value={this.state.cpassword} onChange={(e) => this.handleChange(e)} />
                                    </FormGroup>

                                    <Button color="primary" size="lg" block onClick={() => this.handleClick('submit_password')}>Submit</Button>
                                </React.Fragment> :
                                null
                        }

                        {
                            this.state.loading &&
                            <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center' }}>
                                <Spinner style={{ width: '3rem', height: '3rem' }} />
                            </div>
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    }

    handleChange = (event) => {

        if(event.target.name === 'mobile'){
          
            if(AllowOnlyNumber(event.target.value)){
                
                this.setState({
                    [event.target.name]: event.target.value
                })
            }else{

                toast.error('only number is allow')
                this.setState({
                    [event.target.name]: ''
                })
            }
        }else{
            
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    handleClick = (key) => {

        const { mobile, registrationCode, otp, password, cpassword } = this.state;
        switch (key) {

            case 'get_otp':

                if (mobile && registrationCode) {

                    let val_obj = {
                        data: {
                            "Mobile": mobile,
                            "RegCode": registrationCode
                        },
                        request_type: 'POST',
                        url: 'Account/ForgotPassword'
                    }
                    this.handleRequestServer(key, val_obj)
                } else {

                    toast.error('You have to fill mobile number and registration code')
                }
                break;

            case 'submit_otp':
                if (otp) {

                    let val_obj = {
                        data: {
                            "Mobile": mobile,
                            "RegCode": registrationCode,
                            "OTP": otp,
                        },
                        request_type: 'POST',
                        url: 'Account/VerifyOTP'
                    }
                    this.handleRequestServer(key, val_obj)
                } else {

                    toast.error('Please fill Verification Code...')
                }
                break;

            case 'submit_password':
                if (password && cpassword) {

                    if (password === cpassword) {

                        let val_obj = {
                            data: {
                                "Mobile": mobile,
                                "RegCode": registrationCode,
                                "OTP": otp,
                                "Password": password,
                                "ConfirmPassword": cpassword
                            },
                            request_type: 'POST',
                            url: 'Account/UpdatePassword'
                        }
                        this.handleRequestServer(key, val_obj)
                    } else {

                        toast.error('Password Does Not Match.')
                    }
                } else {

                    toast.error('Enter Your Password.')
                }
                break;
            default:
                break;
        }
    }

    handleRequestServer = async (key, data) => {

        this.setState({ loading: true })

        let response = await requestServerData(data, 'user')

        // let response = data = {
        //     response: true,
        //     data: {
        //         data: {
        //             result: 'success'
        //         }
        //     }
        // }
        if(response.response){

            this.setState({ [key]: response})
        }else{

            toast.error(response.data.data.ResponceMessage)
        }
    this.setState({loading: false })
    }
}

export default withRouter(ResetPassword);