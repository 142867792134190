import React, { Component } from "react";
import { Card, Button, Input, Spinner, Label, Modal,CustomInput, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Terms from '../Common/Terms'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'
import { getFromSession } from '../Utils/SessionStorage'
import { setGlobalCssModule } from "reactstrap/lib/utils";
class BulkUploadPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading:'',
       
          pathname: window.location.pathname,
          target_native_id: "",
      
          admin: getFromSession("admin"),
        //   imageUrl: null,
          fileUrl: null,
          filePreviewUrl: null,
          fileChange: false
        };
      }

      handleChange = event => {
       
        if (event.target.name === "file")
       
         {
             
            let selectedFile = event.target.files[0];
           
            // const reader = new FileReader();
            // reader.onloadend = () => {
                this.setState({
                  file:  event.target.files[0],
                //   filePreviewUrl: reader.result,
                  fileChange: true
                });
            //   };
        

             
            //   if (selectedFile && selectedFile.type.match("xlxs.*")) {
            //     reader.readAsDataURL(selectedFile);
            //   }
            } 
            else {
              this.setState({
                [event.target.name]: event.target.value
              });
            }
        }
        
    





    handleUpload = async event => {       
        
      this.setState({ Loading: true });
        const { file } = this.state;

        let data = new FormData();
        data.append("file", file);
        // data.append("RegNo", registerCode);
        let val_obj = {
            data: data,
            request_type: "POST",
            url: "BulkUploads/BulkPaymentUpload"
          };
      
          
    let response = await requestServerData(val_obj, "admin");
  
    if (response.response) {
 alert("File Uploaded Successfully.")
      // toast.success("File Uploaded Successfully.");
        // this.setState({ filePreviewUrl: null, fileChange: null });
        this.setState({
          file:  null,
        //   filePreviewUrl: reader.result,
          fileChange: false
        });

  window.location.reload(false);
     
      
      } 
      else {
        toast.error("Error");
      }
  
     
      this.setState({
        Loading: false
      });

    }
 

    render(){
        return (
            <div className={"top-main" } style={{ height: window.innerHeight }}>
            <div style={{ paddingTop: "50px", margin: "auto", maxWidth: "500px" }}>
              <Card  class="column-center" style={{ padding: 20 }}>
              <ToastContainer
                        position="top-center"
                    />
                         
             
              <h3>Bulk Payment Upload</h3>
              <FormGroup>
                {/* <label>Choose File</label> */}
               <CustomInput
                type="file"
                id="file"
                name="file"
                accept="xlxs/*"
                onChange={e => this.handleChange(e)}
                // label={fileName || 'choose an image file'}
                value={this.file}
               
                // invalid={invalidFile}
                 />
                <button
                type="submit"
                class="btn btn-secondary"
                style={{
                  marginTop: "12px",
                  width: "100%",
                  backgroundColor: "#9932CC"
                }}
                onClick={() => this.handleUpload()}
              >
                Submit
              </button>
            </FormGroup>
            </Card>
            </div>



            
        </div>
          );
    }
}
export default withRouter(BulkUploadPayment);