import React from 'react'
import { Card, CardTitle, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { requestServerData } from '../Utils/Config'
import UserShlokList from '../UsersComponents/UserShlokList'
import { getFromSession } from '../Utils/SessionStorage'
import NativeDb from '../Utils/nativeDb.json'

class ShlokList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getFromSession('user'),
            showSlock: false
        }
    }

    componentDidMount() {

        this.setupComponent()
    }

    // componentDidUpdate(preState){

    //     if(preState.chapterList !== this.state.chapterList ){
    //         this.setState({
    //             loading: false
    //         })
    //     }
    // }

    render() {

        const { target_native_id, chapterList, TotalShalokCount, TotalAttemptedShalok } = this.state

        return (
            <React.Fragment>
                {
                    this.state.loading
                        ?
                        <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        <UserShlokList
                            TotalShalokCount={TotalShalokCount}
                            TotalAttemptedShalok={TotalAttemptedShalok}
                            title={target_native_id === 'user-attempted-shlok' ? `Total attampted Shlok : ${TotalAttemptedShalok}/${TotalShalokCount}` : target_native_id === 'user-non-attempted-shlok' ? `Total Non attampted Shlok : ${TotalAttemptedShalok}/${TotalShalokCount}`: 'Your all chapter and shlok list for non attampted shlok'}
                            chapterList={chapterList}
                            showSlock={this.state.showSlock}
                            shlokList={this.state[this.state.selectedChapterNumber]}
                            getShlockListForUser={this.getShlockListForUser}
                        />
                }
            </React.Fragment>
        );
    }

    setupComponent = () => {

        let current_path = window.location.pathname;
        let url_arr = current_path.split("/");
        let target = url_arr[1]
        this.setState({
            target_native_id: target
        })
        if (target === 'user-attempted-shlok') {

            this.getUserChapterListForAttamptedShlok()
        } else {

            this.getUserChapterListForNonAttamptedShlok()
        }
    }

    getUserChapterListForAttamptedShlok = async () => {

        this.setState({ loading: true })
        let val_obj = {
            url: `ApprovedShloks/GetChapterWiseShloksCount?RegNo=${this.state.user.data.uid}`,
            data: '',
            request_type: 'GET'
        }

        console.log('user val obj', val_obj)
        let response = await requestServerData(val_obj, 'user')
        console.log('user response', response)
        
        if (response.response) {

            if (response.data.data.result.ChapterWiseShaloks.length > 0) {

                this.setState({ showchaptertitle: false, chapterList: response.data.data.result.ChapterWiseShaloks, TotalShalokCount: response.data.data.result.TotalShalokCount, TotalAttemptedShalok: response.data.data.result.TotalAttemptedShalok })
            } else {

                toast.error("Invalid Registartion Number")
            }
        } else {

            toast.error("Server Error")
        }
        this.setState({
            loading: false
        })
    }

    getUserChapterListForNonAttamptedShlok = async() => {

        this.setState({ loading: true })
        let val_obj = {
            url: `ApprovedShloks/GetChapterWiseShloksNonAttemptedCount?RegNo=${this.state.user.data.uid}`,
            data: '',
            request_type: 'GET'
        }

        console.log('user val obj', val_obj)
        let response = await requestServerData(val_obj, 'user')

        console.log('user response', response)
        
        if (response.response) {

            if (response.data.data.result.ChapterWiseShaloks.length > 0) {

                this.setState({ showchaptertitle: false, chapterList: response.data.data.result.ChapterWiseShaloks, TotalShalokCount: response.data.data.result.TotalShalokCount, TotalAttemptedShalok: response.data.data.result.TotalNonAttemptedShalok })
            } else {

                toast.error("Invalid Registartion Number")
            }
        } else {

            toast.error("Server Error")
        }
        this.setState({
            loading: false
        })
    
    }

    getShlockListForUser = async (chapterNum) => {

        let pathname = window.location.pathname
        let target_array = pathname.split('/')
        let target_native_id = target_array[1]

        let val_obj
        if (target_native_id === 'user-attempted-shlok') {

            val_obj = {
                url: `ApprovedShloks/GetAttemptedShloksByRegistrationCodeChapterNO?RegNo=${this.state.user.data.uid}&ChapterNo=${chapterNum}`,
                data: '',
                request_type: 'GET'
            }
        } else {

            val_obj = {
                url: `ApprovedShloks/GetNonAttemptedShloksByRegistrationCodeChapterNO?RegNo=${this.state.user.data.uid}&ChapterNo=${chapterNum}`,
                data: '',
                request_type: 'GET'
            }
        }

        console.log('Approved Slock ', val_obj)
        let response = await requestServerData(val_obj, 'user')
        console.log('Approved Slock response ', response)

        if (response.response) {

            if(target_native_id === 'user-attempted-shlok'){

                this.setState({
                    [chapterNum]: response.data.data.result.AttemptedShaloks, selectedChapterNumber: chapterNum, showSlock: true
                })
            }else{

                this.setState({
                    [chapterNum]: response.data.data.result.NonAttemptedShaloks, selectedChapterNumber: chapterNum, showSlock: true
                })
            }
        } else {

            toast.error('server error !')
        }

    }
}

export default ShlokList;