import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getFromSession, removeSessionStorage } from "../Utils/SessionStorage";
import { userRoleVisibility } from "../Routes/PrivateRoute";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    let user = getFromSession("user");
    let admin = getFromSession("admin");
    this.setState({
      userData: user,
      adminData: admin
    });
  }

  render() {
    return (
      <div class="sidenav">
        {this.props.activeFor === "user" ? (
          <div style={{ marginTop: 80 }}>
            {this.state.userData &&
              this.state.userData.data &&
              this.state.userData.data.type === "user" &&
              this.renderSidebarMenuUser(userSidebarMenu)}
          </div>
        ) : (
          <div style={{ marginTop: 80 }}>
            {this.state.adminData &&
              this.state.adminData.response &&
              this.state.adminData.data.type === "admin" &&
              this.renderSidebarMenuAdmin(adminSidebarMenu)}
          </div>
        )}
      </div>
    );
  }

  renderSidebarMenuAdmin = data => {
    let { admin = {} } = this.props;
    return (
      (data &&
        data.length &&
        data.reduce((total, item) => {
          let { visible, id, href, title } = item;
          let isVisible = false;
          if (typeof visible === "function") {
            isVisible = visible({
              admin,
              path: (href && href.substring(1, href.length)) || ""
            });
          } else if (item.hasOwnProperty("visible")) {
            isVisible = visible;
          } else {
            isVisible = true;
          }
          if (isVisible) {
            total.push(
              <React.Fragment key={id}>
                <a
                  style={{ color: "#fff" }}
                  onClick={() => this.handleClick(id, href)}
                >
                  {title}
                </a>
              </React.Fragment>
            );
          }
          return total;
        }, [])) ||
      []
    );
  };

  renderSidebarMenuUser = data => {
    let { user = {} } = this.props;
    return (
      (data &&
        data.length &&
        data.reduce((total, item) => {
          let { visible, id, href, title } = item;
          let isVisible = false;
          if (typeof visible === "function") {
            isVisible = visible({
              user,
              path: (href && href.substring(1, href.length)) || ""
            });
          } else if (item.hasOwnProperty("visible")) {
            isVisible = visible;
          } else {
            isVisible = true;
          }
          if (isVisible) {
            total.push(
              <React.Fragment key={id}>
                <a
                  style={{ color: "#fff" }}
                  onClick={() => this.handleClick(id, href)}
                >
                  {title}
                </a>
              </React.Fragment>
            );
          }
          return total;
        }, [])) ||
      []
    );
  };

  handleClick = (id, url) => {
    if (id === "logout") {
      removeSessionStorage("admin");
    }

    this.props.history.push(url);
  };
}

export default withRouter(Sidebar);

const adminSidebarMenu = [
  {
    href: "/dashbord",
    title: "Dashboard",
    id: "dashboard",
    visible: userRoleVisibility
  },
  {
    href: "/contestent-list",
    title: "Contestent List",
    id: "contestentList",
    visible: userRoleVisibility
  },
  {
    href: "/edit-contestent",
    title: "Edit Contestent ",
    id: "EditContestent ",
    visible: userRoleVisibility
  },
  {
    href: "/contestent-payout",
    title: "Payment Request",
    id: "viewPayout",
    visible: userRoleVisibility
  },
  // {
  //   href: "/contestent-update-payout",
  //   title: "Update Payout",
  //   id: "update",
  //   visible: userRoleVisibility
  // },
  {
    href: "/pending-payout",
    title: " Pending Payout",
    id: "pendingPayout",
    visible: userRoleVisibility
  },
  {
    href: "/view-approved-slok",
    title: "View Approved Shloks",
    id: "viewApproved",
    visible: userRoleVisibility
  },
  {
    href: "/new-approved-shlok",
    title: "New Approved Shlok",
    id: "newViewApproved",
    visible: userRoleVisibility
  },
  // { href: '/donate', title: 'Donate', id: 'donate' },
  {
    href: "/donation",
    title: "Donation",
    id: "donation",
    visible: userRoleVisibility
  },
  {
    href: "/contactus-entry",
    title: "Feedback Result",
    id: "contactus-entry",
    visible: userRoleVisibility
  },
  // { href: '/add-user-type', title: 'Add User Type ', id: 'add-user-type' },
  {
    href: "/add-user-type-form",
    title: "Add User Type ",
    id: "add-user-type-form",
    visible: userRoleVisibility
  },
  {
    href: "/add-user",
    title: "Add User ",
    id: "add-user",
    visible: userRoleVisibility
  },
  {
    href: "/wallet",
    title: "Wallet ",
    id: "wallet",
    visible: userRoleVisibility
  },
  {
     href: "/admin-change-password", 
     title: "Change Password",
     id: "admin-change-password",
     visible: userRoleVisibility

 },
  {
     href: "/config",
     title: "Configuration  ",
     id: "config",
     visible: userRoleVisibility
   
 },
 {
  href: "/bulk-upload",
  title: "Bulk Payment Upload  ",
  id: "bulk-upload",
  visible: userRoleVisibility

},
  
  { href: "", title: "Logout", id: "logout" }
];
const userSidebarMenu = [
  {
    href: "/user-dashboard",
    title: "Dashboard",
    id: "dashboard"
  },
  { href: "/user-profile", title: "Profile", id: "profile" },
  {
    href: "/user-attempted-shlok",
    title: "Approved Shlok",
    id: "Attempted_shlok"
  },
  {
    href: "/user-non-attempted-shlok",
    title: "Remaining Shlok",
    id: "non_attempted_shlok"
  },
  {
    href: "/user-payment",
    title: "Payment",
    id: "payment"
  },
  { href: "/user-wallet", title: "Wallet", id: "wallet" },
  // { href: '/user-request', title: 'Request', id: 'requet'},
  { href: "/user-rewards", title: "Rewards", id: "reward" },
  { href: "/user-change-password", title: "Change Password", id: "password" },
  { href: "/user-kyc-update", title: "KYC ", id: "kycupdate" },
  { href: "/user-feedback", title: "Feedback ", id: "/userfeedback" },




  // { href: '/user-donate', title: 'Donate', id: 'donate' },
];
