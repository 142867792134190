import React from "react";

function SvgYoutubeComponent(props) {
  return (
    <svg viewBox="0 0 48 48" width={48} height={48} {...props}>
    <path
      fill="#f44336"
      d="M42 37a5 5 0 01-5 5H11a5 5 0 01-5-5V11a5 5 0 015-5h26a5 5 0 015 5v26z"
    />
       <path
        fill="#fff"
        d="M36.499 25.498c-.276-.983-1.089-1.758-2.122-2.021C32.506 23 24 23 24 23s-8.506 0-10.377.478c-1.032.263-1.846 1.037-2.122 2.021C11 27.281 11 31 11 31s0 3.719.501 5.502c.276.983 1.089 1.758 2.122 2.021C15.494 39 24 39 24 39s8.505 0 10.377-.478c1.032-.263 1.846-1.037 2.122-2.021C37 34.719 37 31 37 31s0-3.719-.501-5.502z"
      />
      <path
        fill="#f44336"
        d="M16.333 37h-1.666V26.655H13V25h5v1.655h-1.667zM23 37h-1.5l-.167-1.132c-.658.711-1.441 1.132-2.05 1.132-.533 0-.908-.231-1.092-.653-.108-.264-.191-.66-.191-1.255V27.5h1.667v7.757c.042.24.217.33.433.33.333 0 .867-.363 1.233-.843V27.5H23V37zm12-4.337v-2.701c0-.777-.192-1.338-.533-1.702-.458-.496-1.117-.76-1.942-.76-.842 0-1.492.264-1.967.76-.358.363-.558.958-.558 1.735v4.593c0 .768.225 1.313.575 1.669.475.495 1.125.743 1.992.743.858 0 1.533-.256 1.983-.785.2-.231.333-.496.392-.785.008-.132.058-.487.058-.908h-1.667v.661c0 .38-.375.694-.833.694s-.833-.314-.833-.694v-2.52H35zm-3.333-3.271c0-.388.375-.694.833-.694s.833.306.833.694v2.123h-1.667v-2.123zm-2.884-.9c-.208-.646-.717-1.001-1.35-1.01-.808-.008-1.142.414-1.767 1.142V25H24v12h1.5l.167-1.034c.525.645 1.208 1.034 1.766 1.034.633 0 1.175-.331 1.383-.977.1-.348.175-.67.183-1.399V30.28c.001-.819-.107-1.44-.216-1.788zm-1.45 5.918c0 .869-.2 1.167-.65 1.167-.258 0-.75-.174-1.017-.439v-5.686c.267-.265.758-.521 1.017-.521.45 0 .65.273.65 1.142v4.337z"
      />
      <path
        fill="#fff"
        d="M15 9l1.835.001 1.187 5.712h.115l1.128-5.711 1.856-.001L19 16.893V21h-1.823l-.003-3.885L15 9zm6.139 5.082c0-.672.219-1.209.657-1.606.437-.399 1.024-.6 1.764-.601.675 0 1.225.209 1.655.63.429.418.645.96.645 1.622l.003 4.485c0 .742-.209 1.326-.63 1.752-.421.424-.999.636-1.74.636-.714 0-1.281-.221-1.712-.656-.428-.435-.64-1.023-.641-1.76l-.003-4.503.002.001zm1.676 4.664c0 .236.057.423.178.553.115.128.279.193.495.193.221 0 .394-.066.524-.201.129-.129.196-.314.196-.547l-.003-4.731a.588.588 0 00-.201-.459.756.756 0 00-.519-.175.687.687 0 00-.486.176.604.604 0 00-.186.459l.002 4.732zM32 12v9h-1.425l-.227-1.1c-.305.358-.622.63-.953.815-.328.186-.648.285-.958.285-.384 0-.671-.132-.866-.394-.195-.259-.291-.65-.291-1.174L27.276 12h1.653l.004 6.825c0 .204.036.355.106.449.066.09.183.14.335.14.124 0 .278-.062.46-.186a2.22 2.22 0 00.512-.471L30.344 12H32z"
      />
    </svg>
  )
}

export default SvgYoutubeComponent