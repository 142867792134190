import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
// import SideBar from './SideBar';
import { Card, Button, Input, Spinner, Label, Modal, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { ToastContainer, toast } from 'react-toastify';
import { AllowOnlyNumber } from '../Validation/Validation'
import NativeDb from '../Utils/nativeDb.json'

class Donationform extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }



    render() {

        return (
            <form><script src="https://cdn.razorpay.com/static/widget/payment-button.js" data-payment_button_id="pl_FKllRILE5G8ZN3" data-button_text="Donate Now" data-button_theme="rzp-dark-standard"> </script> </form>

           
        )
    }
}
   
export default withRouter(Donationform);