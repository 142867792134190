import React, { Component } from "react";
import { Card, Button, Input, Spinner, Label, Row, Col, Modal, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Terms from '../Common/Terms'
import { ToastContainer, toast } from 'react-toastify';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'
import { getFromSession } from '../Utils/SessionStorage'
import { setGlobalCssModule } from "reactstrap/lib/utils";
class ContactusReply extends Component {
    constructor(props) {
        super(props);
        this.state = {             
           Email :props.Email,
           ContactNumber: props.ContactNumber ,
           ContactId: props.ContactId         
         
           
        };
    
    }
    componentDidMount() {         
       
    }

    handleChange = (event) => {

       
        if(event.target.name === 'mobile'){
 
             if(AllowOnlyNumber(event.target.value)){
                 
                 this.setState({
                     [event.target.name]: event.target.value
                 })
             }else{
 
                 toast.error('only number is allow')
             }
 
         } 
         else {
 
             this.setState({
                 [event.target.name]: event.target.value
             })
         }
     }

  
 

handleSubmit = async (Type) => {
    this.setState({
        loading: true

     })
     const { ContactId, feedbackResponce,ContactNumber,Email } = this.state;
     if (feedbackResponce) {
        let error = undefined
        if (error) {
                     toast.error(error)
                    } 

                    else {

                     let data = {
                            "Email":Email,
                            "ContactNumber":ContactNumber,
                            "ContactId":ContactId,
                            "FeedbackResponce": feedbackResponce || '',
                            "Type":Type
                             }
                             let val_obj = {

                                data: data,
                                request_type: 'POST',
                                url: 'ContactUs/UpdateContactUs',
            
                            }
                            // console.log('val_obj', val_obj)
                            let response = await requestServerData(val_obj, 'admin')
                            // console.log('update kyc response', response)
                            
                            if (response.response) {   
                              
                                alert('Responce updated sucessfully')
                                this.setState({
                                
                                  feedbackResponce:'',
                                });

                                this.props.history.push('/contactus-entry')
                               window.location.reload(false);
                            }





                   else {
                            toast.error('Error')
                        }
                    }
                            } else {
                    
                                toast.error('Please fill all field')
                            }

                            this.setState({
                                loading: false
                            })
                        }
                   
                
                





 render()
{
    return(
        
        <Card style={{ padding: 30 }}>
        <div>
            <ToastContainer
                position="top-center"
            />
            <h3 > Contact Us Response</h3>
       
   
            <FormGroup>
                <Label >Email</Label>
                <Input type="text" name="Email" id="Email" placeholder="Enter Email Address *"  value={this.state.Email} onChange={(e) => this.handleChange(e)} />
            </FormGroup>
            <FormGroup>
                        <Label >Mobile Number</Label>
                        <Input type="text" name="ContactNumber" id="ContactNumber" placeholder="Your mobile Number *" value={this.state.ContactNumber} onChange={(e) => this.handleChange(e)} />
            </FormGroup>
            <FormGroup>
                        <Label >Message</Label>
                        <Input type="textarea" name="feedbackResponce" id="feedbackResponce" placeholder="Your message *" rows="5" cols="50" value={this.state.feedback} onChange={(e) => this.handleChange(e)} /> 
            </FormGroup>
            
        <div>
            
            <Row className="align-items-center">
            <Col>
                <Button color={'primary'} style={{width:'100%', display: "grid" , justifyContent: "center",textAlign:'center'}} onClick={() => this.handleSubmit(1)}  >Send Message </Button>
            </Col>
            <Col>
                <Button color={'primary'} style={{width:'100%',justifyContent:'center', display: "grid"}}  onClick={() => this.handleSubmit(2)}>  Send email </Button>
            </Col>
           <Col>
            <Button color={'primary'} style={{width:'100%',justifyContent:'center', display: "grid"}}   onClick={() => this.handleSubmit(3)}  >Send Both </Button>
            </Col>
            </Row>
        </div>
        </div>
    </Card >

    )
}
}

export default withRouter(ContactusReply);