import React, { Component } from "react";
import { Card, Button, Input, Spinner, Label, Modal, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Terms from '../Common/Terms'
import { ToastContainer, toast } from 'react-toastify';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'
import { getFromSession } from '../Utils/SessionStorage'
import { setGlobalCssModule } from "reactstrap/lib/utils";
class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            verifier: Math.floor(Math.random() * 10000),
            Name: '',
           
            ContactNumber: '',
            Email:'',
            Feedback:'',
            pageLoading: false
           
        };
    // window.location.reload(false);


    }
    componentDidMount(){
       
       

    }   

    handleSubmit = async () => {
        this.setState({ pageLoading: true });

        const { name, mobile, feedback, email , verify ,verifier} = this.state;
        if (name && mobile && feedback && email && verify){
            let error = ''
            if (verify.toString() !== verifier.toString()) {
                error = 'you have to verify first !';
                return false;
            }
            this.setState({
                loading: true
            })

            if (error) {

                toast.error(error)
                this.setState({
                    loading: false
                })
            } 

      
        let val_obj = {
            data: {
                "Name": name,
                // "RegistrationCode":registrationcode,
                "ContactNumber": mobile,
                "Email": email,
                "Feedback":feedback              
            },
            request_type: "POST",
            url: 'ContactUs/InsertContactUs'
        }


        let response = await requestServerData(val_obj, 'user');

         if (response.response) {   
                              
           toast.success('Thanks for your Feedback')
           this.setState({
            verifier: Math.floor(Math.random() * 10000),
             name:'',
            //  registrationcode:'',
             mobile:'',
             feedback:'',
             verify:'',
             email:''
           });
          this.props.history.push('/contactus')
           
        } else {

            toast.error('Please try again')
        }
        this.setState({
            loading: false
        })
    }else{
        toast.error('Please fill all fields.')

    }
    this.setState({
        pageLoading: false
      });

}




    handleChange = (event) => {

       if(event.target.name === 'mobile'){

            if(AllowOnlyNumber(event.target.value)){
                
                this.setState({
                    [event.target.name]: event.target.value
                })
            }else{

                toast.error('only number is allow')
            }

        } 
        else {

            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }


render()
{
    return(
        
        <Card style={{ padding: 30 }}>
        <div>
            <ToastContainer
                position="top-center"
            />
            <h3 > Contact Us</h3>
       
            <FormGroup>
                <Label >Name</Label>
                <Input type="name" name="name" id="name" placeholder="Your Full Name *"  value={this.state.name} onChange={(e) => this.handleChange(e)} />
            </FormGroup>
            {/* <FormGroup>
                <Label >Registration Code</Label>
                <Input type="text" name="registrationcode" id="registrationcode" placeholder="Enter Registration Code *"  value={this.state.registrationcode} onChange={(e) => this.handleChange(e)} />
            </FormGroup> */}
            <FormGroup>
                        <Label >Mobile Number</Label>
                        <Input type="text" name="mobile" id="mobile" placeholder="Your mobile Number *" value={this.state.mobile} onChange={(e) => this.handleChange(e)} />
            </FormGroup>
            <FormGroup>
                        <Label >Feedback</Label>
                        <Input type="textarea" name="feedback" id="feedback" placeholder="Your Feedback *" rows="4" cols="50" value={this.state.feedback} onChange={(e) => this.handleChange(e)} /> 
            </FormGroup>
            <FormGroup>
                        <Label >Email</Label>
                        <Input type="text" name="email" id="email" placeholder="Your Email *" value={this.state.email} onChange={(e) => this.handleChange(e)} /> 
            </FormGroup>
            <FormGroup>
                        <Label >Verify Your Self</Label>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <p className={'verify'}>{this.state.verifier}</p>
                        </div>
                        <Input type="text" name="verify" id="verify" placeholder="enter the verification number" value={this.state.verify} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>
            </div>
            <Button color={'primary'} style={{ marginTop: 15, width: '100%' }}   onClick={() => this.handleSubmit() }   >Submit </Button>

            </Card >

    )
}
}

export default withRouter(ContactUs);