import React from "react";

const UerRequest = props => (
  <svg height="512pt" width="512pt" {...props}>
      <path d="M367.398 231.898c0 8.286-6.714 15-15 15-8.28 0-15-6.714-15-15 0-8.28 6.72-15 15-15 8.286 0 15 6.72 15 15zm0 0M352.383 72.3c-30.45 0-55.149 24.641-55.149 55.15v.019c0 8.281 6.715 14.992 15 14.992s15-6.727 15-15.012c0-13.867 11.215-25.148 25.168-25.148 13.875 0 25.164 11.289 25.164 25.168 0 13.875-11.289 25.164-25.164 25.164-8.285 0-15 6.719-15 15v16.066c0 8.285 6.715 15 15 15 8.282 0 15-6.715 15-15v-3.137c23.153-6.55 40.164-27.875 40.164-53.093 0-30.422-24.746-55.168-55.183-55.168zm0 0M143.938 382.813c18.64-14.516 30.664-37.149 30.664-62.547 0-43.707-35.563-79.266-79.27-79.266s-79.266 35.559-79.266 79.266c0 25.398 12.024 48.03 30.66 62.546C20.423 398.462 0 428.017 0 464.868V497c0 8.285 6.715 15 15 15h160.668c8.281 0 15-6.715 15-15v-32.133c0-36.844-20.418-66.402-46.73-82.055zm-97.872-62.547c0-27.164 22.102-49.266 49.27-49.266 27.164 0 49.266 22.102 49.266 49.266 0 27.168-22.102 49.27-49.266 49.27-27.168 0-49.27-22.102-49.27-49.27zM160.668 482H30v-17.133c0-36.086 29.195-65.332 65.332-65.332 36.086 0 65.332 29.195 65.332 65.332V482zm0 0" />
      <path d="M448.8 0H256c-34.906 0-63.2 28.242-63.2 63.2v289.198c0 12.27 14.071 19.446 24 12l60.266-45.199h171.735C483.707 319.2 512 290.957 512 256V63.2C512 28.292 483.758 0 448.8 0zM482 256c0 18.352-14.84 33.2-33.2 33.2H272.067a15.015 15.015 0 00-9 3l-40.265 30.198V63.2C222.8 44.848 237.64 30 256 30h192.8c18.352 0 33.2 14.84 33.2 33.2zm0 0" />
    </svg>
);

UerRequest.defaultProps = {
    width: 48,
    height: 48,
    viewBox: "0 0 512 512"
};

export default UerRequest;
