import React from "react";
import { Progress, Jumbotron, Button } from "reactstrap";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  Spinner
} from "reactstrap";
import NativeDb from "../Utils/nativeDb.json";
import { MDBDataTable, MDBInput } from "mdbreact";
import { requestServerData } from "../Utils/Config";
import { formatNumber } from "../Utils/Methods";
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

class DashboardMini extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      Dashboarddata: [],
      ContestentCount: "",
      ApprovedShlokCount: "",
      PaidAmount: "",
      UnPaidAmount: "",
      WalletAmount: "",
      TodayApprovedShlokCount: "",
      YesterdayApprovedShlokCount: "",
      statewisedata: [],
      statedata: []
    };
  }

  componentDidMount() {
    this.GetAdminDashboardData();
  }

  GetAdminDashboardData = async () => {
    this.setState({ loading: true });
    let val_obj = {
      url: `Dashboards/GetAdminDashboardData`,
      data: "",
      request_type: "get"
    };

    let response = await requestServerData(val_obj, "admin");
    if (response.response) {
      var getdata = response.data.data.result;
      let obj = {
        ContestentCount: getdata[0].ContestentCount,
        ApprovedShlokCount: getdata[0].ApprovedShlokCount,
        PaidAmount: getdata[0].PaidAmount,
        UnPaidAmount: getdata[0].UnPaidAmount,
        WalletAmount: getdata[0].WalletAmount,
        TodayApprovedShlokCount: getdata[0].TodayApprovedShlokCount,
        YesterdayApprovedShlokCount: getdata[0].YesterdayApprovedShlokCount
      }

      let Analytics = [
        {
          count: getdata[0].ContestentCount || 0,
          title: "Contestent",
          color: "#007bff"
        },
        {
          count: getdata[0].ApprovedShlokCount || 0,
          title: "Approved",
          color: "#6610f2"
        },
        {
          count: getdata[0].PaidAmount || 0,
          title: "Paid",
          currency: true,
          color: "#6f42c1"
        },
        {
          count: getdata[0].UnPaidAmount || 0,
          title: "UnPaid",
          currency: true,
          color: "#28a745"
        },
        {
          count: getdata[0].WalletAmount || 0,
          title: "Wallet",
          currency: true,
          color: "#17a2b8"
        },
        {
          count: getdata[0].TodayApprovedShlokCount || 0,
          title: "Today Approved Shlok",
          color: "#e83e8c"
        },
        {
          count: getdata[0].YesterdayApprovedShlokCount || 0,
          title: "Yesterday Approved Shlok",
          color: "#e10b0b"
        }
      ];
      this.setState({ ...obj, Dashboarddata: Analytics, loading: false });
    } else {
      alert("Try Again after some time . . .");
    }
  };

  render() {
    let { Dashboarddata = [], loading } = this.state;
    return (
      <React.Fragment>
        <div
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#f3f3f3",
            borderRadius: 10,
            padding: 10,
            marginBottom: 10,
            flex: 1
          }}
        >
          <div
            style={{
              marginTop: 30,
              marginLeft: 20,
              marginBottom: 30
            }}
          >
            <h6>
              <b>Analytics</b>
            </h6>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              flexWrap: "wrap"
            }}
          >
            {loading && (
              <Spinner style={{ width: "3rem", height: "3rem", padding: 30 }} />
            )}
            {Dashboarddata &&
              Dashboarddata.map(({ currency, title, count, color }) => {
                return (
                  <div
                    title={count}
                    style={{
                      cursor: "pointer",
                      borderWidth: 0,
                      borderLeftWidth: 10,
                      borderStyle: "solid",
                      borderColor: color,
                      minWidth: 190,
                      minHeight: 120,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 10,
                      margin: 10,
                      boxShadow:
                        "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)"
                    }}
                  >
                    <h1>
                      <b>{((currency && "₹") || "") + formatNumber(count)}</b>
                    </h1>
                    <p>{title}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardMini;
