import React, { Component } from "react";
import { Card, Button, Input, Spinner, Label, Modal, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Terms from '../Common/Terms'
import { ToastContainer, toast } from 'react-toastify';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'
import { getFromSession } from '../Utils/SessionStorage'
import { setGlobalCssModule } from "reactstrap/lib/utils";
class Config extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            // verifier: Math.floor(Math.random() * 10000),
            MinimumPayoutAmount: '',
            PerShlokAmount:'',
            MaximumShlokPerDay: '',
          
            admin: getFromSession('admin'),
           
        };
    // window.location.reload(false);


    }
    componentDidMount() {

        this.GetConfigurationData()
    }


    

    GetConfigurationData = async () => {

        this.setState({
            loading: true
        })

        let val_obj = {
            url: `ApprovedShloks/Configuration`,
            data: '',
            request_type: 'GET'
        }

        console.log('Configuration', val_obj)

        let response = await requestServerData(val_obj, 'admin')
        // console.log('Configuration', response.data.data.result)
        if (response.response) {

            let data = response.data.data.result && response.data.data.result[0] || undefined

            if (data) {

                this.setState({
                    amount: data.MinimumPayoutAmount, pamount: data.PerShlokAmount, shlokperday: data.MaximumShlokPerDay
                })
            }
        } else {

            alert('try again . . .')
        }
        this.setState({
            loading: false
        })
    }


    handleSubmit = async () => {

        this.setState({
            loading: true
        })
        const { amount, pamount, shlokperday } = this.state;

        if (amount && shlokperday) {

            let error = undefined

            if (error) {

                toast.error(error)
            } else {

                let data = {
                    "MinimumPayoutAmount": amount,
                    "PerShlokAmount": 10 || '',
                    "MaximumShlokPerDay": shlokperday || ''
                 
                }

                let val_obj = {

                    data: data,
                    request_type: 'POST',
                    url: 'ApprovedShloks/Configuration'
                }
                // console.log('val_obj', val_obj)
                let response = await requestServerData(val_obj, 'admin')
                // console.log('configuration', response)

                if (response.response) {

                    this.GetConfigurationData()
                    alert('Configuration Setting Updated Successfully');
                    // toast.success('Configuration Setting Updated Successfully');
                } else {
                    toast.error('Error')
                }
            }
        } else {

            toast.error('Please fill all field')
        }
        this.setState({
            loading: false
        })
    }





    handleChange = (event) => {

        if(event.target.name === 'amount'){
 
             if(AllowOnlyNumber(event.target.value)){
                 
                 this.setState({
                     [event.target.name]: event.target.value
                 })
             }else{
 
                 toast.error('only number is allow')
             }
             
             
 
         } 
         if(event.target.name === 'pamount'){
 
            if(AllowOnlyNumber(event.target.value)){
                
                this.setState({
                    [event.target.name]: event.target.value
                })
            }else{

                toast.error('only number is allow')
            }
        }
        if(event.target.name === 'shlokperday'){
 
            if(AllowOnlyNumber(event.target.value)){
                
                this.setState({
                    [event.target.name]: event.target.value
                })
            }else{

                toast.error('only number is allow')
            }
        }
         else {
 
             this.setState({
                 [event.target.name]: event.target.value
             })
         }
     }






     




    
    render()
    {
    return(
        <div className={"top-main"} style={{ height: window.innerHeight }}>
        <div style={{ paddingTop: "50px", margin: "auto", maxWidth: "500px" }}>
        <Card style={{ padding: 30 }}>
        <div>
            <ToastContainer
                position="top-center"
            />
            <h3 > Configure Changes</h3>
       
            <FormGroup>
                <Label >Minimum Payment Amount</Label>
                <Input type="text" name="amount" id="amount" placeholder="Enter Minimum Amount*"  value={this.state.amount} onChange={(e) => this.handleChange(e)} />
            </FormGroup>
            {/* <FormGroup>
                <Label > Per Shlok Amount</Label>
                <Input type="text" name="pamount" id="pamount" placeholder="Enter Per Shlok Amount *"  value={this.state.pamount} onChange={(e) => this.handleChange(e)} />
            </FormGroup> */}
            <FormGroup>
                        <Label >Maximum Shlok Per Day </Label>
                        <Input type="text" name="shlokperday" id="shlokperday" placeholder="Shlok Per Day   *" value={this.state.shlokperday} onChange={(e) => this.handleChange(e)} />
            </FormGroup>
            {/* <FormGroup>
                        <Label >Address</Label>
                        <Input type="textarea" name="address" id="address" placeholder="Enter Address *" rows="4" cols="50" value={this.state.address} onChange={(e) => this.handleChange(e)} /> 
            </FormGroup> */}
         
            </div>
            <Button color={'primary'} style={{ marginTop: 15, width: '95%' }}  onClick={() => this.handleSubmit()}  >Submit </Button>

            </Card >
            </div>
            </div>

    )
}
}

export default withRouter(Config);