import React, { Component } from "react";
import { Card, Button,CardTitle, Input, Spinner, Label, Modal, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Terms from '../Common/Terms'
import { ToastContainer, toast } from 'react-toastify';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'
import { getFromSession } from '../Utils/SessionStorage'
import { setGlobalCssModule } from "reactstrap/lib/utils";
const date = new Date();
class EditContestent extends Component {

    constructor(props) {
        super(props);
        this.state = {            
            selectedAge: '',
            userName: null,           
            flag:false,
            // contestentID: props.contestentID || '',
            pathname: window.location.pathname,
            target_native_id: '',
            male:false,
            female:false,
            Activate:false,
            DeActivate:false,                      
            today : date.getFullYear() + '-' + '0' +(date.getMonth() + 1) + '-' + date.getDate()
        };

    }

    handleOnSubmit = async () => {
    
        this.setState({ pageLoading: true });
        let userName = this.state.userName;
        // let mobile = null;
        let user_detial_obj = {
          url: `Contestent/GetContestentByRegNo?RegNum=${this.state.regcode}`,
          data: "",
          request_type: "GET"
        };
        
let user_detail_response = await requestServerData( user_detial_obj,"admin" );
     
         console.log("myresponse",user_detail_response)
        if (user_detail_response.response) {
            let data = user_detail_response.data.data.result && user_detail_response.data.data.result || undefined
          
            if (data) {
                   this.setState({
                    RegistrationCode:data.RegistrationCode,
                    name: data.ContestantName,
                     mobile: data.MobileNo,
                      dob: this.formatDate(data.Dob),
                      selectedAge: data.Age, 
                      email: data.Email,
                       gender: data.Gender,
                     nameUpiId: data.NameInUpi,
                     upiID: data.UpiId,
                     Status: data.IsActive 
                })
            }
           
          userName = user_detail_response.data.data.result.ContestantName;
        this.setState({flag:true})
               
        }
         else {
          toast.error("Network error");
          return void 0;
        }
        
        this.setState({
            loading: false
        })
    }


    handleChange = (event) => {
        
        if (event.target.name === 'gender') {

            this.setState({
                [event.target.name]: event.target.id
            })
        }
        else if (event.target.name === 'Status') {
              
                let stt=!this.state.Status
             
            this.setState({
                [event.target.name]: event.target.id,
                Status:stt
            })
        }
        else if (event.target.name === 'dob') {   
           
            var today = new Date();
            let selectedYearArray = event.target.value.split('-')
            let selectedYear = selectedYearArray[0]
            let age = today.getFullYear() - JSON.parse(selectedYear)
            this.setState({
                selectedAge: age, [event.target.name]: event.target.value
            })
        } else if(event.target.name === 'mobile'){

            if(AllowOnlyNumber(event.target.value)){
                
                this.setState({
                    [event.target.name]: event.target.value
                })
            }else{

                toast.error('only number is allow')
            }
        } else {

            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }
      


    formatDate = date => {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
      };




    handleRegister = async () =>
     {

        const { RegistrationCode, email, name, mobile,nameUpiId, upiID,selectedAge, gender, dob, Status} = this.state;
      
        if (name && mobile  && nameUpiId && upiID && selectedAge  )
         {
            
                    let error = ''
                    error = emailVerifier(email)
                    error = upiIdCheck(upiID)   
                    this.setState({
                        loading: true
                    })
            if (error) {
                toast.error(error)
                this.setState({
                    loading: false
                })
                } else {
                    
                let data_url = ''               
                let requestType = ''
                let createdby = ''
                data_url = 'Contestent/UpdateContestent'
                createdby = 1
                requestType = 'POST'  
              
                let val_obj = {

                    data: {
                        "RegistrationCode":RegistrationCode,
                        "ContestantName": name,
                        "MobileNo": mobile,
                        "Age": selectedAge,
                        "UpiId": upiID,
                        "NameInUpi": nameUpiId,                                             
                        "Dob": dob,
                        "Email":email,
                        "Gender": gender, 
                        "IsActive":Status,
                        "CreatedBy": createdby,                        
                    },
                   
                    request_type: requestType,
                    url: data_url
                }            
                              
let response=''
                        response = await requestServerData(val_obj, 'admin')  
                       

                        console.log("dfds",val_obj)
                       if(response.response)
                       {
                       
                        this.setState({

                          RegistrationCode:'',
                           name:'',
                           mobile:'',
                           selectedAge:'',
                           upiID:'',
                           nameUpiId:'', 
                          email:''

                         
                           });   


                        toast.success('Record Updated successfully!');


                       }
                       
                        
                    }  
                    this.setState({
                        loading: false
                    })  
                       
         
        } 
        

        
    }
    render(){
        return(
            <div className={"top-main"} style={{ height: window.innerHeight }}>

                <div>
                <Card>
                <div align="center">
                            <h3>Edit Contestent </h3>
                            </div>
                            <div
                            // style={{
                            //     display: "flex",
                            //     justifyContent: "flex-start",
                            //     alignItems: "center",
                            //     flexDirection: "row",
                            //     borderColor: "black",
                            //     backgroundColor: "black",
                            //     color: "white",
                            //     borderRadius: "5px 20px 5px",
                            //     fontweight: "bold",
                            //     padding: "15px 20px",
                            //     width: "100%",
                            //     flexWrap: "wrap"
                            // }}
                            >
                            <h5>Registration Code : </h5>
                            <from
                                // style={{
                               
                                // justifyContent: "flex-start",
                                // alignItems: "center",
                                // flexDirection: "row",
                                // flexWrap: "wrap"
                                // }}
                            >
                                <input
                                type="text"
                                name="regcode"
                                onChange={e => this.handleChange(e)}
                                // onKeyDown={e => this.handleEnterSubmit(e)}
                                id="regcode"
                                />
                                <button onClick={e => this.handleOnSubmit()}  type="submit">
                                Submit
                                </button>
                            </from>
                            </div>

                          
 {
 
 this.state.flag==true && 

   
    <div  >
            <Card style={{padding:"20px"}} >        

            <div>
                <ToastContainer
                    position="top-center"
                />
               
                <FormGroup>
                    <Label >Name</Label>
                    <Input type="name" name="name" id="name" placeholder="Your Full Name *" value={this.state.name} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
                <FormGroup>
                    <Label >Email</Label>
                    <Input type="email" name="email" id="email" placeholder="Your Email Id *" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                </FormGroup>

                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <FormGroup style={{ maxWidth: '50%' }}>
                        <Label >Date of birth</Label>
                        <Input type="date" name="dob" id="dob" value={this.state.dob} max={this.state.today} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>

                    <Label >Age : {this.state.selectedAge}</Label>

                </div>

                <FormGroup style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Label >Gender</Label>
                    <div>
                        <Input type="radio" name="gender" id="male" onChange={(e) => this.handleChange(e)} checked={this.state.male || this.state.gender == "male"} />
                        <Label >Male</Label>
                    </div>
                    <div>
                        <Input type="radio" name="gender" id="female" onChange={(e) => this.handleChange(e)} checked={this.state.female || this.state.gender == "female" } />
                        <Label >Female</Label>
                    </div>
                </FormGroup>


                <FormGroup>
                    <Label >Mobile Number</Label>
                    <Input type="mobile" name="mobile" id="mobile" placeholder="Your mobile Number *" value={this.state.mobile} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
                <FormGroup>
                    <Label >Upi ID</Label>
                    <Input type="upiID" name="upiID" id="upiID" placeholder="Your Upi ID *" value={this.state.upiID} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
                <FormGroup>
                    <Label >Name of Upi ID</Label>
                    <Input type="nameUpiId" name="nameUpiId" id="nameUpiId" placeholder="Your Upi ID Name *" value={this.state.nameUpiId} onChange={(e) => this.handleChange(e)} />
                </FormGroup>              
               
                <FormGroup style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Label >Status</Label>
                    <div>
                        <Input type="radio" name="Status" id="Activate" onChange={(e) => this.handleChange(e)} checked={this.state.Status} />
                        <Label >Active</Label>
                    </div>
                    <div>
                        <Input type="radio" name="Status" id="DeActivate" onChange={(e) => this.handleChange(e)} checked={!this.state.Status} />
                        <Label >DeActive</Label>
                    </div>
                </FormGroup>

                
                <FormGroup>
                <Button style={{ marginTop: 15, width: '100%' }} onClick={() => this.handleRegister()}>Update Contestent</Button>
                </FormGroup>
                
               
            </div>
           
           
            </Card >
        </div>
 } 
       </Card>
    </div>
</div>
        
    
        )

        
    }

}
export default withRouter(EditContestent);