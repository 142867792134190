import React, { Component } from "react";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  Spinner
} from "reactstrap";
import NativeDb from "../Utils/nativeDb.json";
import { requestServerData } from "../Utils/Config";
import { withRouter } from "react-router-dom";
import { MDBDataTable, MDBInput } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";

class ViewapprovedSlok extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contestentID: props.match.params.contestentID || "",
      userName: null,
      getRequestFor: "",
      selectedChapters: "",
      selectedSlock: "",
      slock: "",
      regcode: "",
      showchaptertitle: true,
      slokData: [],
      initial: false,
      pageLoading: false
    };
  }

  componentDidMount() {}

  componentDidUpdate(preProps, preState) {
    if (preState.submitStatus !== this.state.submitStatus) {
      this.handleClickShowSlocksList(this.state.getRequestFor);
    }
  }

  render() {
    return (
      <div className={"top-main"} style={{ height: window.innerHeight }}>
        <div>
          <Card>
            <div align="center">
              <h3>View Approved Shlok </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                borderColor: "black",
                backgroundColor: "black",
                color: "white",
                borderRadius: "5px 20px 5px",
                fontweight: "bold",
                padding: "15px 20px",
                width: "100%",
                flexWrap: "wrap"
              }}
            >
              <h5>Registration Code : </h5>
              <from
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap"
                }}
              >
                <input
                  type="text"
                  name="regcode"
                  onChange={e => this.handleChange(e)}
                  onKeyDown={e => this.handleEnterSubmit(e)}
                  id="regcode"
                />
                <button onClick={e => this.handleOnSubmit()} type="submit">
                  Submit
                </button>
              </from>
            </div>
            {this.state.userName && (
              <Card style={{ padding: 20, marginBottom: 20 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle>Name : {this.state.userName}</CardTitle>
                  <CardTitle>
                    Shlok Count : {this.state.TotalAttemptedShalok}/
                    {this.state.TotalShalokCount}
                  </CardTitle>
                </div>
              </Card>
            )}
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {
                //    this.state.selectedChapters &&
                //    <React.Fragment>
                //        {/* <div>
                //            <CardTitle>User Name : abc</CardTitle>
                //        </div> */}
                //        <div>
                //    <CardTitle>Slok Count : {this.state.TotalAttemptedShalok}/{this.state.TotalShalokCount}</CardTitle>
                //        </div>
                //    </React.Fragment>
              }
            </div>

            <div hidden={true} id="myDIV" style={{ padding: 10 }}>
              <CardTitle style={{ margin: 0 }}>Select Chapter</CardTitle>
            </div>

            <div style={{ padding: 10 }}>
              <Card style={{ backgroundColor: "white" }}>
                <ToastContainer position="top-center" />
                {this.state.pageLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Spinner style={{ width: "3rem", height: "3rem" }} />
                  </div>
                ) : (
                  <div>
                    <div
                      hidden={this.state.showchaptertitle}
                      style={{ padding: 10 }}
                    >
                      <CardTitle>Select Chapter</CardTitle>
                    </div>
                    <div style={{ padding: 10 }}>
                      <Form
                        style={{
                          flexDirection: "row",
                          flexWrap: "wrap",
                          display: "flex"
                        }}
                      >
                        {this.renderSlockChapter()}
                      </Form>
                    </div>
                  </div>
                )}

                <div style={{ padding: 10 }}>
                  {this.state.shlokData && (
                    <div className="table-responsive">
                      <MDBDataTable
                        id={"pendingPayouts"}
                        paging={true}
                        striped
                        bordered
                        hover
                        data={this.state.shlokData}
                      />
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  renderSlockChapter = () => {
 
    return (
      this.state.selectedChapters &&
      this.state.selectedChapters.map((chapter, index) => {
        return (
          <FormGroup
            style={{
              width: 300,
              padding: 5,
              backgroundColor: "black",
              borderRadius: "5px 20px 5px",
              overflow: "hidden"
            }}
            key={index}
            check
            inline
          >
            <Card
              style={{
                paddingLeft:5,
                paddingRight:5,
                paddingTop:15,
                paddingBottom:15,
                borderColor: "white",
                backgroundColor: "DarkSlateGrey",
                fontweight: "bold",
              }}
            >
              <Label check style={{ display: "flex", alignItems: "center",justifyContent:"center",flexDirection:"row"}}>
                <Input
                  type="radio"
                  name={"redio"}
                  onClick={e => {
                    this.handleClickChapter(e, chapter.ChapterNO);
                  }}
                />
                <div style={{ float: "left",marginLeft:10,marginRight:15 }}>
                  <CardTitle
                    style={{
                      padding: "20px 20px",
                      borderRadius: "5px 20px 5px",
                      backgroundColor: "white",
                      color: "black",
                      fontweight: "bold",
                      margin: 0,
                      padding: 5,
                      font: 30
                    }}
                  >
                    {"Chapter No : "}
                    {chapter.ChapterNO}
                  </CardTitle>
                </div>
                <div style={{ float: "left" }}>
                  <CardTitle
                    style={{
                      padding: "20px 20px",
                      borderRadius: "5px 20px 5px",
                      backgroundColor: "white",
                      color: "black",
                      fontweight: "bold",
                      margin: 0,
                      padding: 5,
                      font: 30
                    }}
                  >
                    {" Shlok Count : "}({chapter.ShalokCount})
                  </CardTitle>
                </div>
              </Label>
            </Card>
          </FormGroup>
        );
      })
    );
  };

  renderSlockListByChapter = () => {
    return this.state.selectedSlock.map((slock, index) => {
      return (
        <React.Fragment key={index}>
          <FormGroup
            style={{ width: "10%", padding: 5 }}
            key={index}
            check
            inline
          >
            <Card style={{ padding: "5px 20px", width: "100%" }}>
              <Label check style={{ display: "flex", alignItems: "center" }}>
                <div style={{ float: "left", marginLeft: 20 }}>
                  <CardTitle style={{ margin: 0 }}>
                    {"Slok No : "}
                    {slock.ShlokNo}
                  </CardTitle>
                </div>
              </Label>
            </Card>
          </FormGroup>
        </React.Fragment>
      );
    });
  };

  handleChangeDate = e => {
    this.setState({
      DateOfSubmissionOnTelegram: e.target.value
    });
  };

  handleClickChapter = async (e, chapter) => {
    if (e.target.checked) {
      this.setState({
        loading: true
      });
      let val_obj = {
        url: `ApprovedShloks/GetAttemptedShloksByRegistrationCodeChapterNo?RegNo=${this.state.regcode}&ChapterNo=${chapter}`,
        data: "",
        request_type: "GET"
      };
      console.log("Approved Slock ", val_obj);
      let response = await requestServerData(val_obj, "admin");
      console.log("response handleClickChapter", response);

      if (response.response) {
        const data = {
          columns: [
            { label: "Shlok No", field: "ShlokNo" },
            {
              label: "Date of Submission",
              field: "DateOfSubmissionOnTelegram"
            },
            { label: "Evaluated Date", field: "EvaluatedDate" },
            { label: "Rating", field: "Rating" }
          ],
          rows: this.assemblePosts(response.data.data.result.AttemptedShaloks)
        };

        this.setState({ shlokData: data, loading: false });
      } else {
        alert("try again . . .");
      }

      this.setState({
        loading: false,
        selectedSlock: response.data.data.result.AttemptedShaloks
      });
      // console.log("selectedSlock enter", this.state.selectedSlock )
    } else {
      this.setState({ loading: false, selectedSlock: [] });
    }
  };

  handleCloseSlokList = () => {
    this.setState({
      showSlock: false
    });
  };

  handleClickShowSlock = () => {
    this.setState({
      showSlock: true
    });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleOnSubmit = async () => {
    this.setState({ pageLoading: true });
    /*getting the name of filtered user*/
    let userName = null;
    let user_detial_obj = {
      url: `Contestent/GetContestentByRegNo?RegNum=${this.state.regcode}`,
      data: "",
      request_type: "GET"
    };
    let user_detial_response = await requestServerData(
      user_detial_obj,
      "admin"
    );
    if (user_detial_response.response) {
      userName = user_detial_response.data.data.result.ContestantName;
    } else {
      toast.error("Network error");
      return void 0;
    }

    let val_obj = {
      url:
        `ApprovedShloks/GetChapterWiseShloksCount?RegNo=` + this.state.regcode,
      data: "",
      request_type: "get"
    };
    let response = await requestServerData(val_obj, "admin");
    console.log("chapter list", response.data.data);

    if (response.response) {
      if (response.data.data.result.ChapterWiseShaloks.length > 0) {
        this.setState({
          userName,
          showchaptertitle: false,
          loading: false,
          selectedChapters: response.data.data.result.ChapterWiseShaloks,
          TotalShalokCount: response.data.data.result.TotalShalokCount,
          TotalAttemptedShalok: response.data.data.result.TotalAttemptedShalok
        });
        console.log("selectedChapters", this.state.selectedChapters);
      } else {
        toast.error("Invalid Registartion Number");
      }
    } else {
      toast.error("Try Again after some time . . .");
    }
    this.setState({
      pageLoading: false
    });
  };

  handleEnterSubmit = async event => {
    document.getElementById("myDIV").style.visibility = "hidden";
    if (event.keyCode === 13) {
      this.handleOnSubmit();
    }
  };

  assemblePosts = Shlok => {
    let posts =
      Shlok &&
      Shlok.map(post => {
        return {
          ShlokNo: post.ShlokNo,
          DateOfSubmissionOnTelegram:
            post.DateOfSubmissionOnTelegram &&
            post.DateOfSubmissionOnTelegram.split("T")[0],
          EvaluatedDate: post.EvaluatedDate,
          Rating: post.Rating
        };
      });
    return posts;
  };
}

export default withRouter(ViewapprovedSlok);
