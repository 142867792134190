import React, { Component } from "react";
import { Card, Button, Input, Spinner, Label, Modal, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Terms from '../Common/Terms'
import { ToastContainer, toast } from 'react-toastify';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'
import { getFromSession } from '../Utils/SessionStorage'
import { setGlobalCssModule } from "reactstrap/lib/utils";


class UpdateTransaction extends Component {
   
    constructor(props) {
        super(props);
        this.state = {  
            
            PhId : props.PhId,  
            ContestantName:props.ContestantName,
            RegistrationCode:props.RegistrationCode, 
            Transaction : '',
            date: ''
            
        };
         
     
    }  

    changestatus = async () => {              
    this.setState({loading: true })
     const {PhId, Transaction, date} = this.state;
     if (Transaction && date ) 
     {   
                     let data = {
                            "PhId":PhId,
                            "TransactionId":Transaction,
                            "PaidDate":date                            
                             }                             
                             let val_obj = {
                                data: data,
                                request_type: 'POST',
                                url: `ApprovedShloks/ClaimSinglePayment`            
                            }                          
                            let response = await requestServerData(val_obj, 'admin')                          
                           if(response.response) { 
                                toast.success('Payment Updated Successfully')                                
                                 this.props.history.push('/pending-payout')                              
                            //    window.location.reload(true);
                            } 
                            else
                            {
                                toast.error('try again later') 
                            }                  
                   
     } 
     else
      {                    
     toast.error('Please fill all field')
      }
  this.setState({loading: false })
 }

    
      formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    handleChange = (event) => {
        this.setState({
                [event.target.name]: event.target.value
            })
        
    }
    

    


    render()
    {
        return(
            <div className={"top-main"} >
            <div style={{ paddingTop: "50px", margin: "auto", maxWidth: "500px" }}>
              <Card style={{ padding: 20 }}>
              <ToastContainer position="top-center" />
              <FormGroup>
        <Label >{this.state.ContestantName}({this.state.RegistrationCode})</Label>                
            </FormGroup>
              <FormGroup>
                <Label >Transaction id</Label>
                <Input type="text" name="Transaction" id="Transaction" placeholder="Enter Transaction id *" value={this.state.Transaction} onChange={(e) => this.handleChange(e)}  />
            </FormGroup>
            <FormGroup>
                <Label >Transaction Date</Label>
                <Input type="date" name="date" id="date" placeholder="Enter Transaction Date *" value={this.state.date} onChange={(e) => this.handleChange(e)}  />
            </FormGroup>
            <FormGroup>
                {/* <label>Choose File</label> */}
                <Button class="btn btn-outline-success"  onClick={() => this.changestatus()} >Pay Now</Button>
            

            </FormGroup>
            </Card>
            </div>
        </div>
        
        )
    }
}
export default withRouter(UpdateTransaction);