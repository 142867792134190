import React from "react";
import { Progress, Jumbotron, Button } from "reactstrap";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  Spinner
} from "reactstrap";
import NativeDb from "../Utils/nativeDb.json";
import { MDBDataTable, MDBInput } from "mdbreact";
import { requestServerData } from "../Utils/Config";
import { formatNumber } from "../Utils/Methods";
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import PendingPayout from "./PendingPayout";
import DashboardMini from "../Admin/DashboardMini";
import StateWiseData from "./StateWiseData";
import { element } from "prop-types";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ContestentCount: 0,
      Zone: [],
      zoneLoading: true,
      StateZoneWise: [],
      State: [],
      stateLoading: true
    };
  }

  componentDidMount(preProps, preState) {
    this.GetUserDataZoneWise();
    this.GetUserDataStateWise();
  }

  GetUserDataZoneWise = async () => {
    let val_obj = {
      url: `Dashboards/ZoneWiseDashboard?type=Zone`,
      data: "",
      request_type: "get"
    };

    let response = await requestServerData(val_obj, "admin");
    if (response.response) {
      let getdata = response.data.data.result;
      let ContestentCount = getdata.reduce(
        (total, { NoOfContestant = 0 }) =>
          ((total += NoOfContestant) && total) || total,
        0
      );
      let Zone = getdata || [];
      this.setState({ Zone, ContestentCount, zoneLoading: false });
    } else {
      alert("Try Again after some time . . .");
    }
  };

  GetUserDataStateWise = async () => {
    let val_obj = {
      url: `Dashboards/ZoneWiseDashboard?type=state`,
      data: "",
      request_type: "get"
    };

    let response = await requestServerData(val_obj, "admin");
    if (response.response) {
      let getdata = response.data.data.result;
      let StateZoneWise = getdata.reduce(
        (total, { zone, NoOfContestant = 0, State }) => {
          let { count = 0, userCount = 0, states = [] } = total[zone] || {};
          states.push({
            name: State,
            count: NoOfContestant
          });
          count++;
          userCount += NoOfContestant;
          total[zone] = {
            name: zone,
            count,
            states,
            userCount
          };
          return total;
        },
        {}
      );
      let State = getdata || [];
      this.setState({
        State,
        StateZoneWise: Object.values(StateZoneWise),
        stateLoading: false
      });
    } else {
      alert("Try Again after some time . . .");
    }
  };
  render() {
    let {
      Zone,
      ContestentCount = 0,
      StateZoneWise,
      stateLoading,
      zoneLoading
    } = this.state;
    return (
      <React.Fragment>
        {/* <PendingPayout/> */}
        {/* <StateWiseData /> */}
        {/* <div
          style={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#f3f3f3",
            borderRadius: 10,
            padding: 10,
            marginBottom: 10
          }}
        >
          <h5>
            Welcome back,<b> Admin! </b>
          </h5>
          <h5>Your Dashboard is here to show the data</h5>
        </div> */}
        <DashboardMini />
        <div
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#f3f3f3",
            borderRadius: 10,
            padding: 10,
            marginBottom: 10,
            flex: 1
          }}
        >
          <div
            style={{
              marginTop: 30,
              marginLeft: 20,
              marginBottom: 30
            }}
          >
            <h6>
              <b>Zone</b>
            </h6>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              alignContent: "center",
              flexWrap: "wrap"
            }}
          >
            {(zoneLoading && (
              <Spinner style={{ width: "3rem", height: "3rem", padding: 30 }} />
            )) ||
              null}
            {(Zone &&
              Zone.length &&
              Zone.map(({ zone, NoOfContestant }) => {
                let percentage =
                  (ContestentCount &&
                    (NoOfContestant / ContestentCount) * 100) ||
                  0;
                percentage = percentage > 50 ? 100 - percentage : percentage;
                let extraStyle = {};
                if (percentage < 50) {
                  extraStyle = {
                    transform: "rotate(180deg)"
                  };
                }
                let deg = (18 / 5) * percentage - 270;
                return (
                  <div
                    style={{
                      cursor: "pointer",
                      borderWidth: 0,
                      borderLeftWidth: 10,
                      borderStyle: "solid",
                      borderColor: "white",
                      minWidth: 190,
                      minHeight: 120,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      borderRadius: 10,
                      margin: 10,
                      boxShadow:
                        "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)"
                    }}
                  >
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        border: "5px solid transparent",
                        ...extraStyle,
                        background:
                          percentage > 50
                            ? `linear-gradient(#fff,#fff) padding-box,linear-gradient(${deg}deg, blue 50%,transparent 0) border-box,linear-gradient(to right, #f2f2f2 50%,blue 0) border-box`
                            : `linear-gradient(#fff,#fff) padding-box,linear-gradient(${deg}deg, #f2f2f2 50%,transparent 0) border-box,linear-gradient(to right, blue 50%,#f2f2f2 0) border-box`
                      }}
                    >
                      <div
                        style={{
                          paddingTop: 7,
                          paddingLeft: 7,
                          ...extraStyle
                        }}
                      >
                        <h4>
                          <b>{percentage.toFixed(0)}%</b>
                        </h4>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20
                        }}
                      >
                        <h5>{zone}</h5>
                      </div>
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20
                        }}
                      >
                        <h3>
                          <b>{formatNumber(NoOfContestant, 0)}</b>
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })) ||
              null}
          </div>
        </div>
        <div
          style={{
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "#f3f3f3",
            borderRadius: 10,
            padding: 10,
            marginBottom: 10,
            flex: 1
          }}
        >
          <div
            style={{
              marginTop: 30,
              marginLeft: 20,
              marginBottom: 30
            }}
          >
            <h6>
              <b>State</b>
            </h6>
          </div>
          {(zoneLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Spinner style={{ width: "3rem", height: "3rem", padding: 30 }} />
            </div>
          )) ||
            null}
          {(StateZoneWise &&
            StateZoneWise.length &&
            StateZoneWise.map(({ name, count, userCount, states = [] }) => {
              return (
                <div>
                  <div
                    style={{
                      marginTop: 30,
                      marginLeft: 20,
                      marginBottom: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <h3>{name}</h3>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          marginRight: 10
                        }}
                      >
                        <h5>{count}</h5>
                        <h6>
                          <b>state</b>
                        </h6>
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end"
                        }}
                      >
                        <h5>{userCount}</h5>
                        <h6>
                          <b>User</b>
                        </h6>
                      </p>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      flex: 1
                    }}
                  >
                    {states &&
                      states.length &&
                      states.map(({ name, count }) => {
                        return (
                          <div
                            title={count}
                            style={{
                              cursor: "pointer",
                              borderStyle: "solid",
                              minWidth: 90,
                              minHeight: 20,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 10,
                              margin: 10,
                              boxShadow:
                                "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)"
                            }}
                          >
                            <h1>
                              <b>{name}</b>
                            </h1>
                            <p>{formatNumber(count, 0)}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })) ||
            null}
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
