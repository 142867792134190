import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import SideBar from './SideBar';
import {
  Card,
  Button,
  Input,
  Spinner,
  Label,
  Modal,
  DropdownButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody
} from "reactstrap";
import { requestServerData } from "../Utils/Config";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      submitLoading: false,
      username: null,
      password: null,
      status: "Active",
      user_type: [],
      user_type_id:null,
      user_type_name: null,
      dropDownOpen: false,
      mandatory: null,
      Users: [],
      user_type_json: {},

      /// update opr fields
      updateLoading: false,
      UserId: null,
      UserTypeID: null,
      UserName: null,
      Password: null,
      UserTypeName: null,
      UserStatus: null,
      dropDownOpenForUpdate: false,
    };
  }

  handleOnChange = e => {
    let { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async () => {
    this.setState({
      submitLoading: true
    });
    const {
      username,
      password,
      user_type_name,
      user_type_id,
      status,
      user_type
    } = this.state;
    if (!username || !password || (user_type_id === null || user_type_id === undefined) || !status) {
      toast.error(
        `${(!username && "Username") ||
          (!password && "Password") ||
          ((user_type_id === null || user_type_id === undefined) && "User type") ||
          (!status && "Status")} is mandatory`
      );
    } else {
  
      let val_obj = {
        data: {
          UserName: username,
          Password: password,
          UserTypeId: user_type_id,
          Status: status
        },
        request_type: "POST",
        url: "User/InsertUser"
      };

      let response = await requestServerData(val_obj, "admin");
      if (response.response) {
        this.setState({
          username: "",
          password: "",
          status: "Active",
          user_type_name: user_type[0] && user_type[0].UserTypeName,
          dropDownOpen: false,
          mandatory: null
        });
        toast.success("User Added Successfully");
      } else {
        toast.error("Please try again");
      }
    }
    this.getUserType();
    this.setState({
      submitLoading: false
    });
  };

  toggle = () => {
    this.setState(preState => ({
      dropDownOpen: !preState.dropDownOpen
    }));
  };

  toggleUpdate = () => {
    this.setState(preState => ({
      dropDownOpenForUpdate: !preState.dropDownOpenForUpdate
    }));
  };

  componentDidMount() {
    this.getUserType();
  }

  getUserType = async () => {
    let val_obj = {
      data: {},
      request_type: "GET",
      url: "/UserType/GetUserType"
    };

    let response = await requestServerData(val_obj, "admin");

    if (response.response) {
      let data =
        (response.data && response.data.data && response.data.data.result) ||
        [];
      let user_type_json = data.reduce(
        (total, element) =>
          (element &&
            (total[element.UserITypeId] = element.UserTypeName) &&
            total) ||
          total,
        {}
      );
      this.setState({
        loading: false,
        user_type_name: data[0] && data[0].UserTypeName,
        user_type_id:data[0] && data[0].UserITypeId,
        user_type: data,
        user_type_json
      });
      this.getUsers();
    } else {
      toast.error("Please try after some time");
    }
  };

  assembleUser = User => {
    let { user_type_json = {} } = this.state;
    let posts =
      User &&
      User.result.map(data => {
        let {
          UserId,
          UserTypeID,
          UserName,
          Password,
          UserTypeName,
          Status,
          CreatedDate = null,
          CreatedBy,
          LastLogin = null
        } = data || {};
        return {
          UserId,
          UserTypeID,
          UserName,
          Password,
          UserTypeName,
          Status: Status ? "True" : "False",
          CreatedDate:
            (CreatedDate && new Date(CreatedDate).toDateString()) || null,
          CreatedBy: user_type_json[CreatedBy],
          LastLogin,
          Edit: (
            <div className="action-icons">
              <Button
                color="primary mr-2 "
                onClick={() => this.handleClick("edit", data)}
              >
                Edit
              </Button>
            </div>
          )
        };
      });

    return posts;
  };

  getUsers = async () => {
    let val_obj = {
      url: `User/GetUser`,
      data: {},
      request_type: "GET"
    };
    let response = await requestServerData(val_obj, "admin");
    if (response.response) {
      const data = {
        columns: [
          // { label: "ID", field: "UserId" },
          { label: "User Type Id", field: "UserTypeID" },
          { label: "Name", field: "UserName" },
          { label: "User Type", field: "UserTypeName" },
          { label: "Status", field: "Status" },
          { label: "Created On", field: "CreatedDate" },
          { label: "Created By", field: "CreatedBy" },
          { label: "Last Login", field: "LastLogin" },
          { label: "", field: "Edit" }
        ],
        rows: this.assembleUser(response.data.data)
      };

      this.setState({
        Users: data,
        listLoading: false
      });
    } else {
      alert("try again . . .");
    }
  };

  updateUser = async () => {
    let {
      UserId,
      UserName,
      Password,
      UserTypeID,
    } = this.state;

    this.setState({
      updateLoading: true
    });

    let val_obj = {
      url: `User/UpdatetUser`,
      data: {
        UserId,
        UserName,
        Password,
        UserTypeID
      },
      request_type: "POST"
    };
    let response = await requestServerData(val_obj, "admin");
    if (response.response) {
      this.getUserType();
    } else {
      alert("try again . . .");
    }
    this.setState({
      updateLoading: false,
      isModalActive: false
    });
  };

  handleClick = (key, data = {}) => {
    let { UserId, UserTypeID, UserName, UserTypeName, Status , Password } = data;

    if (this.state.updateLoading) return void 0;
    switch (key) {
      case "edit":
        this.setState({
          UserId,
          UserTypeID,
          UserName,
          Password,
          UserTypeName,
          UserStatus:Status,
          isModalActive: true
        });
        break;
      case "update":
        this.updateUser();
        break;
      case "close_modal":
        this.setState({
          updateLoading: false,
          UserId: null,
          UserTypeID: null,
          UserName: null,
          UserTypeName: null,
          Status: null,
          isModalActive: false
        });
        break;
      default:
        break;
    }
  };

  render() {
    let {
      dropDownOpen,
      status,
      user_type,
      loading,
      listLoading,
      user_type_id,
      Users,
      isModalActive,
      updateLoading,
      UserName,
      UserTypeID,
      UserStatus,
      Password,
      dropDownOpenForUpdate
    } = this.state;
    UserStatus = UserStatus ? "Active" : "InActive";
    status = status ? "Active" : "InActive";
    if (loading)
      return (
        <div
          style={{
            margin: "15px 0px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
    return (
      <div className={"top-main"} style={{ height: window.innerHeight }}>
        <div style={{ paddingTop: "50px", margin: "auto", maxWidth: "500px" }}>
          <Card style={{ padding: 20 }}>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
            <h2>Add User</h2>

            <div class="form-group">
              <label>User Type Id* </label>
              <Input
                type="select"
                name="user_type_id"
                onClick={e => this.handleOnChange(e)}
                defaultValue={user_type_id}
              >
                {user_type &&
                  user_type.map(({ UserTypeName, UserITypeId }) => {
                    return <option value={UserITypeId}>{UserTypeName}</option>;
                  })}
              </Input>
            </div>

            <div class="form-group">
              <label class>User Name*</label>
              <input
                name="username"
                id="username"
                placeholder="User Type Name"
                class="form-control"
                type="text"
                value={this.state.username}
                onChange={this.handleOnChange}
              />
            </div>
            <div class="form-group">
              <label class>Password*</label>
              <Input
                name="password"
                id="password"
                placeholder="Enter Password"
                class="form-control"
                type="password"
                value={this.state.password}
                onChange={e => this.handleOnChange(e)}
              />
            </div>
            <div class="form-group">
              <label class>Status* </label>
              <Dropdown isOpen={dropDownOpen} toggle={this.toggle}>
                <DropdownToggle caret>{status}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={e =>
                      this.handleOnChange({
                        target: { name: "status", value: true }
                      })
                    }
                  >
                    Active
                  </DropdownItem>
                  <DropdownItem
                    onClick={e =>
                      this.handleOnChange({
                        target: { name: "status", value: false }
                      })
                    }
                  >
                    Inactive
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {this.state.submitLoading ? (
              <button
                type="submit"
                class="btn btn-secondary"
                style={{
                  marginTop: "12px",
                  width: "100%",
                  backgroundColor: "	#9932CC"
                }}
              >
                <Spinner />
              </button>
            ) : (
              <button
                type="submit"
                class="btn btn-secondary"
                style={{
                  marginTop: "12px",
                  width: "100%",
                  backgroundColor: "	#9932CC"
                }}
                onClick={() => this.handleSubmit()}
              >
                Submit User{" "}
              </button>
            )}
          </Card>
        </div>
        <div
          style={{
            marginTop: 50,
            marginBottom: 50
          }}
        >
          <Card>
            <CardBody>
              {listLoading ? (
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Spinner />
                </div>
              ) : (
                <div className="table-responsive">
                  <MDBDataTable
                    id={"userList"}
                    striped
                    bordered
                    entriesOptions={[10, 50, 100, 1000]}
                    hover
                    width={50}
                    data={Users}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
        <Modal
          isOpen={isModalActive}
          toggle={() => this.handleClick("close_modal")}
        >
          <ModalHeader>Edit User</ModalHeader>
          <ModalBody>
            <div class="form-group">
              <label>User Type Id* </label>
              <Input
                type="select"
                name="UserTypeID"
                defaultValue={UserTypeID}
                onClick={e => this.handleOnChange(e)}
              >
                {user_type &&
                  user_type.map(({ UserTypeName, UserITypeId }) => {
                    return <option value={UserITypeId}>{UserTypeName}</option>;
                  })}
              </Input>
            </div>

            <div class="form-group">
              <label class>User Name*</label>
              <input
                name="UserName"
                id="UserName"
                placeholder="User Type Name"
                class="form-control"
                type="text"
                value={UserName}
                onChange={this.handleOnChange}
              />
            </div>
            <div class="form-group">
              <label class>Password*</label>
              <Input
                name="Password"
                id="Password"
                placeholder="Enter Password"
                class="form-control"
                type="password"
                value={Password}
                onChange={e => this.handleOnChange(e)}
              />
            </div>
            <div class="form-group">
              <label class>Status* </label>
              <Dropdown isOpen={dropDownOpenForUpdate} toggle={this.toggleUpdate}>
                <DropdownToggle caret>{UserStatus}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={e =>
                      this.handleOnChange({
                        target: { name: "UserStatus", value: true }
                      })
                    }
                  >
                    Active
                  </DropdownItem>
                  <DropdownItem
                    onClick={e =>
                      this.handleOnChange({
                        target: { name: "UserStatus", value: false }
                      })
                    }
                  >
                    Inactive
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                this.handleClick("update");
              }}
            >
              {updateLoading ? <div style={{paddingLeft:25,paddingRight:25}}><Spinner style={{ width: "1rem", height: "1rem" }}/></div> : "Update"}
            </Button>
            <Button
              color="secondary"
              style={{
                opacity:updateLoading ? ".5" :"1"
              }}
              onClick={() => {
                this.handleClick("close_modal");
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default withRouter(AddUser);
