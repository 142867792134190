import React, { Component } from "react";
import {
  Card,
  CardTitle,
  Button,
  Input,
  Spinner,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row
} from "reactstrap";
import NativeDb from "../Utils/nativeDb.json";
import { requestServerData } from "../Utils/Config";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const date = new Date();

class ApprovedSlok extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contestentID: props.match.params.contestentID || "",
      userName: props.location.state.ContestantName || "",
      getRequestFor: "",
      selectedChapters: [],
      selectedSlocks: [],
      today:
        date.getFullYear() +
        "-" +
        "0" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate(),
      slock: "",
      slokData: [],
      initial: false
    };
  }

  componentDidMount() {
    this.setupComponent();
  }
  formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  componentDidUpdate(preProps, preState) {
    if (preState.submitStatus !== this.state.submitStatus) {
      this.handleClickShowSlocksList(this.state.getRequestFor);
    }
  }

  render() {
    if (this.state.fulLoading) {
      return (
        <div
          style={{
            margin: "15px 0px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
    } else {
      return (
        <div>
          <Card style={{ padding: 20, marginBottom: 20 }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <CardTitle>Name : {this.state.userName}</CardTitle>
              <CardTitle>
                Total Shlok : {this.state.shlok}/{this.state.totalShlok}
              </CardTitle>
              <CardTitle>
                Registration Code : {this.state.contestentID}
              </CardTitle>
            </div>
          </Card>
          <Card>
            <div style={{ padding: 10 }}>
              <CardTitle style={{ margin: 0 }}>Select Chapter</CardTitle>
            </div>
            <div style={{ padding: 10 }}>
              <Card>
                <ToastContainer position="bottom-center" autoClose={4000} />
                <div style={{ padding: 10 /* width:"900px" */ }}>
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "100%"
                    }}
                  >
                    {this.renderSlockChapter()}
                  </Form>
                </div>
              </Card>
              {this.state.showSlock && (
                <div style={{ marginTop: 30 }}>
                  {this.state[this.state.getRequestFor] &&
                    this.renderSlockListByChapter()}
                </div>
              )}
            </div>
          </Card>
        </div>
      );
    }
  }
  renderSlockChapter = () => {
    let extraStyle = {
      width: "30%"
    };
    if (window.innerWidth <= 500) {
      extraStyle = {
        flex: 1
      };
    }
    return (
      this.state.chapterList &&
      this.state.chapterList.map((chapter, index) => {
        return (
          <FormGroup
            style={{
              width: 300,
              padding: 5,
              flexDirection: "row",
              flexWrap: "wrap"
            }}
            key={index}
            check
            inline
          >
            <Card style={{ padding: "5px 20px", width: "100%" }}>
              <Label check style={{ display: "flex", alignItems: "center" }}>
                <Input
                  type="radio"
                  name={"redio"}
                  defaultChecked={this.state.getRequestFor == chapter.ChapterNO}
                  onClick={e => {
                    this.handleClickChapter(e, chapter);
                  }}
                />

                <div style={{ float: "left", marginLeft: 20 }}>
                  <CardTitle style={{ margin: 0, wordBreak: "break-all" }}>
                    {chapter.ChapterNO} ({chapter.ChapterName})<br></br>
                    <b style={{ fontSize: "14px" }}>{chapter.ShalokCount}</b>
                  </CardTitle>
                </div>
              </Label>
            </Card>
          </FormGroup>
        );
      })
    );
  };

  renderSlockListByChapter = () => {
    if (!this.state.loading) {
      return (
        <Card>
          <div
            style={{
              padding: "10px 50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap"
              }}
            >
              <CardTitle style={{ margin: 0, fontWeight: "bold" }}>
                Select Date:{" "}
              </CardTitle>
              <div>
                <Input
                  type="date"
                  value={this.state.DateOfSubmissionOnTelegram}
                  name={"date"}
                  max={this.state.today}
                  onChange={e => this.handleChangeDate(e)}
                />
              </div>
            </div>

            <div>
              <Dropdown
                isOpen={this.state.isRating}
                toggle={() => this.handleClickDropDwon("rating")}
              >
                <DropdownToggle>
                  {this.state.rating ? this.state.rating : "Select Rating"}
                </DropdownToggle>
                <DropdownMenu>
                  {ratingType.map((rating, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          this.setState({ rating: rating });
                        }}
                      >
                        {rating}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap"
              }}
            >
              <CardTitle style={{ margin: 0 }}>Evaluated By : Admin</CardTitle>
              <Button color={"success"} onClick={this.handleSubmitSlock}>
                {" "}
                {"Submit"}
              </Button>
            </div>
          </div>
          {/* {
                                this.state.loading ?
                                    <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center' }}>
                                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                                    </div>
                                    :
                                    <div style={{ padding: 10, float: 'right' }}>
                                        
                                    </div>
                            } */}
          <div style={{ padding: 10 }}>
            <Card style={{ padding: 10 }}>
              <div style={{ padding: 10 }}>
                <CardTitle style={{ margin: 0 }}>
                  Chapter No : {this.state.getRequestFor}
                </CardTitle>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  flexWrap: "wrap"
                }}
              >
                {this.state[this.state.getRequestFor].map((slock, index) => {
                  return (
                    <React.Fragment key={index}>
                      <FormGroup
                        style={{ minWidth: 200, width: "20%", padding: 5 }}
                        key={index}
                        check
                        inline
                      >
                        <Card style={{ padding: "5px 20px", width: "100%" }}>
                          <Label
                            check
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Input
                              type="checkbox"
                              onClick={e => {
                                this.handleClickSlok(e, slock);
                              }}
                            />
                            <div style={{ float: "left", marginLeft: 20 }}>
                              <CardTitle style={{ margin: 0 }}>
                                {"Slok No : "}
                                {slock.ShlokNo}
                              </CardTitle>
                            </div>
                          </Label>
                        </Card>
                      </FormGroup>
                    </React.Fragment>
                  );
                })}
              </div>
            </Card>
          </div>
        </Card>
      );
    } else {
      return (
        <div
          style={{
            margin: "15px 0px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // togle function

  handleClickDropDwon = () => {
    this.setState({
      isRating: !this.state.isRating
    });
  };

  handleChangeDate = e => {
    this.setState({
      DateOfSubmissionOnTelegram: e.target.value
    });
  };

  handleCloseSlokList = () => {
    this.setState({
      showSlock: false
    });
  };

  handleClickShowSlock = () => {
    this.setState({
      showSlock: true
    });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  // set shlock and chaper's

  handleClickChapter = (e, chapter) => {
    if (e.target.checked) {
      this.setState({
        getRequestFor: chapter.ChapterNO,
        [chapter.CSId]: e.target.checked
      });
      this.handleClickShowSlocksList(chapter.ChapterNO);
    } else {
      this.setState({
        getRequestFor: chapter.ChapterNO,
        [chapter.CSId]: e.target.checked
      });
    }
  };

  handleClickSlok = async (e, slock) => {
    let selectedSlocks = this.state.selectedSlocks;
    if (e.target.checked) {
      //console.log(JSON.stringify(slock));
      // await selectedSlocks.push(slock.Shalok)
      await selectedSlocks.push({
        CSId: slock.Shalok.CsId,
        ChapterNo: slock.Shalok.ChapterNo,
        ShlokNo: slock.ShlokNo
      });
    } else {
      let selectedData = await selectedSlocks.filter(
        item => item.CSId != slock.Shalok.CsId
      );
      selectedSlocks = selectedData;
    }
    this.setState({
      selectedSlocks: selectedSlocks
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // server request

  setupComponent = async () => {
    this.setState({
      loading: true
    });
    let val_obj = {
      url: `ApprovedShloks/GetChapterWiseShloksNonAttemptedCount?RegNo=${this.state.contestentID}`,
      data: "",
      request_type: "GET"
    };

    // console.log('Approved Slock ', val_obj)
    let response = await requestServerData(val_obj, "admin");
    // console.log('Approved Slock ', response)
    if (response.response) {
      this.setState({
        chapterList: response.data.data.result.ChapterWiseShaloks,
        loading: false,
        totalShlok: response.data.data.result.TotalShalokCount,
        shlok: response.data.data.result.TotalNonAttemptedShalok,
        DateOfSubmissionOnTelegram: this.formatDate(new Date())
      });
    } else {
      toast.error("Network error");
    }
  };

  handleClickShowSlocksList = async chapterNum => {
    this.setState({
      loading: true,
      today: this.formatDate(new Date())
    });

    let val_obj = {
      url: `ApprovedShloks/GetNonAttemptedShloksByRegistrationCodeChapterNO?RegNo=${this.state.contestentID}&ChapterNO=${chapterNum}`,
      data: "",
      request_type: "GET"
    };

    //e.log('Approved Slock ', val_obj)
    let response = await requestServerData(val_obj, "admin");
    //console.log('shlock list ', response)

    if (response.response) {
      this.setState({
        loading: false,
        [chapterNum]: response.data.data.result.NonAttemptedShaloks,
        showSlock: true
      });
    } else {
      toast.error("Network error");
    }
  };

  handleSubmitSlock = async () => {
    if (!this.state.rating) {
      toast.error("Please Select rating");
      return false;
    }
    if (this.state.selectedSlocks.length == 0) {
      toast.error("Please Select atleast one shlok");
      return false;
    }
    if (this.state.DateOfSubmissionOnTelegram) {
      this.setState({ loading: true });
      let value = {
        RegistrationCode: this.state.contestentID,
        ShlokArry: this.state.selectedSlocks,
        DateOfSubmissionOnTelegram: this.state.DateOfSubmissionOnTelegram,
        EvaluatedBy: 1,
        Rating: this.state.rating
      };

      let val_obj = {
        url: `ApprovedShloks/ApprovedShloks`,
        data: value,
        request_type: "POST"
      };
      // console.log('Approved Slock To server', JSON.stringify(value))
      let response = await requestServerData(val_obj, "admin");
      // console.log("response11", response)

      // setTimeout(function(){
      //  }, 5000)
      if (response.response) {
        toast.success(response.data.data.result);

        this.setState({
          loading: false,
          submitStatus: response.data.data,
          ShlokArry: [],
          DateOfSubmissionOnTelegram: "",
          selectedSlocks: []
        });

        this.setupComponent();
      } else {
        toast.error("Try Again after some time . . .");
      }
    } else {
      toast.error("Please Select Date First");
    }
  };
}

export default withRouter(ApprovedSlok);

const ratingType = ["Good", "Average", "Excellent"];
