import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
// import SideBar from './SideBar';
import { Spinner } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify';
import { requestServerData } from '../Utils/Config';
import { getFromSession } from '../Utils/SessionStorage'
import { AllowOnlyNumber, AllowOnlyNumberAlfaNumerik } from '../Validation/Validation'

class KycUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: getFromSession('user'),
        }
    }

    componentDidMount() {

        this.getKycForUser()
    }

    render() {

        if (this.state.loading) {

            return (

                <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center' }}>
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                </div>
            )
        } else {

            return (
                <div style={{ width: '100%' }}>
                    <div class="card" style={{ padding: '20px' }}>
                        <div>
                            <div class="Toastify"></div>
                            <ToastContainer
                                position="top-center"
                            />
                            <h2>KYC Details Form</h2>
                            <div class="form-group">
                                <label class>Account Holder Name*</label>
                                <input name="accountHolder" id="accountHolder" placeholder="Account holder name" class="form-control" type="text" value={this.state.accountHolder}
                                    onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div class="form-group">
                                <label class>Bank Name*</label>
                                <input name="bankName" id="bankName" placeholder="Your Bank Name" class="form-control" type="text" value={this.state.bankName}
                                    onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div class="form-group">
                                <label class>Account Number*</label>
                                <input name="accountNo" id="accountNo" placeholder="Your Account Number" class="form-control" type="text" value={this.state.accountNo}
                                    onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div class="form-group">
                                <label class>Branch Name *</label>
                                <input name="branch" id="branch" placeholder="Your Branch Name" class="form-control" type="text" value={this.state.branch}
                                    onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div class="form-group">
                                <label class>IFSC Code *</label>
                                <input name="ifsc" id="ifsc" placeholder="Your IFSC Code" class="form-control" type="text" value={this.state.ifsc}
                                    onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div class="form-group">
                                <label class>Adhar Card Number *</label>
                                <input name="adharNo" id="adharNo" placeholder="Your Adhar Number" class="form-control" type="text" value={this.state.adharNo}
                                    onChange={(e) => this.handleChange(e)} required />
                            </div>
                            <div class="form-group">
                                <label class>Pan Card Number</label>
                                <input name="pan" id="pan" placeholder="Your PanCard Number" class="form-control" type="text" value={this.state.pan}
                                    onChange={(e) => this.handleChange(e)}  />
                            </div>
                            <button type="submit" class="btn btn-secondary" style={{ marginTop: "12px", width: "100%", backgroundColor: "	#9932CC" }} onClick={() => this.handleSubmit()}>Update</button>
                        </div>

                    </div>
                </div>
            )
        }
    }

    handleChange = (event) => {

        if (event.target.name === 'accountNo') {

            if (AllowOnlyNumber(event.target.value)) {

                if (event.target.value.length < 17 ) {

                    this.setState({
                        [event.target.name]: event.target.value
                    })
                }
            }
        } else if (event.target.name === 'ifsc') {            

            if (AllowOnlyNumberAlfaNumerik(event.target.value)) {

                if (event.target.value.length < 12) {

                    this.setState({
                        [event.target.name]: event.target.value
                    })
                }
            } else {

                this.setState({
                    [event.target.name]: ''
                })
            }
        } else if (event.target.name === 'adharNo') {

            if (AllowOnlyNumber(event.target.value)) {

                if (event.target.value.length < 13 )  {

                    this.setState({
                        [event.target.name]: event.target.value
                    })
                }
            }
        } else if (event.target.name === 'pan') {

            if (AllowOnlyNumberAlfaNumerik(event.target.value)) {

                if (event.target.value.length < 11) {

                    this.setState({
                        [event.target.name]: event.target.value
                    })
                }
            } else {

                this.setState({
                    [event.target.name]: ''
                })
            }
        } else {

            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    getKycForUser = async () => {

        this.setState({
            loading: true
        })

        let val_obj = {
            url: `Contestent/GetKycDataByRegNo?RegNum=${this.state.user.data.uid}`,
            data: '',
            request_type: 'GET'
        }

        // console.log('kyc update', val_obj)

        let response = await requestServerData(val_obj, 'user')
        // console.log('kyc update', response.data.data.result)
        if (response.response) {

            let data = response.data.data.result && response.data.data.result[0] || undefined

            if (data) {

                this.setState({
                    adharNo: data.Aadhar, accountNo: data.AccountNo, bankName: data.BankName, branch: data.BankBranchName, ifsc: data.IFSCCode, pan: data.PanCard, accountHolder: data.AccountHolderName, uid: data.RegistrationCode
                })
            }
        } else {

            alert('try again . . .')
        }
        this.setState({
            loading: false
        })
    }

    handleSubmit = async () => {

        this.setState({
            loading: true
        })
        const { adharNo, accountNo, bankName, branch, ifsc, pan, accountHolder, uid } = this.state;

        if (adharNo && accountNo && bankName && branch && ifsc && accountHolder && uid) {

            let error = undefined

            if (adharNo.length > 13 && adharNo < 13) {

                error = 'adhar number not valid'
            }

            if (accountNo.length < 8) {

                error = 'account number not valid'
            }

            if (ifsc.length < 11) {

                error = 'ifsc not valid'
            }

            if (error) {

                toast.error(error)
            } else {

                let data = {
                    "RegistrationCode": uid,
                    "Aadhar": adharNo || '',
                    "PanCard": pan || '',
                    "BankName": bankName || '',
                    "AccountNo": accountNo || '',
                    "IFSCCode": ifsc || '',
                    "BankBranchName": branch || '',
                    "AccountHolderName": accountHolder || ''
                }

                let val_obj = {

                    data: data,
                    request_type: 'POST',
                    url: 'Contestent/UpdateKYC'
                }
                // console.log('val_obj', val_obj)
                let response = await requestServerData(val_obj, 'user')
                // console.log('update kyc response', response)

                if (response.response) {

                    this.getKycForUser()
                    alert('KYC updated successfully.');
                    // toast.success('KYC updated successfully.');
                } else {
                    toast.error('Error')
                }
            }
        } else {

            toast.error('Please fill all field')
        }
        this.setState({
            loading: false
        })
    }
}

export default withRouter(KycUpdate);