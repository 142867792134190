import React, { Component } from "react";
import {
  Card,
  CardTitle,
  Button,
  Input,
  Spinner,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row
} from "reactstrap";
import NativeDb from "../Utils/nativeDb.json";
import { requestServerData } from "../Utils/Config";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const date = new Date();

class NewapprovedSlok extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contestentID: null,
      userName: null,
      getRequestFor: "",
      selectedChapters: [],
      selectedSlocks: [],
      today:
        date.getFullYear() +
        "-" +
        "0" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate(),
      slock: "",
      slokData: [],
      initial: false
    };
  }

  componentDidMount() {}
  formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  componentDidUpdate(preProps, preState) {
    if (preState.submitStatus !== this.state.submitStatus) {
      this.handleClickShowSlocksList(this.state.getRequestFor);
    }
  }

  render() {
    if (this.state.fulLoading) {
      return (
        <div
          style={{
            margin: "15px 0px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
    } else {
      return (
        <div className={"top-main"} style={{ height: window.innerHeight }}>
          <Card>
            <div align="center">
              <h3>Approved Shlok </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                borderColor: "black",
                backgroundColor: "black",
                color: "white",
                borderRadius: "5px 20px 5px",
                fontweight: "bold",
                padding: "15px 20px",
                width: "100%",
                flexWrap: "wrap"
              }}
            >
              <h5>Registration Code : </h5>
              <from
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap"
                }}
              >
                <input
                  type="text"
                  name="regcode"
                  onChange={e => this.handleChange(e)}
                  onKeyDown={e => this.handleEnterSubmit(e)}
                  id="regcode"
                />
                <button onClick={e => this.handleSubmitUserId(e)} type="submit">
                  Submit
                </button>
              </from>
            </div>
          </Card>
          {this.state.userName && (
            <Card style={{ padding: 20, marginBottom: 20 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CardTitle>Name : {this.state.userName}</CardTitle>
                <CardTitle>
                  Total Shlok : {this.state.shlok}/{this.state.totalShlok}
                </CardTitle>
              </div>
            </Card>
          )}
          <Card>
            {/* <div style={{ padding: 10 }}>
                            <CardTitle style={{ margin: 0 }}>Select Chapter</CardTitle>
                        </div> */}
            <div style={{ padding: 10 }}>
              <Card>
                <ToastContainer position="bottom-center" autoClose={4000} />
                <div style={{ padding: 10 /*width:"900px"*/ }}>
                  <Form
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      display: "flex"
                    }}
                  >
                    {this.renderSlockChapter()}
                  </Form>
                </div>
              </Card>
              {this.state.showSlock && (
                <div style={{ marginTop: 30 }}>
                  {this.state[this.state.getRequestFor] &&
                    this.renderSlockListByChapter()}
                </div>
              )}
            </div>
          </Card>
        </div>
      );
    }
  }
  renderSlockChapter = () => {
    let extraStyle = {
      width: "30%"
    };
    if (window.innerWidth <= 500) {
      extraStyle = {
        flex: 1
      };
    }
    if (!this.state.loading)
      return (
        this.state.chapterList &&
        this.state.chapterList.map((chapter, index) => {
          return (
            <FormGroup
              style={{
                padding: 5,
                flexDirection: "row",
                flexWrap: "wrap",
                width: 300
              }}
              key={index}
              check
              inline
            >
              <Card style={{ padding: "5px 20px", width: "100%" }}>
                <Label check style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    type="radio"
                    name={"redio"}
                    defaultChecked={
                      this.state.getRequestFor == chapter.ChapterNO
                    }
                    onClick={e => {
                      this.handleClickChapter(e, chapter);
                    }}
                  />

                  <div style={{ float: "left", marginLeft: 20 }}>
                    <CardTitle style={{ margin: 0, wordBreak: "break-all" }}>
                      {chapter.ChapterNO}
                      <br />({chapter.ChapterName})
                      <br />
                      <b style={{ fontSize: "14px" }}>{chapter.ShalokCount}</b>
                    </CardTitle>
                  </div>
                </Label>
              </Card>
            </FormGroup>
          );
        })
      );
    else
      return (
        <div
          style={{
            margin: "15px 0px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
  };

  renderSlockListByChapter = () => {
    if (!this.state.loading) {
      return (
        <Card>
          <div
            style={{
              padding: "10px 50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            <CardTitle style={{ margin: 0, fontWeight: "bold" }}>
              Select Date:{" "}
            </CardTitle>
            <div>
              <Input
                type="date"
                value={this.state.DateOfSubmissionOnTelegram}
                name={"date"}
                max={this.state.today}
                onChange={e => this.handleChangeDate(e)}
              />
            </div>
            <div>
              <Dropdown
                isOpen={this.state.isRating}
                toggle={() => this.handleClickDropDwon("rating")}
              >
                <DropdownToggle>
                  {this.state.rating ? this.state.rating : "Select Rating"}
                </DropdownToggle>
                <DropdownMenu>
                  {ratingType.map((rating, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          this.setState({ rating: rating });
                        }}
                      >
                        {rating}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>

            <CardTitle style={{ margin: 0 }}>Evaluated By : Admin</CardTitle>
            <Button color={"success"} onClick={this.handleSubmitSlock}>
              {" "}
              {"Submit"}
            </Button>
          </div>
          {/* {
                                this.state.loading ?
                                    <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center' }}>
                                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                                    </div>
                                    :
                                    <div style={{ padding: 10, float: 'right' }}>
                                        
                                    </div>
                            } */}
          <div style={{ padding: 10 }}>
            <Card style={{ padding: 10 }}>
              <div style={{ padding: 10 }}>
                <CardTitle style={{ margin: 0 }}>
                  Chapter No : {this.state.getRequestFor}
                </CardTitle>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  flexWrap: "wrap"
                }}
              >
                {this.state[this.state.getRequestFor].map((slock, index) => {
                  return (
                    <React.Fragment key={index}>
                      <FormGroup
                        style={{ minWidth: 200, width: "20%", padding: 5 }}
                        key={index}
                        check
                        inline
                      >
                        <Card style={{ padding: "5px 20px", width: "100%" }}>
                          <Label
                            check
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Input
                              type="checkbox"
                              onClick={e => {
                                this.handleClickSlok(e, slock);
                              }}
                            />
                            <div style={{ float: "left", marginLeft: 20 }}>
                              <CardTitle style={{ margin: 0 }}>
                                {"Slok No : "}
                                {slock.ShlokNo}
                              </CardTitle>
                            </div>
                          </Label>
                        </Card>
                      </FormGroup>
                    </React.Fragment>
                  );
                })}
              </div>
            </Card>
          </div>
        </Card>
      );
    } else {
      return (
        <div
          style={{
            margin: "15px 0px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // togle function

  handleClickDropDwon = () => {
    this.setState({
      isRating: !this.state.isRating
    });
  };

  handleChangeDate = e => {
    this.setState({
      DateOfSubmissionOnTelegram: e.target.value
    });
  };

  handleCloseSlokList = () => {
    this.setState({
      showSlock: false
    });
  };

  handleClickShowSlock = () => {
    this.setState({
      showSlock: true
    });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  // set shlock and chaper's

  handleClickChapter = (e, chapter) => {
    if (e.target.checked) {
      this.setState({
        getRequestFor: chapter.ChapterNO,
        [chapter.CSId]: e.target.checked
      });
      this.handleClickShowSlocksList(chapter.ChapterNO);
    } else {
      this.setState({
        getRequestFor: chapter.ChapterNO,
        [chapter.CSId]: e.target.checked
      });
    }
  };

  handleClickSlok = async (e, slock) => {
    let selectedSlocks = this.state.selectedSlocks;
    if (e.target.checked) {
      //console.log(JSON.stringify(slock));
      // await selectedSlocks.push(slock.Shalok)
      await selectedSlocks.push({
        CSId: slock.Shalok.CsId,
        ChapterNo: slock.Shalok.ChapterNo,
        ShlokNo: slock.ShlokNo
      });
    } else {
      let selectedData = await selectedSlocks.filter(
        item => item.CSId != slock.Shalok.CsId
      );
      selectedSlocks = selectedData;
    }
    this.setState({
      selectedSlocks: selectedSlocks
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // server request

  setupComponent = async () => {
    this.setState({
      loading: true
    });
    let val_obj = {
      url: `ApprovedShloks/GetChapterWiseShloksNonAttemptedCount?RegNo=${this.state.contestentID}`,
      data: "",
      request_type: "GET"
    };

    // console.log('Approved Slock ', val_obj)
    let response = await requestServerData(val_obj, "admin");
    // console.log('Approved Slock ', response)
    if (response.response) {
      this.setState({
        chapterList: response.data.data.result.ChapterWiseShaloks,
        loading: false,
        totalShlok: response.data.data.result.TotalShalokCount,
        shlok: response.data.data.result.TotalNonAttemptedShalok,
        DateOfSubmissionOnTelegram: this.formatDate(new Date())
      });
    } else {
      toast.error("Network error");
    }
  };

  handleClickShowSlocksList = async chapterNum => {
    this.setState({
      loading: true,
      today: this.formatDate(new Date())
    });

    let val_obj = {
      url: `ApprovedShloks/GetNonAttemptedShloksByRegistrationCodeChapterNO?RegNo=${this.state.contestentID}&ChapterNO=${chapterNum}`,
      data: "",
      request_type: "GET"
    };

    //e.log('Approved Slock ', val_obj)
    let response = await requestServerData(val_obj, "admin");
    //console.log('shlock list ', response)

    if (response.response) {
      this.setState({
        loading: false,
        [chapterNum]: response.data.data.result.NonAttemptedShaloks,
        showSlock: true
      });
    } else {
      toast.error("Network error");
    }
  };

  handleSubmitSlock = async () => {
    if (!this.state.rating) {
      toast.error("Please Select rating");
      return false;
    }
    if (this.state.selectedSlocks.length == 0) {
      toast.error("Please Select atleast one shlok");
      return false;
    }
    if (this.state.DateOfSubmissionOnTelegram) {
      this.setState({ loading: true });
      let value = {
        RegistrationCode: this.state.contestentID,
        ShlokArry: this.state.selectedSlocks,
        DateOfSubmissionOnTelegram: this.state.DateOfSubmissionOnTelegram,
        EvaluatedBy: 1,
        Rating: this.state.rating
      };

      let val_obj = {
        url: `ApprovedShloks/ApprovedShloks`,
        data: value,
        request_type: "POST"
      };
      // console.log('Approved Slock To server', JSON.stringify(value))
      let response = await requestServerData(val_obj, "admin");
      // console.log("response11", response)

      // setTimeout(function(){
      //  }, 5000)
      if (response.response) {
        toast.success(response.data.data.result);

        this.setState({
          loading: false,
          submitStatus: response.data.data,
          ShlokArry: [],
          DateOfSubmissionOnTelegram: "",
          selectedSlocks: []
        });

        this.setupComponent();
      } else {
        toast.error("Try Again after some time . . .");
      }
    } else {
      toast.error("Please Select Date First");
    }
  };

  handleChange = e => {
    let { value: contestentID = "" } = e.target || {};
    this.setState({ contestentID });
  };

  handleEnterSubmit = async event => {
    if (event.keyCode === 13) {
      this.handleSubmitUserId();
    }
  };

  handleSubmitUserId = async () => {
    this.setState({
      loading: true
    });
    /*getting the name of filtered user*/
    let userName = null;
    let user_detial_obj = {
      url: `Contestent/GetContestentByRegNo?RegNum=${this.state.contestentID}`,
      data: "",
      request_type: "GET"
    };
    let user_detial_response = await requestServerData(
      user_detial_obj,
      "admin"
    );
    if (user_detial_response.response) {
      userName = user_detial_response.data.data.result.ContestantName;
    } else {
      toast.error("Network error");
      return void 0;
    }

    /*getting the req use shlok*/

    let val_obj = {
      url: `ApprovedShloks/GetChapterWiseShloksNonAttemptedCount?RegNo=${this.state.contestentID}`,
      data: "",
      request_type: "GET"
    };

    // console.log('Approved Slock ', val_obj)
    let response = await requestServerData(val_obj, "admin");
    // console.log('Approved Slock ', response)
    if (response.response) {
      this.setState({
        chapterList: response.data.data.result.ChapterWiseShaloks,
        loading: false,
        totalShlok: response.data.data.result.TotalShalokCount,
        shlok: response.data.data.result.TotalNonAttemptedShalok,
        DateOfSubmissionOnTelegram: this.formatDate(new Date()),
        userName
      });
    } else {
      toast.error("Network error");
    }
  };
}

export default withRouter(NewapprovedSlok);

const ratingType = ["Good", "Average", "Excellent"];
