import React from "react";

const UserWallet = props => (
  <svg {...props}>
  <path d="M390.6 39H88.3C65.5 39.1 47 57.6 47 80.5v.5h-5.6C18.6 81 .1 99.4 0 122.2v229.3c.1 22.8 18.5 41.4 41.4 41.5h302.3c22.8-.1 41.3-18.6 41.3-41.5v-.5h5.6c22.8 0 41.3-18.4 41.4-41.2V80.5c-.1-22.9-18.5-41.4-41.4-41.5zm-46.9 334H41.4c-11.8-.1-21.3-9.7-21.4-21.5V122.2c.1-11.7 9.6-21.2 21.4-21.2h302.3c11.7 0 21.3 9.4 21.3 21.2v57.2c-1.8.4-3.6.6-5.3.6H302c-31.4 0-56.9 25.5-56.9 56.9s25.5 56.9 56.9 57h57.6c1.8-.1 3.6-.3 5.3-.5l.1 58.1c0 11.8-9.5 21.4-21.3 21.5zm68.3-63.2c-.1 11.7-9.6 21.2-21.4 21.2H385v-44.8c4.3-2.9 8.2-6.5 11.5-10.5l15.5-20v54.1zm-31.3-46.2c-5 6.5-12.8 10.3-21 10.4H302c-20.4-.1-36.9-16.6-36.9-37s16.5-36.9 36.9-37h57.6c6.9 0 13.7-1.5 19.9-4.5 6.6-3.1 12.4-7.8 16.9-13.6l15.5-20 .1 61.1-31.3 40.6zM412 129.2L385 164v-41.8c0-22.8-18.5-41.2-41.3-41.2H67v-.5c0-11.8 9.5-21.4 21.3-21.5h302.3c11.8.1 21.3 9.7 21.4 21.5v48.7z" />
  <path d="M324.4 227h-24c-5.5 0-10 4.5-10 10s4.5 10 10 10h24c5.5 0 10-4.5 10-10s-4.5-10-10-10z" />
</svg>
);

UserWallet.defaultProps = {
    width: 48,
    height: 48,
    viewBox: "0 0 432 432"
};

export default UserWallet;
