import * as React from "react"

function SvgComponentChangepassword(props) {
  return (
    <svg height={512} viewBox="0 0 512.008 512.008" width={512} {...props}>
      <path d="M299.305 381.008c-2.762-4.783-8.878-6.422-13.66-3.66l-19.641 11.34v-22.68c0-5.523-4.478-10-10-10s-10 4.477-10 10v22.68l-19.641-11.34c-4.785-2.761-10.898-1.123-13.66 3.66s-1.123 10.899 3.66 13.66l19.641 11.34-19.641 11.34c-4.783 2.761-6.422 8.877-3.66 13.66a9.996 9.996 0 0013.66 3.66l19.641-11.34v22.68c0 5.523 4.478 10 10 10s10-4.477 10-10v-22.68l19.641 11.34a9.997 9.997 0 0013.66-3.66c2.762-4.783 1.123-10.899-3.66-13.66l-19.641-11.34 19.641-11.34c4.783-2.761 6.421-8.877 3.66-13.66zM162.305 381.008c-2.761-4.783-8.877-6.422-13.66-3.66l-19.641 11.34v-22.68c0-5.523-4.478-10-10-10s-10 4.477-10 10v22.68l-19.641-11.34a10 10 0 00-13.66 3.66c-2.762 4.783-1.123 10.899 3.66 13.66l19.641 11.34-19.641 11.34c-4.783 2.761-6.422 8.877-3.66 13.66a9.996 9.996 0 0013.66 3.66l19.641-11.34v22.68c0 5.523 4.478 10 10 10s10-4.477 10-10v-22.68l19.641 11.34a9.997 9.997 0 0013.66-3.66c2.762-4.783 1.123-10.899-3.66-13.66l-19.641-11.34 19.641-11.34c4.783-2.761 6.421-8.877 3.66-13.66zM349.703 431.008a9.996 9.996 0 0013.66 3.66l19.641-11.34v22.68c0 5.523 4.478 10 10 10s10-4.477 10-10v-22.68l19.641 11.34a9.997 9.997 0 0013.66-3.66c2.762-4.783 1.123-10.899-3.66-13.66l-19.641-11.34 19.641-11.34c4.783-2.761 6.422-8.877 3.66-13.66-2.761-4.783-8.877-6.422-13.66-3.66l-19.641 11.34v-22.68c0-5.523-4.478-10-10-10s-10 4.477-10 10v22.68l-19.641-11.34c-4.785-2.761-10.898-1.123-13.66 3.66s-1.123 10.899 3.66 13.66l19.641 11.34-19.641 11.34c-4.783 2.761-6.422 8.877-3.66 13.66z" />
      <path d="M462.004 300.008H301.067c-5.522 0-10 4.477-10 10s4.478 10 10 10h160.937c16.542 0 30 13.458 30 30v112c0 16.542-13.458 30-30 30h-412c-16.542 0-30-13.458-30-30v-112c0-16.542 13.458-30 30-30h161.063c5.522 0 10-4.477 10-10s-4.478-10-10-10H50.004c-27.57 0-50 22.43-50 50v112c0 27.57 22.43 50 50 50h412c27.57 0 50-22.43 50-50v-112c0-27.57-22.43-50-50-50zM256.004 213.008c5.522 0 10-4.477 10-10v-42.725c0-5.523-4.478-10-10-10s-10 4.477-10 10v42.725c0 5.523 4.477 10 10 10z" />
      <path d="M184.004 276.008h144c23.159 0 42-18.841 42-42V129.283c0-23.159-18.841-42-42-42h-2V70c0-38.598-31.402-70-70-70s-70 31.402-70 70v17.283h-2c-23.159 0-42 18.841-42 42v104.725c0 23.159 18.841 42 42 42zm22-206.008c0-27.57 22.43-50 50-50s50 22.43 50 50v17.283h-100zm-44 59.283c0-12.131 9.869-22 22-22h144c12.131 0 22 9.869 22 22v104.725c0 12.131-9.869 22-22 22h-144c-12.131 0-22-9.869-22-22zM256.063 320.074c2.63 0 5.21-1.07 7.07-2.93 1.87-1.86 2.93-4.44 2.93-7.07 0-2.64-1.06-5.21-2.93-7.07-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.069 2.93a10.059 10.059 0 00-2.931 7.07c0 2.63 1.07 5.21 2.931 7.07 1.86 1.86 4.44 2.93 7.069 2.93z" />
    </svg>
  )
}

export default SvgComponentChangepassword