import * as React from "react"

function SvgComponentBooks(props) {
  return (
    <svg viewBox="0 0 64 64" width={512} height={512} {...props}>
      <path d="M61 16a1 1 0 00-1 1v36h-5.43a85.082 85.082 0 00-19.12 2.178 1.992 1.992 0 01-2.1-.834A22.732 22.732 0 0145.211 51H57a1 1 0 001-1v-7a1 1 0 00-2 0v6H45.211A24.728 24.728 0 0033 52.212V25a1 1 0 00-2 0v27.211A24.724 24.724 0 0018.789 49H8V13h15.4a7.605 7.605 0 017.6 7.605 1 1 0 102 0A7.605 7.605 0 0140.6 13H56v26a1 1 0 002 0V12a1 1 0 00-1-1H40.6a9.613 9.613 0 00-8.6 5.332A9.613 9.613 0 0023.4 11H7a1 1 0 00-1 1v38a1 1 0 001 1h11.789a22.732 22.732 0 0111.863 3.344 2 2 0 01-2.1.834A85.069 85.069 0 009.43 53H4V17a1 1 0 00-2 0v37a1 1 0 001 1h6.43a83.117 83.117 0 0118.67 2.127 3.98 3.98 0 003.9-1.268 3.978 3.978 0 003.9 1.268A83.145 83.145 0 0154.57 55H61a1 1 0 001-1V17a1 1 0 00-1-1z" />
      <path d="M28.723 19.613A9.579 9.579 0 0023.4 18H12a1 1 0 000 2h11.4a7.576 7.576 0 014.218 1.277 1 1 0 101.11-1.664zM52 18H40.6a9.581 9.581 0 00-5.328 1.613 1 1 0 001.11 1.664A7.581 7.581 0 0140.6 20H52a1 1 0 000-2zM28.723 24.613A9.579 9.579 0 0023.4 23H12a1 1 0 000 2h11.4a7.576 7.576 0 014.218 1.277 1 1 0 101.11-1.664zM52 23H40.6a9.581 9.581 0 00-5.328 1.613 1 1 0 001.11 1.664A7.581 7.581 0 0140.6 25H52a1 1 0 000-2zM28.723 29.613A9.579 9.579 0 0023.4 28H12a1 1 0 000 2h11.4a7.576 7.576 0 014.218 1.277 1 1 0 101.11-1.664zM52 28H40.6a9.581 9.581 0 00-5.328 1.613 1 1 0 001.11 1.664A7.581 7.581 0 0140.6 30H52a1 1 0 000-2zM28.723 34.613A9.579 9.579 0 0023.4 33H12a1 1 0 000 2h11.4a7.576 7.576 0 014.218 1.277 1 1 0 101.11-1.664zM52 33H40.6a9.581 9.581 0 00-5.328 1.613 1 1 0 001.11 1.664A7.581 7.581 0 0140.6 35H52a1 1 0 000-2zM28.723 39.613A9.579 9.579 0 0023.4 38H12a1 1 0 000 2h11.4a7.576 7.576 0 014.218 1.277 1 1 0 101.11-1.664zM52 38H40.6a9.581 9.581 0 00-5.328 1.613 1 1 0 001.11 1.664A7.581 7.581 0 0140.6 40H52a1 1 0 000-2zM23.4 43H12a1 1 0 000 2h11.4a7.576 7.576 0 014.218 1.277 1 1 0 101.11-1.664A9.579 9.579 0 0023.4 43zM52 43H40.6a9.581 9.581 0 00-5.328 1.613 1 1 0 001.11 1.664A7.581 7.581 0 0140.6 45H52a1 1 0 000-2z" />
    </svg>
  )
}

export default SvgComponentBooks