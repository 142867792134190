import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import SideBar from './SideBar';
import {
  Card,
  Button,
  Input,
  Spinner,
  Label,
  Modal,
  DropdownButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody
} from "reactstrap";
import { requestServerData } from "../Utils/Config";
import { ToastContainer, toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";

class AddUserTypeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user_type_name: null,
      mandatory: null,
      UserTypes: [],
      listLoading: true,

      // all update filters
      updateLoading: false,
      UserTypeId: null,
      update_user_type_name: null,
      isModalActive: false,
      removeLoading: false
    };
  }

  handleOnChange = e => {
    let { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async () => {
    this.setState({
      loading: true
    });
    const { user_type_name } = this.state;
    if (!user_type_name) {
      toast.error(`User type Name is mandatory`);
    } else {
      let val_obj = {
        data: {
          UserTypeName: user_type_name
        },
        request_type: "POST",
        url: "UserType/InsertUserType"
      };

      let response = await requestServerData(val_obj, "admin");
      if (response.response) {
        toast.success("Added Successfully");
        this.setState({
          user_type_name: "",
          mandatory: null
        });
      } else {
        toast.error("Please try again");
      }
    }
    this.getUserType();
    this.setState({
      loading: false
    });
  };

  componentDidMount() {
    this.getUserType();
  }

  assembleUserType = UserType => {
    let posts =
      UserType &&
      UserType.result.map(data => {
        return {
          UserTypeName: data.UserTypeName || "",
          Edit: (
            <div className="action-icons">
              <Button
                color="primary mr-2 "
                onClick={() => this.handleClick("edit", data)}
              >
                Edit
              </Button>
            </div>
          ),
          Remove: data.IsActive ? (
            <div className="action-icons">
              <Button
                color="success  mr-2"
                class="btn btn-outline-danger"
                onClick={() => this.handleClick("remove", data)}
              >
                {this.state.removeLoading && <Spinner style={{ width: "3rem", height: "3rem" }} /> || "Mark Inactive" }
              </Button>
            </div>
          ) : (
            <div className="action-icons">
              <Button
                color="danger mr-2 "
                class="btn btn-outline-danger"
                onClick={() => this.handleClick("remove", data)}
              >
                {this.state.removeLoading && <Spinner  style={{ width: "3rem", height: "3rem" }} /> || "Mark Active" }
              </Button>
            </div>
          )
        };
      });

    return posts;
  };

  getUserType = async () => {
    this.setState({
      listLoading:true
    });
    let val_obj = {
      url: `UserType/GetUserType`,
      data: {},
      request_type: "GET"
    };
    let response = await requestServerData(val_obj, "admin");
    if (response.response) {
      const data = {
        columns: [
          { label: "Name", field: "UserTypeName" },
          { label: "Edit", field: "Edit" },
          { label: "Status", field: "Remove" }
        ],
        rows: this.assembleUserType(response.data.data)
      };
      this.setState({
        UserTypes: data,
        listLoading: false
      });

    } else {
      alert("try again . . .");
    }
  };

  updateUserType = async () => {
    this.setState({
      updateLoading: true
    });
    let val_obj = {
      url: `UserType/UpdateUserType`,
      data: {
        UserTypeId: this.state.UserTypeId,
        UserTypeName: this.state.update_user_type_name
      },
      request_type: "POST"
    };
    let response = await requestServerData(val_obj, "admin");
    if (response.response) {
      this.getUserType();
    } else {
      alert("try again . . .");
    }
    this.setState({
      updateLoading: true,
      isModalActive: false
    });
  };

  removeUserType = async data => {
    if (this.state.removeLoading) return void 0;
    this.setState({
      removeLoading: true
    });
    let val_obj = {
      url: `UserType/DeleteUserType`,
      data: {
        UserTypeId: data.UserITypeId
      },
      request_type: "POST"
    };
    let response = await requestServerData(val_obj, "admin");
    if (response.response) {
    } else {
      alert("try again . . .");
    }
    this.getUserType();

    this.setState({
      removeLoading: false
    });
  };

  handleClick = (key, data = {}) => {
    if (this.state.updateLoading) return void 0;
    switch (key) {
      case "edit":
        this.setState({
          UserTypeId: data.UserITypeId,
          update_user_type_name: data.UserTypeName,
          isModalActive: true
        });
        break;
      case "update":
        this.updateUserType(data);
        break;
      case "remove":
        this.removeUserType(data);
        break;
      case "close_modal":
        this.setState({
          UserTypeId: null,
          isModalActive: false
        });
        break;
      default:
        break;
    }
  };

  render() {
    let {
      user_type_name,
      loading,
      listLoading,
      UserTypes,
      isModalActive,
      update_user_type_name,
      updateLoading
    } = this.state;
    return (
      <div className={"top-main"} style={{ height: window.innerHeight }}>
        <div style={{ paddingTop: "50px", margin: "auto", maxWidth: "500px" }}>
          <Card style={{ padding: 20 }}>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
            <h2>Add User Type</h2>
            <div class="form-group">
              {/* <label class>User Type Name*</label> */}
              <input
                name="user_type_name"
                id="user_type_name"
                placeholder="User Type Name"
                class="form-control"
                type="text"
                value={user_type_name}
                onChange={this.handleOnChange}
              />
            </div>

            {loading ? (
              <button
                type="submit"
                class="btn btn-secondary"
                style={{
                  marginTop: "12px",
                  width: "100%",
                  backgroundColor: "#9932CC"
                }}
              >
                <Spinner />
              </button>
            ) : (
              <button
                type="submit"
                class="btn btn-secondary"
                style={{
                  marginTop: "12px",
                  width: "100%",
                  backgroundColor: "#9932CC"
                }}
                onClick={() => this.handleSubmit()}
              >
                Submit
              </button>
            )}
          </Card>
        </div>
        <div
          style={{
            marginTop: 50,
            marginBottom: 50
          }}
        >
          <Card>
            <CardBody>
              {listLoading ? (
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Spinner />
                </div>
              ) : (
                <div className="table-responsive">
                  <MDBDataTable
                    id={"userTypeList"}
                    striped
                    bordered
                    entriesOptions={[10, 50, 100, 1000]}
                    hover
                    width={50}
                    data={UserTypes}
                  />
                </div>
              )}
            </CardBody>
          </Card>
          <Modal
            isOpen={isModalActive}
            toggle={() => this.handleClick("close_modal")}
          >
            <ModalHeader>Edit Contestant</ModalHeader>
            <ModalBody>
              <div class="form-group">
                {/* <label class>User Type Name*</label> */}
                <input
                  name="update_user_type_name"
                  id="update_user_type_name"
                  placeholder="User Type Name"
                  class="form-control"
                  type="text"
                  value={update_user_type_name}
                  onChange={this.handleOnChange}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  this.handleClick("update");
                }}
              >
                {updateLoading ? <Spinner /> : "Update"}
              </Button>
              <Button
                color="secondary"
                style={{
                  opacity: updateLoading ? ".5" : "1"
                }}
                onClick={() => {
                  this.handleClick("close_modal");
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(AddUserTypeForm);
