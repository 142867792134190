import React, { Component } from "react";
import { Badge, Row, Col, Card, CardBody, Button, Modal,TableColumnVisibility, ModalHeader, ModalFooter, Spinner,Input, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import AddContestent from '../Admin/AddContestent'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactusReply from "./ContactusReply";
const date = new Date();
class ContactUsEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start: this.formatDate(date),
            end: this.formatDate(date),
           
          
        };
    }
    
    componentDidMount() {
        

        this.getContactUsData(this.state.start,this.state.end, true)
        // this.getContactUsDataResponse(this.state.today,this.state.today, true)
     
    }

    handleChangeDate = (e) => {

        if(e.target.name==='start')
        {
            this.setState({           
                start: e.target.value  
            })
        }
        if(e.target.name==='end')
        {
            this.setState({                  
                end: e.target.value                  
            })
        }
       
    }
    // componentDidMount() {

    //     this.handleGetDataForTable()
    // }
    donwloadExcel = () => {

        if (this.state.ContactUs && this.state.ContactUs.length > 0) {

        }
    }
    formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    render() {

        if (this.state.loading) {

            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <div >
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            )
        }

        return (
           
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer
                        position="top-center"
                    />
                   
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">ContactUs Details</h3>
                                    <hr />
                                    <Row className="align-items-center">
                                    <Col>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 20,flexWrap:"wrap" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center',flexDirection:"row",flexWrap:"wrap" }}>

                                                <div style={{ marginRight: 20,whiteSpace:"nowrap",width:window.innerWidth < 600 ? "100%" :"none" }}>
                                                    Select Date
                                                </div>
                                            <div>
                                            <div> Start:</div>
                                            <Input value={this.state.start} type="date" name={'start'} onChange={e => this.handleChangeDate(e)} />
                                            </div>
                                        <div>
                                        <div>
                                                <div> End:</div>
                                                <Input value={this.state.end} type="date" name={'end'} onChange={e => this.handleChangeDate(e)} />
                                                </div>
                                        </div>
                                        <div>
                                            <Button color={'primary'} onClick={() => this.getContactUsData(this.state.start,this.state.end,true)}>Requested </Button>
                                        </div>
                                            
                                        <div>
                                            <Button color={'success'} onClick={() => this.getContactUsData(this.state.start,this.state.end,false)}>Responded</Button>
                                        </div>
                       
                                        <div>
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="download-table-xls-button"
                                                table="ContactUs"
                                                filename={`${this.state.dateForGetTableData}ContactUsData`}
                                                sheet={`${this.state.dateForGetTableData}ContactUsData`}
                                                buttonText="Download as XLS" />
                                        </div>
                                        </div>
                                        </div>
                                    </Col>
                                    </Row>
                              
                                </div>
                            </div>
                        </Col>
                    </Row>
                  
                    <br />

                    <Row className="mb-4">
                        <Col md="12">
                            <Card>
                                <CardBody>
                                <div className="table-responsive">
                                    <MDBDataTable
                                        id={'ContactUs'}
                                        paging={true}
                                        // display={hidden}
                                      
                                        striped
                                        bordered
                                        entriesOptions={[10, 50, 100, 1000]}
                                        hover
                                        width={50}
                                        data={this.state.ContactUs}
                                     
                                    />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={this.state.modalIsActive} toggle={() => this.handleClick('close_modal')}>
                    <ModalHeader>Contact Us Response </ModalHeader>
                    <ModalBody>
                        {/* <EditContestent/> */}
                        <ContactusReply  Email={this.state.Email} ContactNumber={this.state.ContactNumber} ContactId={this.state.ContactId} handleClose={this.handleClick} />
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color="primary" onClick={() => { this.handleClick('edit_contestent')}}>Edit Contestent</Button>{' '} */}

                        <Button color="secondary" onClick={() => { this.handleClick('close_modal') }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
          
        );
    }

    assemblePosts = (ContactUs,Status) => {
       
            let st=Status
        let posts = ContactUs && ContactUs.result.map((post) => {
           
                if( st==true)
                {
            return (                              
                {
                ContactId:post.ContactId,   
                Date:post.CreatedDate.split('T')[0],
                 Name: post.Name,
                 RegistrationCode: post.RegistrationCode,
                 ContactNumber: post.ContactNumber,
                 Feedback: post.Feedback,               
                 Email: post.Email, 
                Action: <div className="action-icons">
                     <Button color="primary mr-2 " onClick={() => this.handleClick('edit', post)}   >Reply</Button>
                 </div>,
              
                }
            )
                }
                else
                {

                    return (                              
                        {
                        Date:post.CreatedDate.split('T')[0],
                         Name: post.Name,                        
                         Feedback: post.Feedback,
                         FeedbackResponce: post.FeedbackResponce                       
                      
                        }
                    )
                }

        });

        return posts;
    }

    handleClick = (key, data) => {

        switch (key) {
           
                case 'edit':                   
         this.setState({ 
                        ContactId:data.ContactId,
                        Email:data.Email,
                        ContactNumber:data.RegistrationCode
                    })
                    this.setState({
                        modalIsActive: true
                    })
                    //  this.props.history.push(`/edit-contestent/${data.RegistrationCode}`)
                    break;
                   
                    case 'close_modal':
        
                        this.setState({
                            modalIsActive: false
                        })
                        // this.getContactUsData(this.state.today,this.state.today, true)
                        this.getContactUsData(this.state.start,this.state.end, true)
                        break;
                        
        
        }
    }


    getContactUsData = async (date1,date2,type) => {

        this.setState({
            loading: true
        })
        let  postdata = {            
            "Status": type,
            "FirstDate": date1,
            "SecondDate": date2
        }
        let val_obj = {
            url: 'ContactUs/GetContactUs',
            data: postdata,
            request_type: 'POST'
        }
        let response = await requestServerData(val_obj, 'admin')

        console.log('contact request', response.data.data.result)
        if (response.response) {
         
            if(type==true)
            {
            const data = {
                columns: [
                    {label:'Sr NO',field:'ContactId'},
                    { label: 'Date', field: 'Date', },                 
                    { label: 'Name', field: 'Name', },
                    { label: 'Reg Code', field: 'RegistrationCode', },
                    { label: 'Contact', field: 'ContactNumber', },
                    { label: 'Feedback', field: 'Feedback', },                   
                    { label: 'Email ',  field: 'Email', },  
                    { label: 'Action', field: 'Action', },


                   ],

                rows: this.assemblePosts(response.data.data,true),
            }
            this.setState({ ContactUs: data, ContactUsCount: response.data.data.result.length })
       
            } 
            
            else
            {
                
                const data = {
                    columns: [
                        { label: 'Date', field: 'Date', },                     
                        { label: 'Name', field: 'Name', },                      
                        { label: 'Feedback', field: 'Feedback', },                      
                        { label: 'FeedbackResponce ',  field: 'FeedbackResponce', }  
                       ],
    
                    rows: this.assemblePosts(response.data.data,false),
                }
                this.setState({ ContactUs: data, ContactUsCount: response.data.data.result.length })
               
            }

        } else {

            alert('server error')
            this.props.history.push('/')
        }
        this.setState({ loading: false })
    }

    

}
export default withRouter(ContactUsEntry);