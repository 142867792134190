import React, { Component } from "react";
import { Row, Col, Card,CardHeader, CardBody, Button,Tabs, Modal, ModalHeader,TabContent,TabPane, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const date = new Date();

class ContestentPayout extends Component {
    constructor(props) {
        super(props);
        this.editContestent = React.createRef();
        this.state = {
            totalContestnentPayout: "",
            TotalShlokCount: "",
            TotalAmountPaid: "",
            // today :this.formatDate(date)
            start: this.formatDate(date),
            end: this.formatDate(date),
            Status:true
        };
    }
    componentDidMount() {

        this.getContestentPayouts(this.state.start,this.state.end, 'UnPaid')
    }

    formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    render() {
        // console.log(this.state.today)

        if (this.state.loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <div >
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            )
        }

        return (
      
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                   
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">Payment Request</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 20,flexWrap:"wrap" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center',flexDirection:"row",flexWrap:"wrap" }}>
                            <div style={{ marginRight: 20,whiteSpace:"nowrap",width:window.innerWidth < 600 ? "100%" :"none" }}>
                              
                            </div>
                            <div
                            >
                            <div>Start:</div><Input value={this.state.start} type="date" name={'start'} onChange={e => this.handleChangeDate(e)} />
                            </div>
                            <div
                            >
                            <div>End:</div><Input value={this.state.end} type="date" name={'end'} onChange={e => this.handleChangeDate(e)} />
                            </div>
                                {/* <Input value={this.state.today} type="date" name={'date'} onChange={e => this.handleChangeDate(e)} /> */}
                            {/* <Input value={this.state.today} type="date" name={'date'} onChange={e => this.handleChangeDate(e)} /> */}
                        </div>
                        <div>
                            <Button color={'primary'} onClick={() => this.getContestentPayouts(this.state.start,this.state.end,'UnPaid')}>Payout Due</Button>
                        </div>
                        
                        <div>
                            <Button color={'success'} onClick={() => this.getContestentPayouts(this.state.start,this.state.end,'Paid')}>Payout Done</Button>
                        </div>
                        {
                            this.state.ContestnentPayout && this.state.ContestnentPayout.length > 0 &&
                            <div>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="contestentPayouts"
                                    filename={`${this.state.dateForGetTableData}contestentPayoutsData`}
                                    sheet={`${this.state.dateForGetTableData}contestentPayoutsData`}
                                    buttonText="Download as XLS" 
                                    background="#d49364"
                                    backgroundColor="red"/>
                            </div>
                        }
                    </div>
                    {  this.state.Status==true &&
                    <Row className="mb-4" >
                        <Col md="12">
                                                        <Card>
                 
                            <CardHeader  style={{ backgroundColor:'#4285f4' ,color:'white'}} tag="h3">Pending Payout</CardHeader>
                                <CardBody >
                                    Total Achiever : <b>{this.state.totalContestnentPayout}</b>
                                    {/* Total Shlok Count : { this.state.TotalShlokCount} */}
                                   &nbsp;&nbsp;&nbsp;&nbsp; Total Amount : <b>{ this.state.TotalAmountPaid}</b>
                                   <div className="table-responsive">
                                    <MDBDataTable
                                        id={'contestentPayouts'}
                                        paging={false}
                                        striped
                                        bordered
                                        hover
                                        data={this.state.ContestnentPayoutsData}
                                    />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
    
                    
    }
    {   this.state.Status==false &&
         <Row className="mb-4" >
         <Col md="12">
                                         <Card>
  
             <CardHeader  style={{ backgroundColor:'#00c851' ,color:'white'}} tag="h3">Done Payout</CardHeader>
                 <CardBody >
                     Total Achiever : <b>{this.state.totalContestnentPayout}</b>
                     {/* Total Shlok Count : { this.state.TotalShlokCount} */}
                    &nbsp;&nbsp;&nbsp;&nbsp; Total Amount : <b>{ this.state.TotalAmountPaid}</b>
                    <div className="table-responsive">
                     <MDBDataTable
                         id={'contestentPayouts'}
                         paging={false}
                         striped
                         bordered
                         hover
                         data={this.state.ContestnentPayoutsData}
                     />
                     </div>
                 </CardBody>
             </Card>
         </Col>
     </Row>

    }
                </div>
            </div>
           
        );
    }

    handleChangeDate = (e) => {

        if(e.target.name==='start')
        {
            this.setState({           
                start: e.target.value  
            })
        }
        if(e.target.name==='end')
        {
            this.setState({                  
                end: e.target.value                  
            })
        }
       
    }

    donwloadExcel = () => {

        if (this.state.ContestnentPayout && this.state.ContestnentPayout.length > 0) {

        }
    }

    getContestentPayouts = async (date1,date2, type) => {
       

       if(type=="UnPaid")
       {
        this.setState({           
            Status:true
        })
       }
       else
       {
        this.setState({
           
            Status:false
        })
       }
              
        this.setState({
            loading: true,
           
        })

        let  postdata = {
            "RegCode": "",
            "PayStatus": type,
            "FirstDate": date1,
            "SecondDate": date2
        }
        let val_obj = {
            // url: `ApprovedShloks/GetApprovedShloksByDate?dt=${date}&Paystatus=${type}`,
            url: `ApprovedShloks/PaymentHistory`,
            data: postdata,
            request_type: 'POST'
        }
        let response = await requestServerData(val_obj, 'admin')
       // console.log('contestent payout list', response.data.data.result)

        if (response.response) {
            let totalAmount = 0            
            if(type=="UnPaid")
            {
                const data = {
                columns: [
                    // { label: 'ContestantId', field: 'ContestantId', },
                    // { label: 'Registration Date', field: 'RegistrationDate', },
                    { label: 'Req Date', field: 'PaymentRequestedDate', },
                    { label: 'PhId', field: 'PhId', },
                    { label: 'Reg No', field: 'RegistrationCode', },
                    { label: 'Name', field: 'ContestantName', },
                    { label: 'Mobile', field: 'MobileNo', },
                    // { label: 'Bank', field: 'BankName', },
                    { label: 'Ac\No', field: 'AccountNo', },
                    { label: 'IFSC', field: 'IFSCcode', },
                    { label: 'Benificiary Name', field: 'AccountHolderName', },
                    
                    { label: 'Upi', field: 'UpiId', },
                    // { label: 'Name In Upi ID', field: 'NameInUpi', },
                    { label: 'UPI Status', field: 'UpiStatus', },             
                   
                    { label: 'Amount', field: 'TotalAmount', },
                    // { label: 'Paid Date', field: 'PaidDate', },
                 
                ],
                rows: this.assemblePosts(response.data.data,true),
            }
            response.data.data.result && response.data.data.result.map((item, index) => {
                if (item.TotalAmount > 0) {
                    totalAmount = totalAmount + item.TotalAmount
                }
            })
            this.setState({
                ContestnentPayoutsData: data,
                totalContestnentPayout: response.data.data.result.length,
                TotalAmountPaid: totalAmount,
                ContestnentPayout: response.data.data.result, loading: false
            })

        }
        else
        {

            const data = {
                columns: [
                    // { label: 'ContestantId', field: 'ContestantId', },
                    // { label: 'Registration Date', field: 'RegistrationDate', },
                    { label: 'Req Date', field: 'PaymentRequestedDate', },
                    { label: 'PhId', field: 'PhId', },
                    { label: 'Reg No', field: 'RegistrationCode', },
                    { label: 'Name', field: 'ContestantName', },
                    { label: 'Mobile No', field: 'MobileNo', },                   
                    { label: 'Ac\No', field: 'AccountNo', },
                    { label: 'IFSC', field: 'IFSCcode', },
                    { label: 'Benificiary Name', field: 'AccountHolderName', }, 
                    { label: 'Amount', field: 'TotalAmount', },
                    { label: 'Paid Date', field: 'PaidDate', },
                    { label: 'TransactionNo', field: 'TransactionId', },
                 
                ],
                rows: this.assemblePosts(response.data.data,false),

            }
            response.data.data.result && response.data.data.result.map((item, index) => {
                if (item.TotalAmount > 0) {
                    totalAmount = totalAmount + item.TotalAmount
                }
            })
            this.setState({
                ContestnentPayoutsData: data,
                totalContestnentPayout: response.data.data.result.length,
                TotalAmountPaid: totalAmount,
                ContestnentPayout: response.data.data.result, loading: false
            })

        }
           
       

            // toast.success("Success !")

        } else {
            this.setState({
                ContestnentPayoutsData: [],
                totalContestnentPayout: 0,
                TotalAmountPaid: 0,
                ContestnentPayout: [],
                 loading: false
            })
            toast.error("Error !")
            // this.props.history.push('/')
        }

        

    }

    assemblePosts = (ContestnentData,Status) => {
        let st=Status

        let posts = ContestnentData.result && ContestnentData.result.map((post) => {
            if( st==true)
            {
           
            return (
                
                {                    
                    PaymentRequestedDate: post.PaymentRequestedDate.split('T')[0],
                    PhId: post.PhId,
                    RegistrationCode: post.RegistrationCode,
                    ContestantName: post.ContestantName,
                    MobileNo: post.MobileNo,
                    // BankName: post.BankName,
                    AccountNo:post.AccountNo,
                    IFSCcode: post.IFSCcode,
                    AccountHolderName:post.AccountHolderName,
                    // NameInUpi: post.NameInUpi,
                    UpiId:post.UpiId,
                    UpiStatus: post.UpiStatus,
                                   
                  //  ShlokCount: post.ShlokCount,
                  TotalAmount: post.TotalAmount ,
                //   PaidDate: post.PaidDate?.split('T')[0]  
                    // OptOutRemuneration: <Button className="btn-sm" color={post.OptOutRemuneration === true ? 'success' : 'danger '} > {post.OptOutRemuneration === true ? "Need Remenaration" : 'No Remenaration'}  </Button>,
                    // Action: <div className="action-icons">

                    //     <Button color="primary mr-2 " onClick={() => this.handleClick('edit', post)} >Edit</Button>
                    //     <Button class="btn btn-outline-danger" onClick={() => this.handleClick('approved', post)} >Approved Shlok</Button>
                    // </div>
                }

            )
            }
            else
            {

                return (
                
                    {
                        // ContestantId: post.ContestantId,
                        // RegistrationDate: post.CreatedDate,
                        PaymentRequestedDate: post.PaymentRequestedDate.split('T')[0],
                        PhId: post.PhId,
                        RegistrationCode: post.RegistrationCode,
                        ContestantName: post.ContestantName,
                        MobileNo: post.MobileNo,
                        // BankName: post.BankName,
                        AccountNo:post.AccountNo,
                        IFSCcode: post.IFSCcode,
                        AccountHolderName:post.AccountHolderName,
                        // NameInUpi: post.NameInUpi,
                        // UpiId:post.UpiId,
                        // UpiStatus: post.UpiStatus,
                                       
                      //  ShlokCount: post.ShlokCount,
                      TotalAmount: post.TotalAmount ,
                      PaidDate: post.PaidDate?.split('T')[0]  ,
                      TransactionId: post.TransactionId
                        // OptOutRemuneration: <Button className="btn-sm" color={post.OptOutRemuneration === true ? 'success' : 'danger '} > {post.OptOutRemuneration === true ? "Need Remenaration" : 'No Remenaration'}  </Button>,
                        // Action: <div className="action-icons">
    
                        //     <Button color="primary mr-2 " onClick={() => this.handleClick('edit', post)} >Edit</Button>
                        //     <Button class="btn btn-outline-danger" onClick={() => this.handleClick('approved', post)} >Approved Shlok</Button>
                        // </div>
                    }
    
                )




            }

        });
 
        return posts;
    }

}

export default withRouter(ContestentPayout);