import React from "react";

const UserKyc = props => (
  <svg height="512pt" width="512pt" {...props}>
      <path d="M146 492c0 11.047-8.953 20-20 20H80c-44.113 0-80-35.887-80-80V80C0 35.887 35.887 0 80 0h245.89c44.11 0 80 35.887 80 80v119c0 11.047-8.956 20-20 20-11.046 0-20-8.953-20-20V80c0-22.055-17.945-40-40-40H80c-22.055 0-40 17.945-40 40v352c0 22.055 17.945 40 40 40h46c11.047 0 20 8.953 20 20zm179.887-352c0-11.047-8.953-20-20-20h-206c-11.043 0-20 8.953-20 20s8.957 20 20 20h206c11.047 0 20-8.953 20-20zM246 220c0-11.047-8.953-20-20-20H99.887c-11.043 0-20 8.953-20 20s8.957 20 20 20H226c11.047 0 20-8.953 20-20zM99.887 280c-11.043 0-20 8.953-20 20s8.957 20 20 20H183c11.047 0 20-8.953 20-20s-8.953-20-20-20zm348.922 217.121C441.113 506.578 429.758 512 417.652 512H222.348c-12.172 0-23.563-5.418-31.25-14.863-7.735-9.504-10.739-21.852-8.243-33.875 6.461-31.125 23.575-59.446 48.184-79.746a139.51 139.51 0 0130.422-19.086C249.359 350.684 242 332.672 242 312.965c0-43.012 34.988-78 78-78s78 34.988 78 78c0 19.707-7.36 37.719-19.46 51.465 10.858 5 21.1 11.394 30.42 19.086 24.118 19.894 41.032 47.492 47.782 77.879.156.527.29 1.058.403 1.601 2.507 12.094-.532 24.531-8.336 34.125zM282 312.965c0 20.953 17.047 38 38 38s38-17.047 38-38-17.047-38-38-38-38 17.047-38 38zm135.988 158.469a.139.139 0 01-.008-.04c-9.605-46.253-50.812-79.828-97.98-79.828s-88.379 33.575-97.98 79.829c-.09.449.218.593.218.597.008 0 .043.008.11.008h195.304c.012-.012.282-.215.336-.566zm0 0" />
    </svg>
);

UserKyc.defaultProps = {
    width: 48,
    height: 48,
    viewBox: "-27 0 512 512"
};

export default UserKyc;
