import React from "react";

const FacebookBox = props => (
  <svg width={48} height={48} {...props}>
    <path
      d="M6.111 1h35.778A5.111 5.111 0 0147 6.111v35.778A5.111 5.111 0 0141.889 47H6.111A5.111 5.111 0 011 41.889V6.111A5.111 5.111 0 016.111 1m33.222 5.111h-6.389A8.944 8.944 0 0024 15.056v6.389h-5.111v7.667H24V47h7.667V29.111h7.667v-7.667h-7.667v-5.111a2.556 2.556 0 012.556-2.556h5.111z"
      fill={props.fill}
    />
    <path fill="none" d="M0 0h48v48H0z" />
  </svg>
);

FacebookBox.defaultProps = {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48'
};

export default FacebookBox;
