import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Header from './Header'
import AddContestent from '../Admin/AddContestent'


import HomePage from './HomePage'
import UserLogin from './UserLogin'
import Donationform from './Donationform'
import Sidebar from '../Common/Sidebar'
import Dashboard from '../UsersComponents/Dashboard'
import UserDashboardMini from '../UsersComponents/UserDashboardMini'
import UserFeedback from '../UsersComponents/UserFeedback'

import ShlokList from '../UsersComponents/ShlokList'
import UserProfile from '../UsersComponents/UserProfile'
import ResetPassword from '../UsersComponents/ResetPassword'

import Payment from '../UsersComponents/Payment'
import Request from '../UsersComponents/Request'
import Rewards from '../UsersComponents/Rewards'
import ChangePassword from "../Common/ChangePassword";
import Wallet from '../UsersComponents/Wallet'
import KycUpdate from '../UsersComponents/KycUpdate'
import ProfilePicture from '../UsersComponents/ProfilePicture'

import BackArrow from '../Assets/Images/BackArrow'
import Terms from '../Common/Terms'
import Faq from '../Common/Faq'
import Aboutus from '../Common/Aboutus'
import ContactUs from '../Common/ContactUs'
import PageUnderConstruction from '../Common/PageUnderConstruction'




import { getFromSession } from '../Utils/SessionStorage'
// import UserDashboardMini from "../UsersComponents/UserDashboardMini";



class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			target_native_id: '',
			registrationSuccessId: ''
		};
	}

	componentDidMount() {

		let user = getFromSession('user')

		this.setState({
			isLoginUser: user.response,
			user:user.data
		})
		this.setupComponent()
	}

	componentDidUpdate(revProps, preState) {

		if (this.state.target_native_id !== preState.target_native_id) {

			this.setupComponent()
		}
	}

	render() {
		const { target_native_id, isLoginUser,user } = this.state
		let SidebarActive = true
		if (target_native_id === '' || target_native_id === 'register-user' || target_native_id === 'user-login' || target_native_id === 'terms'  || target_native_id === 'faq' || target_native_id === 'about-us'|| target_native_id === 'contactus' ) {
			SidebarActive = false
		}

		if (target_native_id === 'user-dashboard') {

			if (!isLoginUser) {
				this.renderBackForNotLogin()
			}
		}
		let mobile = window.innerWidth < 769

		if (mobile) {

			return (
				<div className={'top-main'}>
					<Header />
					{/* {
						isLoginUser &&
						<React.Fragment>
							{
								SidebarActive &&
								<Sidebar activeFor={'user'} />
							}
						</React.Fragment>
					} */}
					<div style={{ marginTop: 78 }}>
						{
							target_native_id === '' &&
							<div style={{ width: '100%', height: '100%', padding: 20 }}>
								{
									isLoginUser &&
									<React.Fragment>
										<BackArrow height={20} width={20} />
										<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>
											Go To Dashboard</a>
									</React.Fragment>
								}
								<HomePage />
							</div>
						}
						{
							target_native_id === 'register-user' &&
							<div style={{ padding: 20, margin: 'auto' }}>
								{
									isLoginUser &&
									<React.Fragment>
										<BackArrow height={20} width={20} />
										<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
									</React.Fragment>
								}
								<AddContestent />
							</div>
						}
						{
							target_native_id === 'donation-form' &&
							<div style={{ padding: 20, margin: 'auto' }}>
								{
									isLoginUser &&
									<React.Fragment>
										<BackArrow height={20} width={20} />
										<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
									</React.Fragment>
								}
								<Donationform />
							</div>
						}
						{
							target_native_id === 'user-login' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<UserLogin />
							</div>
						}
						{
							target_native_id === 'forget-password' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<ResetPassword />
							</div>
						}
						{
							target_native_id === 'terms' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								{
									isLoginUser &&
									<React.Fragment>
										<BackArrow height={20} width={20} />
										<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
									</React.Fragment>
								}
								<Terms />
							</div>
						}
							{
							target_native_id === 'faq' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								{
									isLoginUser &&
									<React.Fragment>
										<BackArrow height={20} width={20} />
										<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
									</React.Fragment>
								}
								<Faq />
							</div>
						}

{
							target_native_id === 'contactus' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								{
									isLoginUser &&
									<React.Fragment>
										<BackArrow height={20} width={20} />
										<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
									</React.Fragment>
								}
								<ContactUs />
							</div>
						}
							{
							target_native_id === 'about-us' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								{
									isLoginUser &&
									<React.Fragment>
										<BackArrow height={20} width={20} />
										<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
									</React.Fragment>
								}
								<Aboutus />
							</div>
						}

						{/* Protected page here*/}
						<div style={{ width: '100%', padding: 10 }}>
							{
								isLoginUser && target_native_id === 'user-dashboard' &&
								<Dashboard />
							}
							{
								isLoginUser && target_native_id === 'user-dashboard-mini' &&
								<UserDashboardMini />
							}
						




							{
								isLoginUser && (target_native_id === 'user-attempted-shlok' || target_native_id === 'user-non-attempted-shlok') &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<ShlokList />
								</React.Fragment>
							}
							{
								isLoginUser && target_native_id === 'user-profile' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<UserProfile />
								</React.Fragment>
							}
							{
								isLoginUser && target_native_id === 'user-payment' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<Payment />
								</React.Fragment>
							}
							{
								isLoginUser && target_native_id === 'user-wallet' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<Wallet />
								</React.Fragment>
							}
							{
								isLoginUser && target_native_id === 'user-request' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<Request />
								</React.Fragment>
							}
							{
								isLoginUser && target_native_id === 'user-rewards' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<Rewards />
								</React.Fragment>
							}
							{
								isLoginUser && target_native_id === 'user-change-password' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<ChangePassword />
								</React.Fragment>
							}

							{
								isLoginUser && target_native_id === 'user-kyc-update' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<KycUpdate />
								</React.Fragment>
							}
								{
								isLoginUser && target_native_id === 'profile-picture' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-dashboard') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<ProfilePicture />
								</React.Fragment>
							}
									{
								isLoginUser && target_native_id === 'user-feedback' &&
								<React.Fragment>
									{
										isLoginUser &&
										<React.Fragment>
											<BackArrow height={20} width={20} />
											<a style={{ color: '#4285f4' }} onClick={() => { this.props.history.push('/user-feedback') }}>Go To Dashboard</a>
										</React.Fragment>
									}
									<UserFeedback />
								</React.Fragment>
							}
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className={'top-main'}>
					<Header />
					{
						isLoginUser &&
						<React.Fragment>
							{
								SidebarActive &&
								<Sidebar activeFor={'user'} user={user}/>
							}
						</React.Fragment>
					}
					<div style={{ marginTop: 90 }}>
						{
							target_native_id === '' &&
							<div style={{ width: '100%', padding: 48 }}>
								<HomePage />
							</div>
						}
						{
							target_native_id === 'register-user' &&
							<div style={{ padding: 40, margin: 'auto', width: '50%' }}>
								<AddContestent />
							</div>
						}
						{
							target_native_id === 'donation-form' &&
							<div style={{ padding: 40, margin: 'auto', width: '50%' }}>
								<Donationform />
							</div>
						}
						{
							target_native_id === 'user-login' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<UserLogin />
							</div>
						}
						{
							target_native_id === 'forget-password' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<ResetPassword />
							</div>
						}
						{
							target_native_id === 'terms' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<Terms />
							</div>
						}
							{
							target_native_id === 'faq' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<Faq />
							</div>
						}
							{
							target_native_id === 'contactus' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<ContactUs />
							</div>
						}
						{
							target_native_id === 'about-us' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<Aboutus />
							</div>
						}
						{
							target_native_id === 'page-under-construction' &&
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<PageUnderConstruction />
							</div>
						}

						{/* Protected page here*/}
						<div style={{ width: '100%', paddingLeft: 200, paddingRight: 20 }}>
							{
								isLoginUser && target_native_id === 'user-dashboard' &&
								<Dashboard />
							}
							{
								isLoginUser && target_native_id === 'user-dashboard-mini' &&
								<UserDashboardMini />
							}
						
							{
								isLoginUser && (target_native_id === 'user-attempted-shlok' || target_native_id === 'user-non-attempted-shlok') &&
								<ShlokList />
							}
							{
								isLoginUser && target_native_id === 'user-profile' &&
								<div style={{ padding: 90 }}>
									<UserProfile />
								</div>
							}
							{
								isLoginUser && target_native_id === 'user-payment' &&
								<Payment />
							}
							{
								isLoginUser && target_native_id === 'user-wallet' &&
								<Wallet />
							}
							{
								isLoginUser && target_native_id === 'user-request' &&
								<Request />
							}
							{
								isLoginUser && target_native_id === 'user-rewards' &&
								<Rewards />
							}
							{
								isLoginUser && target_native_id === 'user-change-password' &&
								<ChangePassword />
							}
							{
								isLoginUser && target_native_id === 'user-kyc-update' &&
								<div style={{ padding: 90 }}>
									<KycUpdate />
								</div>
							}
								{
								isLoginUser && target_native_id === 'profile-picture' &&
								<div style={{ padding: 90 }}>
									<ProfilePicture />
								</div>
							}
								{
								isLoginUser && target_native_id === 'user-feedback' &&
								<div style={{ padding: 90 }}>
									<UserFeedback />
								</div>
							}
						</div>
					</div>
				</div>
			);
		}
	}

	setupComponent = () => {

		let current_path = window.location.pathname;
		let url_arr = current_path.split("/");
		let target = url_arr[1]
		this.setState({
			target_native_id: target
		})

	}

	renderBackForNotLogin = () => {

		this.props.history.push('/user-login')
	}

}

export default withRouter(Home);