import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import SideBar from './SideBar';
import {
  Card,
  Button,
  Input,
  Spinner,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { requestServerData } from "../Utils/Config";
import { ToastContainer, toast } from "react-toastify";
import { AllowOnlyNumber } from "../Validation/Validation";
import NativeDb from "../Utils/nativeDb.json";

class Donate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={"top-main"}
        style={{ 
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
         }}
      >
        <div>
          <ToastContainer position="top-center" />
          <div class="card" style={{ padding: "20px" }}>
            <div>
              <div class="Toastify"></div>
              <h2>Donation Form</h2>
              <div class="form-group">
                <label class>Donor name</label>
                <input
                  name="donor"
                  id="donor"
                  placeholder="Donor Name"
                  class="form-control"
                  type="text"
                  value={this.state.donor}
                  onChange={e => this.handleChange(e)}
                  required
                />
              </div>
              <div class="form-group">
                <label class>Mobile number</label>
                <input
                  name="mobile"
                  id="mobile"
                  placeholder="Your Mobile Number"
                  class="form-control"
                  type="text"
                  value={this.state.mobile}
                  onChange={e => this.handleChange(e)}
                  required
                />
              </div>
              <React.Fragment>
                <div
                  style={{ display: "flex", margin: "15px 0px" }}
                >
                  <div>
                    <Dropdown
                      isOpen={this.state.isState}
                      toggle={() => this.handleClickDropDwon("state")}
                    >
                      <DropdownToggle caret style={{ padding: 10 }}>
                        {this.state.selectedState &&
                        this.state.selectedState.StateName
                          ? this.state.selectedState.StateName
                          : "Select state"}
                      </DropdownToggle>
                      <DropdownMenu style={{ height: 200, overflow: "scroll" }}>
                        {NativeDb["states"].map((state, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => {
                                this.setState({ selectedState: state });
                              }}
                            >
                              {state.StateName}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <div>
                    <Dropdown
                      isOpen={this.state.isDistrict}
                      toggle={() => this.handleClickDropDwon("district")}
                    >
                      <DropdownToggle caret style={{ padding: 10 }}>
                        {this.state.selectedDistrict &&
                        this.state.selectedDistrict.DistrictName
                          ? this.state.selectedDistrict.DistrictName
                          : "Select District"}
                      </DropdownToggle>
                      <DropdownMenu style={{ height: 200, overflow: "scroll" }}>
                        {this.state.selectedState &&
                          NativeDb["cities"].map((city, index) => {
                            if (
                              city.StateId === this.state.selectedState.StateId
                            ) {
                              return (
                                <DropdownItem
                                  key={index}
                                  onClick={() => {
                                    this.setState({ selectedDistrict: city });
                                  }}
                                >
                                  {city.DistrictName}
                                </DropdownItem>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </React.Fragment>

              <div class="form-group">
                <label class>Address</label>
                <input
                  name="address"
                  id="ifsc"
                  placeholder="Your Address Here"
                  class="form-control"
                  type="text"
                  value={this.state.address}
                  onChange={e => this.handleChange(e)}
                  required
                />
              </div>
              <div class="form-group">
                <label class>Pin code</label>
                <input
                  name="pin"
                  id="pin"
                  placeholder=" PinCode Here"
                  class="form-control"
                  type="text"
                  value={this.state.pin}
                  onChange={e => this.handleChange(e)}
                  required
                />
              </div>
              <div class="form-group">
                <label class>Donation Amount</label>
                <input
                  name="donation"
                  id="donation"
                  placeholder=" Donation Amount"
                  class="form-control"
                  type="text"
                  value={this.state.donation}
                  onChange={e => this.handleChange(e)}
                />
              </div>
              <button
                type="submit"
                class="btn btn-secondary"
                style={{
                  marginTop: "12px",
                  width: "100%",
                  backgroundColor: "	#9932CC"
                }}
                onClick={this.handleSubmit}
              >
                Submit
              </button>
              {this.state.loading && (
                <div
                  style={{
                    margin: "15px 0px",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Spinner style={{ width: "3rem", height: "3rem" }} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChange = event => {
    if (
      event.target.name === "donation" ||
      event.target.name === "pin" ||
      event.target.name === "mobile"
    ) {
      if (AllowOnlyNumber(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value
        });
      } else {
        this.setState({
          [event.target.name]: ""
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handleClickDropDwon = (key, data) => {
    switch (key) {
      case "state":
        this.setState({
          isState: !this.state.isState
        });
        break;

      case "district":
        this.setState({
          isDistrict: !this.state.isDistrict
        });
        break;

      default:
        break;
    }
  };

  handleSubmit = async () => {
    const {
      donor,
      mobile,
      address,
      selectedDistrict,
      selectedState,
      pin,
      donation
    } = this.state;

    this.setState({
      loading: true
    });

    let val_obj = {
      data: {
        ReceiptNo: "string",
        DonorName: donor,
        MobileNumber: mobile,
        Address: address,
        District: selectedDistrict.DistrictName,
        State: selectedState.StateName,
        Pincode: pin,
        DonationAmount: donation
      },
      request_type: "POST",
      url: "Donation/Entry"
    };

    console.log("val_obj", val_obj);
    let response = await requestServerData(val_obj, "admin");
    console.log("val_obj", response);

    if (response.response) {
      this.props.history.push("/donation");
    } else {
      toast.error("Please try again");
    }
    this.setState({
      loading: false
    });
  };
}

export default withRouter(Donate);
