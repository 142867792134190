export const storeInSession = (key, value) => {

    sessionStorage.setItem(key, JSON.stringify(value));
    return { response: true }
}


export const getFromSession = (key) => {

    let value = sessionStorage.getItem(key) || undefined

    if (value) {

        let data = JSON.parse(value)

        return { response: true, data: data }
    } else {

        return { response: false }
    }
}

export const replceSessionStorage = (key, value) => {

    let pData = sessionStorage.getItem(key) || undefined

    if (pData) {

        sessionStorage.removeItem(key);
        let preData = JSON.parse(pData)
        sessionStorage.setItem(key, JSON.stringify(value));

        return { response: true, data: { preData: preData, data: value } }
    } else {

        let data = storeInSession(key, value)
        return data
    }
}

export const removeSessionStorage = (key) => {

    sessionStorage.removeItem(key);
    return { response: true }
}