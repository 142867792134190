import React, { Component } from "react";
import {Badge, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import AddContestent from '../Admin/AddContestent'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardMini from '../Admin/DashboardMini'
import EditContestent from "./EditContestent";



class contestentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          removeLoading:false
        };
    }

    componentDidMount() {

        this.handleGetDataForTable()
    }

    render() {

        if (this.state.loading) {

            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <div >
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            )
        }

        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer
                        position="top-center"
                    />
                    <Row>                       
                    <DashboardMini/>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">Contestant List/प्रतिभागियों  की सूची</h3>
                                    <hr />
                                    <Row className="align-items-center">
                                        {/* <h4 className="page-center">
                                            Total :
                                            {this.state.ContestnentDataCount}
                                        </h4> */}
                                        <Col className="d-flex justify-content-end">
                                            <Button className="align-self-right" color="info" onClick={() => this.handleClick('add')}>Add new </Button>
                                            {/* <Button className="align-self-right" color="info" >Add new </Button> */}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 20, paddingRight: 30 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <div style={{margin:0, padding:0, display:'none'}}>
                                <Button color='primary' onClick={()=>{this.handleClick('excel')}}>Download Excel</Button>
                            </div>  
                                <ReactHTMLTableToExcel
                                    id="excel-button"
                                    className="download-table-xls-button"
                                    table="contestentList"
                                    filename="contestentList"
                                    sheet="contestentList"
                                    buttonText="Download as XLS" />
                            </div>
                    </div>
                    <Row className="mb-4">
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                    <MDBDataTable
                                        id={'contestentList'}
                                        // paging={true}
                                        striped
                                        bordered
                                        entriesOptions={[10, 50, 100, 1000]}
                                        hover
                                        width={50}
                                        data={this.state.ContestnentData}
                                    />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={this.state.modalIsActive} toggle={() => this.handleClick('close_modal')}>
                    <ModalHeader>Edit Contestant</ModalHeader>
                    <ModalBody>
                        {/* <EditContestent/> */}
                        <EditContestent contestentID={this.state.contestentID} handleClose={this.handleClick} />
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color="primary" onClick={() => { this.handleClick('edit_contestent')}}>Edit Contestent</Button>{' '} */}

                        <Button color="secondary" onClick={() => { this.handleClick('close_modal') }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    assemblePosts = (ContestnentData) => {
        
        let posts = ContestnentData && ContestnentData.result.map((post) => {
            
            let date = post.CreatedDate && post.CreatedDate.split('T')[0]

            return (
                {
                    ContestantId: post.ContestantId,
                    RegistrationDate: date,
                    RegistrationCode: post.RegistrationCode,
                    ContestantName: post.ContestantName,
                    MobileNo: post.MobileNo,                  
                    UpiId: post.UpiId,
                    paidAmount : post.PaidAmount,
                    UnpaidAmount : post.UnPaidAmount,
                    WalletAmount : post.WalletAmount,
                  
                    UpiStatus : post.UpiStatus === 'Verified' ? <Button color="success  mr-2 " onClick={() => this.changestatus(post.RegistrationCode)} >Verified</Button>
                        : <Button color="danger mr-2 " class="btn btn-outline-danger" onClick={() => this.changestatus(post.RegistrationCode)}>Pending</Button>
                    ,
                    NameInUpi: post.NameInUpi,                   
                    Action: <div className="action-icons">
                        <Button color="primary mr-2 " onClick={() => this.handleClick('edit', post)} >Edit</Button>
                    </div>,
                    //  APShlok: <Button  color="btn btn-success " outline onClick={() => this.handleClick('approved', post)} >Approve<Badge style={{  fontWeight: 'bold', justifyContent: 'center' }} color="secondary">{post.ApproveShlok}</Badge></Button>
                     APShlok: <Button  color="btn btn-info " outline onClick={() => this.handleClick('approved', post)} >Approve<b>[{post.ApproveShlok}]</b></Button>,
                     Remove: (
                        post.IsActive ? (
                            <div className="action-icons">
                              <Button
                                color="success  mr-2"
                                class="btn btn-outline-danger"
                                onClick={() => this.handleClick("remove", post)}
                              >
                                {this.state.removeLoading && <Spinner style={{ width: "3rem", height: "3rem" }} /> || "Mark Inactive" }
                              </Button>
                            </div>
                          ) : (
                            <div className="action-icons">
                              <Button
                                color="danger mr-2 "
                                class="btn btn-outline-danger"
                                onClick={() => this.handleClick("remove", post)}
                              >
                                {this.state.removeLoading && <Spinner  style={{ width: "3rem", height: "3rem" }} /> || "Mark Active" }
                              </Button>
                            </div>
                       )
                     )
                }

            )

        });

        return posts;
    }

    handleClick = (key, data) => {

        switch (key) {

            // case 'edit_contestent':

            // debugger
            // let edit = this.editContestent.current.focus()
            // debugger
            // break;


            case 'add':

                this.props.history.push('/add-contestent')
                break;

            case 'edit':

                this.setState({
                    contestentID: data.RegistrationCode
                })
                this.setState({
                    modalIsActive: true
                })
                //  this.props.history.push(`/edit-contestent/${data.RegistrationCode}`)
                break;

            case 'approved':

                this.props.history.push(`/approved-slok/${data.RegistrationCode}`, {ContestantName: data.ContestantName})
                break;
            case "remove":
                    this.removeContestentList(data);
                    break;
            case 'close_modal':

                this.setState({
                    modalIsActive: false
                })
                this.handleGetDataForTable()
                break;
                
            case 'excel':

            this.setState({excelDownload: true})
                break;

            default:
                break;
        }
    }

    removeContestentList = async data => {
        if (this.state.removeLoading) return void 0;
        this.setState({
          removeLoading: true
        });
        let val_obj = {
          url: `Contestent/DeleteContestentData`,
          data: {
            RegistrationCode: data.RegistrationCode
          },
          request_type: "POST"
        };
        let response = await requestServerData(val_obj, "admin");
        if (response.response) {
          this.handleGetDataForTable();
        } else {
          alert("try again . . .");
        }
        this.setState({
          removeLoading: false
        });
    };

    handleGetDataForTable = async () => {

        this.setState({
            loading: true
        })
        let val_obj = {
            url: 'Contestent/GetNewContentSummary1',
            data: '',
            request_type: 'GET'
        }
        let response = await requestServerData(val_obj, 'admin')
        
        console.log('contestent list', response.data.data.result)
        if (response.response) {

            const data = {
                columns: [
                    // { label: 'ContestantId', field: 'ContestantId', },
                    { label: 'Date', field: 'RegistrationDate', },
                    { label: 'Reg No', field: 'RegistrationCode', },
                    { label: 'Name', field: 'ContestantName', },
                    { label: 'Mobile', field: 'MobileNo', },
                    // { label: 'State', field: 'State', },
                    // { label: 'District', field: 'District', },
                    { label: 'Upi ID', field: 'UpiId', },
                    { label: 'Upi Name', field: 'NameInUpi', },
                    { label: 'PaidAmount', field: 'paidAmount', },
                    { label: 'UnpaidAmount', field: 'UnpaidAmount', },
                    { label: 'WalletAmount', field: 'WalletAmount', },
                   // { label: 'TotalCount', field: 'TotalCount', },
                    { label: 'Upi Status', field: 'UpiStatus', },
                    
                    // { label: 'Remuneration', field: 'OptOutRemuneration', },
                    { label: 'Shlok', field: 'APShlok', },
                    // { label: 'Action', field: 'Action', },
                    // { label: 'Remove', field: 'Remove' }
                ],
                rows: this.assemblePosts(response.data.data),
            }
            this.setState({ ContestnentData: data, ContestnentDataCount: response.data.data.result.length })
            //      toast.success("Success !")

        } else {

            alert('server error')
            // this.props.history.push('/')
        }
        this.setState({loading:false})
    }

    changestatus = async (RegNo) => {

        this.setState({
            loading: true
        })
        let data = {
            "RegNo": RegNo
        }
        let val_obj = {
            url: `Contestent/VarifiedUpiStatus?RegNo=${RegNo}`,
            data: data,
            request_type: 'POST'
        }
        let response = await requestServerData(val_obj, 'admin')
        console.log('contestent list', response.data.data.result)

        if(response.response){

            this.handleGetDataForTable()
        }else{

            alert('try again . . .')
        }

        
        // // let server_url = 'http://localhost:8326/api/Contestent/VarifiedUpiStatus?RegNo='+ RegNo;
        // let server_url = 'https://webapi.vedic-wisdom.com/api/Contestent/VarifiedUpiStatus?RegNo=' + RegNo;
        // axios
        //     .post(server_url, data)
        //     .then(responseJson => {
        //         this.handleGetDataForTable()
        //     })
    }

}

export default withRouter(contestentList);