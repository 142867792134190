import React from "react";

const userIcom = props => (
    <svg width={30.5} height={30.5}  {...props}>
    <path d="M29.146 27.933l-.865-4.869c-.229-1.295-1.421-2.522-2.711-2.797l-6.451-1.361c1.214-1.176 2.147-2.807 2.768-4.445.798-.291 1.603-1.002 1.833-1.931.31-1.229-.017-2.328-.78-2.807v-.868c.697-2.1 1.062-5.533-2.104-7.381-1.417-.828-2.593-.881-3.48-.592-.137-.359.274-.881.274-.881-.729-.025-1.112.363-1.271.582-.974-.447-2.248-.639-3.878-.297-5.232 1.098-5.9 7.951-4.943 9.453-.747.48-1.063 1.567-.759 2.787.233.932 1.042 1.646 1.843 1.934.609 1.642 1.536 3.272 2.748 4.448l-6.441 1.356c-1.289.271-2.479 1.502-2.708 2.797l-.868 4.871c-.12.677.041 1.314.452 1.808.41.489 1.014.76 1.698.76h23.493c.687 0 1.29-.27 1.701-.76.409-.49.572-1.133.449-1.807zm-1.215 1.166c-.219.26-.55.401-.936.401h-4.566v-1.164h5.729c.004.287-.055.558-.227.763zM3.502 29.5c-.384 0-.715-.143-.933-.401-.172-.205-.233-.476-.229-.763h5.729V29.5H3.502zm5.567 0v-2.854a.5.5 0 10-1 0v.688H2.473l.729-4.099c.159-.894 1.043-1.808 1.93-1.994l4.561-.961a7.962 7.962 0 005.59 2.312 7.865 7.865 0 005.583-2.299l4.498.948c.889.188 1.773 1.103 1.932 1.994l.729 4.099h-5.597v-.688a.5.5 0 00-1 0V29.5H9.069zm6.18-8.867c1.062 0 2.021-.348 2.873-.916l1.536.324a6.876 6.876 0 01-4.374 1.553 6.963 6.963 0 01-4.382-1.565l1.466-.31c.853.568 1.814.914 2.881.914zM9.454 13.48c-.425.092-1.406-.443-1.601-1.219-.199-.793-.01-1.533.421-1.654a.532.532 0 01.393.062V9.485l.007-.011c-.003-.016-.005-.038-.007-.057v-.404c.106-1.004 1.055-2.909 6.28-1.196 1.799.59 2.909-.345 3.47-1.584 1.774.196 2.958 2.213 3.413 3.455v.981a.62.62 0 01.197-.072c-.002.009-.001.025-.004.031.015.019.041.006.077-.029.041 0 .082-.006.122.007.431.121.619.86.421 1.653-.192.771-1.167 1.302-1.59 1.222-.565 1.721-1.693 3.922-3.301 5.135a5.137 5.137 0 01-.603.387 4.191 4.191 0 01-.917.381c-.315.087-.646.14-.986.14-.347 0-.68-.051-1-.14a4.31 4.31 0 01-.921-.377 4.891 4.891 0 01-.603-.387c-1.595-1.2-2.701-3.376-3.268-5.14z" />
  </svg>
);

userIcom.defaultProps = {
    width: 48,
    height: 48,
    viewBox: '0 0 30.5 30.5'
};

export default userIcom;
